import { Icon } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { BaseFolder,  ContentItem, ITokenProvider, TokenInfo } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan as faTrashRegular } from '@fortawesome/free-regular-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import all from 'it-all';
import parallelBatch from 'it-parallel-batch';

type TokenGeneratorProps = {
    // initialToken: TokenInfo | null;
    baseFolder: BaseFolder;
    show: boolean;
    handleShowChange?: (show: boolean) => void;
    files: ContentItem[],
    basePath: string,
    tokenProvider: ITokenProvider
}

interface FileToken {
    name:string;
    key:string;
}

  
export const TokenGenerator = ({  baseFolder, show, handleShowChange, files, basePath, tokenProvider }: TokenGeneratorProps) => {

    const [duration, setDuration] = useState<number>(Math.min(10,baseFolder?.maxDurationMinutes));
    const [scope, setScope] = useState<"None"|"File"|"Folder"|"FolderAndSub"|"Any">( "None");
    const [perm, setPerm] = useState<"Read" | "Write" | "List" | "Delete" | "ReadList" | "All">("Read");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tokenUrl, setTokenUrl] = useState<(string|undefined)[]>([]);
    const [filesToken, setFilesToken] = React.useState<FileToken[]>([]);
    const [canGenerateToken, setCanGenerateToken] = React.useState<boolean>(false);



    useEffect(() => {
        if ( baseFolder) {
            setDuration( Math.min(10,baseFolder?.maxDurationMinutes));
            let newScope : "None"|"File"|"Folder"|"FolderAndSub"|"Any" = "None";
            switch (baseFolder.securityModel){
                case "CloudStorageLevel":
                case "ContainerFileShareLevel":
                    newScope = "Any";
                    break;
                case "FolderLevel":
                    newScope = "FolderAndSub";
                    break;
                case "FileLevel":
                    newScope = "File";
                    break;
            }
            setScope(newScope);
            if (files?.length > 0){
                setFilesToken([...files.map(f=> ({name: f.name, key: uuidv4() })),({name: "", key: uuidv4() })]);
            }
            else {
                setFilesToken([({name: "", key: uuidv4() })]);
            }
            setTokenUrl([]);
            setCanGenerateToken( newScope!=="File" || files?.length > 0);
            setLoading(false);
        }

      }, [baseFolder, show, files ]);
    useEffect(() => {      
        if (scope==="Folder"){
            setPerm("List");
        }
        else {
            setPerm(baseFolder?.operationAllowed === "Write" ? "Write" : "Read");
        }
    }, [scope, baseFolder?.operationAllowed]);

    useEffect(() => {      
        if ( scope!=="File" || perm === "List"){
            setCanGenerateToken(true);
        }
        else if (filesToken.length > 0 && filesToken[0].name !== ""){
            setCanGenerateToken(true);
        } else {
            setCanGenerateToken(false);
        }        
    }, [scope, filesToken, perm]);
    const handleDuration = (value:string) => {
        let n = Number(value);
        if (!isNaN(n))
            setDuration(n);
    }

    const generateToken = async () => {
        setLoading(true);
        const provider = tokenProvider;
        let arrayTask = [];
        if (scope === "File"){
            const folder = basePath + ( basePath && !basePath.endsWith("/")? "/" : "");
            filesToken.forEach((f) => {
                if (f?.name){
                    arrayTask.push(async (): Promise<TokenInfo|undefined> => {
                        return await provider.getToken(perm, folder + f.name, duration);
                    });                
                }
            });
        } else {
            const basePath2 = baseFolder?.securityModel === "FolderLevel" || baseFolder?.kind === "AwsS3" ?  basePath : "";
            arrayTask.push(async (): Promise<TokenInfo|undefined> => {
                return await provider.getToken(perm, basePath2, duration);
            });                
        }
        const tokens = await all(parallelBatch(arrayTask, 5));
        if (tokens){
            setTokenUrl(tokens.map(t=>t?.urlWithSignature.join(",")));
        }
        setLoading(false);
    }
    const previous = async () => {
        setTokenUrl([]);
    }

    const handleClose = () => {
        setPerm('Read');
        setDuration( Math.min(10, baseFolder?.maxDurationMinutes));
        setScope("None");
        setTokenUrl([]);
        setLoading(false);        
        if (handleShowChange) handleShowChange(false);
    }
    
    function onChangeFile(event: any, index: number, value: string) {
        const newFiles = [...filesToken];
        if (value === "" && index < filesToken.length - 1) {
            newFiles.splice(index, 1);
        } else {
            newFiles[index].name = value;
        }
        if (index === filesToken.length - 1 && value !== "") {
            newFiles.push(({name: "", key: uuidv4() }));
        }
        setFilesToken(newFiles);
    }

    function onAddFile(event: any) {
        event.preventDefault();  
        setFilesToken([...filesToken, ({name: "", key: uuidv4() })]);
    }

    function onRemoveFile(event: any, index: number) {
        event.preventDefault();  
        const newFiles = [...filesToken];
        newFiles.splice(index, 1);
        setFilesToken(newFiles);
    }

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{"Generate a token to use in other tools"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate>
                {
                    tokenUrl.length===0 &&
                    <>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                            Scope
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Check 
                                        type='radio'
                                        inline
                                        className='col-form-label'
                                        name='scope'
                                        value='File'
                                        label='per file'
                                        defaultChecked={scope === "File"}
                                        onChange={(e)=> setScope("File") }
                                        />
                                {
                                    baseFolder?.securityModel === "FileLevel" && baseFolder?.kind === "AwsS3" &&
                                    <Form.Check 
                                        type='radio'
                                        inline
                                        className='col-form-label'
                                        name='scope'
                                        value='Folder'
                                        label='for this folder'
                                        title='only available on Aws S3'
                                        defaultChecked={scope === "Folder"}
                                        onChange={(e)=> setScope("Folder") }
                                    />                                                                               
                                }                                           
                                {
                                    baseFolder?.securityModel === "FolderLevel" &&
                                    <Form.Check 
                                        type='radio'
                                        inline
                                        className='col-form-label'
                                        name='scope'
                                        value='FolderAndSub'
                                        label='for this folder and its subdirectories'
                                        title='only available on Azure Datalake'
                                        defaultChecked={scope === "FolderAndSub"}
                                        onChange={(e)=> setScope("FolderAndSub") }
                                    />                                                                               
                                } 
                                                                                          
                                <Form.Check 
                                        type='radio'
                                        inline
                                        className='col-form-label'
                                        name='scope'
                                        value='Any'
                                        label='for any location'
                                        title='only available on Azure'
                                        disabled={ baseFolder?.kind === "AwsS3" }
                                        defaultChecked={scope==="Any"}
                                        onChange={(e)=> setScope("Any") }
                                    />         
                            </Col>
                        </Form.Group>                                        
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                        Permissions
                        </Form.Label>
                        <Col sm={10}>
                            {
                                (scope === "FolderAndSub" || scope === "Any") &&
                                <>
                                    <Form.Check 
                                            type='radio'
                                            inline
                                            className='col-form-label'
                                            name='permission'
                                            value='ReadList'
                                            label='List and download'
                                            title='Users can list and download content'
                                            defaultChecked={perm === 'ReadList'}
                                            onChange={(e)=> setPerm("ReadList") }
                                        />         
                                    <Form.Check 
                                            type='radio'
                                            inline
                                            className='col-form-label'
                                            name='permission'
                                            value='All'
                                            label='All (list, download, upload, delete, create folder)'
                                            title='Users can list, download content as upload, create folders, etc...'
                                            disabled={ baseFolder?.operationAllowed !== "Write" }
                                            defaultChecked={perm === 'All'}
                                            onChange={(e)=> setPerm("All") }
                                        />                                                                               
                                </>
                            }
                            { scope === "Folder" &&
                                <Form.Check 
                                    type='radio'
                                    inline
                                    className='col-form-label'
                                    name='permission'
                                    value='List'
                                    label='List this folder'
                                    title='Users can list content. Not the subdirectories'
                                    defaultChecked={perm === 'List'}
                                    onChange={(e)=> setPerm("List") }
                                />         
                            }
                            { scope === "File" && 
                            <>
                                <Form.Check 
                                    type='radio'
                                    inline
                                    className='col-form-label'
                                    name='permission'
                                    value='Read'
                                    label='Download files'
                                    title='Users can download a file'
                                    defaultChecked={perm === 'Read'}
                                    onChange={(e)=> setPerm("Read") }
                                />         
                                <Form.Check 
                                    type='radio'
                                    inline
                                    className='col-form-label'
                                    name='permission'
                                    value='Write'
                                    label='Upload files'
                                    title='Users can upload a file'
                                    defaultChecked={perm === 'Write'}
                                    disabled={ baseFolder?.operationAllowed !== "Write" }
                                    onChange={(e)=> setPerm("Write") }
                                />         
                                <Form.Check 
                                    type='radio'
                                    inline
                                    className='col-form-label'
                                    name='permission'
                                    value='Delete'
                                    label='Delete files'
                                    title='Users can delete one or several files'
                                    defaultChecked={perm === 'Delete'}
                                    disabled={ baseFolder?.operationAllowed !== "Write" }
                                    onChange={(e)=> setPerm("Delete") }
                                />         
                            
                            
                            </>
                    
                            
                            
                            }
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                            Duration in minutes
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Range  max={baseFolder?.maxDurationMinutes} value={duration} onChange={(e)=>handleDuration(e.target.value)} />
                            </Col>
                            <Col sm={1}>
                                <Form.Control value={duration} onChange={(e)=>handleDuration(e.target.value)} ></Form.Control>
                            </Col>
                        </Form.Group>
                        {
                            scope === "File"  &&
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                Files
                                </Form.Label>
                                <Col sm={10}>
                                    <Container fluid>
                                        { 
                                            filesToken.map((f, index) => {
                                                return (
                                                    <Row key={f.key}>
                                                        <Col sm={11}>
                                                            <Form.Control placeholder="Enter a filename" defaultValue={f.name} onChange={(e)=> onChangeFile(e, index, e.target.value) }/>
                                                        </Col>
                                                        <Col sm={1}>
                                                            <button className='browserlink text-primary' onClick={(e)=>onRemoveFile(e,index)} title="delete" ><FontAwesomeIcon icon={faTrashRegular} /></button>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                        <Row>
                                            <Col sm={12}>
                                                <small>
                                                    <button className='browserlink text-primary' onClick={(e)=>onAddFile(e)}><FontAwesomeIcon icon={faPlus} /> Add a file</button>
                                                </small>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                
                            </Form.Group>
                        }
                        </>
                    }
                    { 
                        isLoading &&  
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                    }    
                    {
                        tokenUrl.map((url, index) => {
                            if ( url) {
                                return (
                                    <Form.Group as={Row} className="mb-3" key={uuidv4()}>
                                        <Form.Label column sm={2}>
                                        Url with token
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Text >{url}</Form.Text>
                                        </Col>
                                        <Col sm={1}>
                                            <Button variant='outline-primary' title='copy' onClick={() => window.navigator.clipboard.writeText(url)}><Icon iconName='Copy' /></Button>
                                        </Col>
                                    </Form.Group>
                                )
                            }
                            else {
                                return <></>;
                            }
                        })
                    }                
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" href="https://digitalplatforms.totalenergies.com/documentation/digital-platforms-fundamentals/cloud-competence-center-ccc/flash/use-token-outside" target="_blank"><Icon iconName='Help' /> How to use the token</Button>            
                {
                    tokenUrl.length>0 &&
                    <>
                        <Button variant="secondary" onClick={previous}>Previous</Button>
                        <Button variant="primary" onClick={handleClose}><Icon iconName='ChromeClose' /> Close</Button>
                    </>
                }
                {
                    tokenUrl.length===0 &&
                    <>
                        <Button variant="secondary" onClick={handleClose}><Icon iconName='ChromeClose' /> Close</Button>
                        <Button variant="primary" onClick={generateToken} disabled={!canGenerateToken} >Generate a token</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
  }