import * as React from 'react';

// import "formik-stepper/dist/style.css";
import './App.scss';
import { createBrowserRouter, createRoutesFromElements,  Route, RouterProvider} from 'react-router-dom';
import Layout from './pages/Layout';
import Home, { ListContextes } from './pages/Home';
import AdminList, { GetCloudStorages } from './pages/AdminList';
import Navigation, { ListFolderContent } from './pages/Navigation';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ILoginContext } from './types';
import AdminForm, {  GetCloudStorage, SetCloudStorage } from './pages/AdminForm';
import { ErrorGeneric } from './pages/ErrorGeneric';
import AdminPermission, { AddPermission, DeletePermission, GetPermissions, ReviewPermission } from './pages/AdminPermission';
import { useEffect } from 'react';
import { EventMessage, EventType } from '@azure/msal-browser';
import { handleLogin } from './utils';
import { ErrorAdminAccount } from './pages/ErrorAdminAccount';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBackend } from './pages/ErrorBackend';
import { DataLakeHome, GetDatalakeInfos } from './pages/DataLakeHome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';


export function Fallback() {
  return <p>Performing initial data load</p>;
}

//  utilisation de react-query : lire 
//  https://tkdodo.eu/blog/react-query-meets-react-router

// import all regular and solid icons for font awsome
library.add(far)
library.add(fas)

export default function App() {
  const msalContext = useMsal(); 
  const isAuthenticated = useIsAuthenticated();       
  const context : ILoginContext = {
    isAuthenticated : isAuthenticated,
    msalContext : msalContext
  };
  useEffect(() => {
      const callbackId = msalContext.instance.addEventCallback((event:EventMessage) => {
        if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE && event.interactionType === "silent")
        {
          // cannot retreive token is silent mode... Usually this is due to the MFA once a day... So log in again explicitly
          handleLogin(msalContext);
        }
      });

      return () => {
          if (callbackId) {
              msalContext.instance.removeEventCallback(callbackId);
          }
      };
  }, [msalContext]);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 60 * 1, // 1 hour of cache,
        refetchOnWindowFocus: false
      },
    },
  })
  let router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route 
          index 
          element={<Home />} 
          loader = {ListContextes(context )}
          errorElement =  {<ErrorBackend />}          
          />
        <Route 
          id="navigationdl"
          path='/dl/:cloudStorageId'
          element={<DataLakeHome  />}
          loader = {GetDatalakeInfos(context, queryClient)}
          />
        <Route 
          id="navigationdl2"        
          path='/dl/:cloudStorageId/:perimeter/*'
          element={<Navigation  />}
          loader = {ListFolderContent(context, queryClient)}
          errorElement =  {<ErrorGeneric />}
          />                    
        <Route 
          path='/self'
          element={<AdminList admin={false} />}
          loader = {GetCloudStorages(context, false)}
          />
        <Route 
          path='/self/:cloudStorageId'
          element={<AdminForm/>}
          loader = {GetCloudStorage(context)}
          action = {SetCloudStorage(context)}
          errorElement =  {<ErrorGeneric />}
          />          
        <Route 
          path='/self/:cloudStorageId/permissions'
          element={<AdminPermission admin={false} />}
          loader = {GetPermissions(context, false)}
          errorElement =  {<ErrorGeneric />}
          />            
        <Route 
          path='/self/:cloudStorageId/permissions/add'
          action = {AddPermission(context)}
          errorElement =  {<ErrorGeneric />}
          />            
        <Route 
          path='/self/:cloudStorageId/permissions/delete'
          action = {DeletePermission(context)}
          errorElement =  {<ErrorGeneric />}
          />         
        <Route 
          path='/self/:cloudStorageId/permissions/review'
          action = {ReviewPermission(context, false)}
          errorElement =  {<ErrorGeneric />}
          />                 
        <Route 
          path='/admin'
          element={<AdminList  admin={true}/>}
          loader = {GetCloudStorages(context, true)}
          />
        <Route 
          path='/admin/:cloudStorageId/permissions'
          element={<AdminPermission admin={true} />}
          loader = {GetPermissions(context, true)}
          errorElement =  {<ErrorGeneric />}
          />            
        <Route 
          path='/admin/:cloudStorageId/permissions/add'
          action = {AddPermission(context)}
          errorElement =  {<ErrorGeneric />}
          />            
        <Route 
          path='/admin/:cloudStorageId/permissions/delete'
          action = {DeletePermission(context)}
          errorElement =  {<ErrorGeneric />}
          />    
        <Route 
          path='/admin/:cloudStorageId/permissions/review'
          action = {ReviewPermission(context, true)}
          errorElement =  {<ErrorGeneric />}
          />                        
        <Route 
          path='/notanadminaccount'
          element={<ErrorAdminAccount  />}
          />                      
        <Route 
          id="navigation"
          path="/:cloudStorageId/*" 
          element={<Navigation />}
          loader = {ListFolderContent(context, queryClient)} 
          errorElement =  {<ErrorGeneric />}
          />        
      </Route>
    )
  );
  return <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </QueryClientProvider>;
}