// so thank you https://github.com/ryanerdmann/azure-storage-browser/blob/main/src/AzureDirectoryProvider.ts#L21
import { ContainerClient, BlobItem, BlobPrefix, BlobServiceClient } from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/core-http';
import { IDirectoryProvider, ContentItem, ProgressCallback, IDirectoryContent, TokenInfo, ITokenProvider, BaseFolderWithVoucher } from '../types';
import { getFileName, safelyDecodeURIComponent } from '../utils'

interface ContainerClientFilePath {
  containerClient: ContainerClient;
  filePath: string;
}

export class AzureBlobProvider implements IDirectoryProvider {
  protected tokenInfo: TokenInfo;
  protected baseFolder :BaseFolderWithVoucher;
  protected tokenProvider: ITokenProvider;

  constructor(token: TokenInfo, baseFolder: BaseFolderWithVoucher, tokenProvider: ITokenProvider) {
    this.tokenInfo = token;
    this.baseFolder = baseFolder;
    this.tokenProvider = tokenProvider; 
  }

  getBlobServiceClient( ): BlobServiceClient {
    let url = new URL(this.tokenInfo.urlWithSignature[0]);
    let baseUrl = new URL(url.protocol+ "//"+url.host  + url.port);
    let sas = url.search;
    return new BlobServiceClient(baseUrl.toString() + sas);
  }

  getContainerClientAndFilePath(path:string, isFolder: boolean, fileNameOrPrefixToSearch?: string): ContainerClientFilePath | null{
    let url = new URL(this.tokenInfo.urlWithSignature[0]);
    if (url.pathname === '/' && !path) // no container name neither in the token neither in the path
      return null;
    path = this.sanetizePath(path, isFolder, fileNameOrPrefixToSearch);
    let containerName = "";
    let restOfPath = "";
    if (url.pathname === '/') {
      containerName = path.split('/')[0];
      restOfPath = path.substring(containerName.length + 1);
    } else  {
      containerName = url.pathname.split('/')[1];
      restOfPath = safelyDecodeURIComponent(url.pathname.substring(containerName.length + 2));
      if (path && this.baseFolder.securityModel !== "FileLevel") {
        if (restOfPath && !restOfPath.endsWith('/'))
          restOfPath += '/';
        restOfPath += path;
      }
    }
    if (!fileNameOrPrefixToSearch  && restOfPath && !restOfPath.endsWith('/'))
      restOfPath += '/';
    return {
      containerClient: this.getBlobServiceClient().getContainerClient(containerName),
      filePath: restOfPath
    }
  }

  protected sanetizePath(path: string, isFolder: boolean, fileNameOrPrefixToSearch?: string, ) {
    if (!path.endsWith('/'))
      path += '/';
    // left trim path, remove double slash
    // whith token delivered at FileLevel, url contain already the filename
    if (fileNameOrPrefixToSearch && (this.baseFolder.securityModel !== "FileLevel" || isFolder)) {
      path += '/' + fileNameOrPrefixToSearch;
    }
    path = path.replace(/^\/+/, '').replace("//", "/");
    return path;
  }

  async get(path: string, options?: {continuationToken?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
    const containerClient  = this.getContainerClientAndFilePath(path, true, options?.prefixSearch);
    if (containerClient) {
      const blobs = containerClient.containerClient.listBlobsByHierarchy('/', { prefix: containerClient.filePath, abortSignal: options?.abortSignal  }).byPage({ maxPageSize: options?.pageSize, continuationToken: options?.continuationToken });

      let response = (await blobs.next()).value;

      let contents: ContentItem[] = [];
      for await (const blob of response.segment.blobItems) {
        const item = this.fromBlobItem(path, blob);
        contents.push(item);
      }
      for await (const prefix of response.segment.blobPrefixes) {
        const item = this.fromBlobPrefix(path, prefix);
        contents.push(item);
      }

      return {
        elements: contents,
        baseFolder: this.baseFolder,
        tokenProvider: this.tokenProvider,
        continuationToken: response.continuationToken,
        pagesize: options?.pageSize,
        prefixSearch: options?.prefixSearch
      }
    }
    let contents: ContentItem[] = [];    
    for await (const container of this.getBlobServiceClient().listContainers()) {
      contents.push(this.fromBlobPrefix(path, container));
    }    

    return {
      baseFolder: this.baseFolder,
      elements: contents,
      tokenProvider: this.tokenProvider      
    }
  }

  async upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<boolean>{
    const filename = file.name;
    
    const containerClient  = this.getContainerClientAndFilePath(path, false, filename);    
    if ( containerClient) {
      const client = containerClient.containerClient.getBlockBlobClient(containerClient.filePath);
      const opts = { 
        blobHTTPHeaders: { 
          blobContentType: file.type 
        }, 
        onProgress: options?.onprogress,
        abortSignal: options?.abortSignal
      };
      await client.uploadData(file, opts);
    }
    return true;
  }
  async download( path:string, item:ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<Blob|undefined>{
    let fullName = item.name ;
    const containerClient  = this.getContainerClientAndFilePath(path, false, fullName);    
    if ( containerClient) {    
      const client = containerClient.containerClient.getBlockBlobClient(containerClient.filePath);
      const opts = {
        onProgress:(progress: TransferProgressEvent) => {
          options?.onprogress({ loadedBytes: progress.loadedBytes });
        },
        abortSignal: options?.abortSignal
      }
      const downloadBlockBlobResponse = await client.download(undefined, undefined,opts);
      return await downloadBlockBlobResponse.blobBody;
    }
  }
  async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    // for "normal" (not HNS), folders cannot be created
    // so, a trick : user navigate automatically in the subfolder... and then he can upload a document
    return false;
  }

  async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    // folders are virtual, no need to delete them
    if (item.isFile) {
      let fullName = item.name ;
      const containerClient  = this.getContainerClientAndFilePath(path, false, fullName);  
      if (containerClient) {
        const client = containerClient.containerClient.getBlockBlobClient(containerClient.filePath);
        await client.delete(options);
      }
    }
    return true;
  }
  async rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    throw new Error('Method not supported.');
  }
  async move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    throw new Error('Method not supported.');
  }

  protected fromBlobPrefix(path: string, prefix: BlobPrefix): ContentItem {
    let newPath = path;
    // remove last slash if any
    let idx = prefix.name.lastIndexOf('/', prefix.name.length - 2);
    return {
      isFile: false,
      name: getFileName(prefix.name),
      path: newPath + prefix.name.substring(idx+1), 
      isBaseFolder: false,
    };
  }

  protected fromBlobItem(path: string, blob: BlobItem): ContentItem {
    let newPath = path ;    
    let idx = blob.name.lastIndexOf('/', blob.name.length - 2);
    return {
      isFile: true,
      name: getFileName(blob.name) ,
      modified: blob.properties.lastModified,
      size: blob.properties.contentLength ?? 0,
      path:  newPath + blob.name.substring(idx+1), 
      isBaseFolder: false,
    }
  }

  
}