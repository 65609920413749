import { ErrorMessage, Field, useFormikContext, FieldArray, ArrayHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { CloudStorage, cloudStorageSource, cloudStorageDomain } from "../../types";
import { StepConfig, useWizard } from "react-formik-step-wizard";
import * as Yup from 'yup';
import { Button,  Image, Collapse, Accordion } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { Values } from "react-formik-step-wizard/dist/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus, faFileUpload, faXmarkCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import readXlsxFile, { Row } from 'read-excel-file';

const TechnicalInformationStep = () => {
    const { values } = useWizard()    
    const formikContext = useFormikContext<CloudStorage>();
    const kind = values.Platform.kind;
    const techLabel = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
                return "Azure Storage Account";
            case "AwsS3":
                return "AWS S3 Bucket";
            case "AzureDataLakeTE":
            case "AwsDataLakeTE":
                return "App Data Lake Layer";
                
        }
        return "to define in techLabel method";
    }


    const technicalInfoLabel = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
            case "AzureDataLakeTE":
                return "Storage Account Id";
            case "AwsS3":
            case "AwsDataLakeTE":
                return "Bucket Name";
        }
        return "to define in technicalInfoLabel method";
    }
    const technicalInfoPlaceHolder = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
            case "AzureDataLakeTE":
                return "/subscriptions/<subid>/resourceGroups/<rgname>/providers/Microsoft.Storage/storageAccounts/<stname>";
            case "AwsS3":
                return "bucketName";
            case "AwsDataLakeTE":
                return "bucketName/appCode";
        }
        return "to define in technicalInfoPlaceHolder method";
    }

    const basePathLabel = () => {
        switch ( kind) {
            case "AzureBlob":
                return "Container";
            case "AzureFile":
                return "File share";
            case "AzureDataLake":
                return "Scope";
            case "AwsS3":
            case "AwsDataLakeTE":
                return "Account Id";
            case "AzureDataLakeTE":
                return "Application Code";
        }
        return "to define in basePathLabel method";
    }
    const basePathPlaceHolder = () => {
        switch ( kind) {
            case "AzureBlob":
                return "container name";
            case "AzureFile":
                return "file share";
            case "AzureDataLake":
                return "container or container/path/to/a/folder";
            case "AwsS3":
            case "AwsDataLakeTE":
                return "123456789012";
            case "AzureDataLakeTE":
                return "datalake application code"

        }
        return "to define in basePathPlaceHolder method";
    }
    const landingZoneNameLabel = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
            case "AzureDataLakeTE":
                return "Subscription Name";
            case "AwsS3":
            case "AwsDataLakeTE":
                return "Account Name";
        }
        return "to define in landingZoneNameLabel method";
    }
    const landingZoneNamePlaceHolder = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
            case "AzureDataLakeTE":
                return "sub-iasp-lz-xxxxx";
            case "AwsS3":
            case "AwsDataLakeTE":
                return "awsd-wl-xxxxx";

        }
        return "to define in landingZoneNameHolder method";
    }
    
    const confidentialityLabel = () => {
        return "Confidentiality level";
    }

    const visibilityLabel = () => {
        return "Visibility";
    }

    const environmentLabel = () => {
        return "Environment";
    }

    const arnRoleLabel = () => {
        return "ARN Role";
    }

    const arnRolePlaceHolder = () => {
        return "arn:aws:iam::123456789012:role/xxxxx";
    }

    const securityModelLabel = () => {
        switch ( kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
            case "AzureDataLakeTE":
            case "AwsDataLakeTE":
                return "Generate SAS token";
            case "AwsS3":
                return "Generate presigned URL";
            default:
                return "Generate presigned URL";
        }
    }

    const [helpOpen, setHelpOpen] = useState(false);
    const [helpImg, setHelpImg] = useState("");
    const [helpImg2, setHelpImg2] = useState("");
    const setHelp = (url:string, url2?:string) => {
        if( url !== helpImg){
            setHelpImg(url);
            if (url2)
                setHelpImg2(url2);
            else
                setHelpImg2('');
            setHelpOpen(true);
        } else {
            setHelpOpen(!helpOpen);
        }
    }

    const [helpOpenInstructions, setHelpOpenInstructions] = useState(false);

    const setHelpInstr = (kind:string) => {
        if (!helpOpenInstructions) {
            setHelpOpenInstructions(true);
        }
        else {
            setHelpOpenInstructions(!helpOpenInstructions);
        }
    }
    const corsConfig =`
    [
        {
            "AllowedHeaders": [
                "*"
            ],
            "AllowedMethods": [
                "GET",
                "PUT",
                "POST",
                "DELETE"
            ],
            "AllowedOrigins": [
                "https://flash.totalenergies.com"
            ],
            "ExposeHeaders": [
                "ETag"
            ]
        }
    ]`
    const bucketPolicy = `
    {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::339712975047:role/workload/awsp-iamrole-flash-federation-api"
                },
                "Action": [
                    "s3:ListBucket",
                    "s3:ListBucketVersions",
                    "s3:GetObject",
                    "s3:GetObjectVersion",
                    "s3:PutObject",
                    "s3:DeleteObject",
                    "s3:DeleteObjectVersion",
                    "S3:GetObjectTagging",
                    "s3:PutObjectTagging"
                ],
                "Resource": [
                    "arn:aws:s3:::{BUCKET_NAME}",
                    "arn:aws:s3:::{BUCKET_NAME}/*"
                ]
            }
        ]
    }`
    const rolePolicy = `
    {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Sid": "AllowBucketAndObjectAccess",
                "Effect": "Allow",
                "Action": [
                    "s3:ListBucket",
                    "s3:GetObject",
                    "s3:PutObject",
                    "s3:DeleteObject"
                ],
                "Resource": [
                    "*"
                ]
            }
        ]
    }`
    const roleTrust = `
    {
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::339712975047:role/workload/awsp-iamrole-flash-oidc-federation-api"
                },
                "Action": "sts:AssumeRole"
            }
        ]
    }`

    useEffect(() => {
        // when create, null. Init from kind
        if (formikContext?.values) 
        {
            if (!formikContext.values.securityModel) {
                let initialSecurityModel = undefined;
                let initialArnRole = "";
                let initialEnvironment = "";
                switch(values.Platform.kind) {
                    case "AzureBlob":
                    case "AzureFile":
                        initialSecurityModel = "ContainerFileShareLevel";
                        break;
                    case "AzureDataLake":
                        initialSecurityModel= "FolderLevel";
                        break;
                    case "AzureDataLakeTE":
                    case "AwsDataLakeTE":
                        initialSecurityModel= "NoToken";
                        formikContext.setFieldValue("visibility", "OnlyFromMAIN");
                        break;
                    case "AwsS3":
                        initialSecurityModel= "FileLevel";
                        break;
                }
                formikContext.setFieldValue("securityModel", initialSecurityModel);
                formikContext.setFieldValue("arnRole", initialArnRole);
                formikContext.setFieldValue("environment", initialEnvironment);
            }

        }
    }, [formikContext, formikContext.values, formikContext.values.securityModel, values]);

    //DataLakeTE
    //Prod
    const azureDataLakeTEAppProd = "/subscriptions/0c99688b-6749-476e-bb97-bf9fb8981ee7/resourceGroups/rgp-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stpdlk2appiasp";
    const azureDataLakeTEDomainProd = "/subscriptions/0c99688b-6749-476e-bb97-bf9fb8981ee7/resourceGroups/rgp-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stpdlk2domiasp";
    const azureDataLakeTESourceProd = "/subscriptions/0c99688b-6749-476e-bb97-bf9fb8981ee7/resourceGroups/rgp-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stpdlk2srciasp";
    //PProd
    const azureDataLakeTEAppPProd = "/subscriptions/b8539b2a-a922-4872-a8b7-b51cae4aba4c/resourceGroups/rga-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stadlk2appiasp";
    const azureDataLakeTEDomainPProd = "/subscriptions/b8539b2a-a922-4872-a8b7-b51cae4aba4c/resourceGroups/rga-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stadlk2domiasp";
    const azureDataLakeTESourcePProd = "/subscriptions/b8539b2a-a922-4872-a8b7-b51cae4aba4c/resourceGroups/rga-dlk2-apps-weu/providers/Microsoft.Storage/storageAccounts/stadlk2srciasp";

    //Source
    const dataLakeSourceLabel = () => {
        return "Source";
    }
    const dataLakeBaseLabel = () => {
        return "Base";
    }
    const dataLakeObjectLabel = () => {
        return "Object";
    }
    //Domain
    const dataLakeDomainLabel = () => {
        return "Domain";
    }
    const dataLakeSubDomaiLabel = () => {
        return "SubDomain";
    }
    const dataLakeObjectDomainLabel = () => {
        return "Object";
    }
    useEffect(() => {   
        // Set technicalInfo value based on conditions
        if (values.Platform.kind === "AzureDataLakeTE" && (formikContext.values.environment === "PROD" || formikContext.values.environment === "PPROD")) {
            const technicalInfoApp = formikContext.values.environment === "PROD" ? azureDataLakeTEAppProd : azureDataLakeTEAppPProd;
            const technicalInfoDomain = formikContext.values.environment === "PROD" ? azureDataLakeTEDomainProd : azureDataLakeTEDomainPProd;
            const technicalInfoSource = formikContext.values.environment === "PROD" ? azureDataLakeTESourceProd : azureDataLakeTESourcePProd;
            formikContext.setFieldValue("technicalInfo", technicalInfoApp);
            formikContext.setFieldValue("technicalInfoDomain", technicalInfoDomain);
            formikContext.setFieldValue("technicalInfoSource", technicalInfoSource);
    }
    // eslint-disable-next-line
    }, [formikContext.values.environment]);

    const useFileInput = () => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [fileName, setFileName] = useState<string | null>(null);
        const [fileData, setFileData] = useState<cloudStorageSource[] | cloudStorageDomain[]>([]);
        const [errorMessage, setErrorMessage] = useState<string>('');
        let formatedJsonData: (cloudStorageSource[] | cloudStorageDomain[]) = [];
    
        const handleDisplayFileDetails = async (event: React.ChangeEvent<HTMLInputElement>, layer:string) => {
            const file = event.target.files?.[0];
            if (file) {
                const fileExtension = file.name.split('.').pop();
                try {
                    let jsonData = await readXlsxFile(file);
                    setFileName(file.name);
                    if (jsonData.length > 0 && layer === "source") {
                        jsonData = jsonData.slice(1);
                        formatedJsonData = jsonData.map((row: Row) => {
                            return {
                                source: row[0].toString(),
                                base: row[1].toString(),
                                object: row[2].toString()
                            };
                            });
                    }
                    else if (jsonData.length > 0 && layer === "domain"){
                        jsonData = jsonData.slice(1);
                        formatedJsonData = jsonData.map((row: Row) => {
                            return {
                                domain: row[0].toString(),
                                subDomain: row[1].toString(),
                                object: row[2].toString()
                            };
                        });
                    } 
                    setFileData(formatedJsonData);
                    setErrorMessage('');
                } catch (error) {
                    const errorMessage = `Error reading the ${fileExtension} file. Please make sure it is a valid xlsx file.`;
                    setFileName(file.name);
                    setErrorMessage(errorMessage);
                    setFileData([]);
                }
            }
        };
    
        const handleClickUpload = () => {
            inputRef.current?.click();
        };
    
        return {
            inputRef,
            fileName,
            fileData,
            errorMessage,
            handleDisplayFileDetails,
            handleClickUpload,
        };
    };

    const source = useFileInput();
    const domain = useFileInput();


    useEffect(() => {
        formikContext.values.sourceDL && formikContext.values.sourceDL.length > 0 ? formikContext.setFieldValue("sourceDL", Array.from(new Set([...formikContext.values.sourceDL, ...source.fileData]))) : formikContext.setFieldValue("sourceDL", source.fileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [source.fileData]);

    useEffect(() => {
        formikContext.values.domainDL && formikContext.values.domainDL.length > 0 ? formikContext.setFieldValue("domaiDL", Array.from(new Set([...formikContext.values.domainDL, ...domain.fileData]))) : formikContext.setFieldValue("domainDL", domain.fileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [domain.fileData]);

    return (
        <>
            <div>Provide technical information about the {techLabel()}</div>
            <br/>
            { !["AzureDataLakeTE", "AwsDataLakeTE"].includes(kind) &&
                <div className="form-group row">
                <label htmlFor="securityModel" className="col-2 col-form-label">{securityModelLabel()}</label>
                <div className="col-8">
                    <Field as="select" className="form-control"
                        name="securityModel"
                    >
                        { (kind === "AzureDataLake"&& false)  && <option value="NoToken">no SAS token. Use Entra ID identity</option> }
                        { (kind === "AzureDataLake" || kind === "AzureBlob")  && <option value="ContainerFileShareLevel">At container level</option> }
                        { kind === "AzureFile"  && <option value="ContainerFileShareLevel">At file share level</option> }
                        { kind === "AzureDataLake"  && <option value="FolderLevel">At folder level</option> }
                        <option value="FileLevel">For each action (browse, download, upload, delete)</option>
                    </Field>
                    { kind.startsWith("Azure") && kind !== "AzureDataLake"  && <small className="form-text text-muted">If you are not sure, set At container level</small> }
                    { kind.startsWith("Azure") && kind === "AzureDataLake" && <small className="form-text text-muted">If you are not sure, set At folder level </small> }
                    <ErrorMessage name="securityModel"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                </div>
            </div>
            }
            <div className="form-group row">
                <label htmlFor="confidentiality" className="col-2 col-form-label">{confidentialityLabel()}</label>
                <div className="col-8">
                    <Field as="select" className="form-control" name="confidentiality">
                        <option value=""></option>
                        <option value="C1">C1</option>
                        <option value="C2">C2</option>
                        <option value="C3">C3</option>
                    </Field>
                    <ErrorMessage name="confidentiality"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                    <small className="form-text text-muted">
                    I3 or I4 projects, C4 projects are not admissible
                    </small>
                </div>
            </div>
            {!["AzureDataLakeTE", "AwsDataLakeTE"].includes(kind) &&
            <div className="form-group row">
                <label htmlFor="visibility" className="col-2 col-form-label">{visibilityLabel()}</label>
                <div className="col-8">
                    <div className="form-check mt-2">
                        <Field name="visibility" type="radio" value="FromAnywhere" className="form-check-input" />
                        From anywhere </div>
                    <div className="form-check mt-2">
                        <Field name="visibility" type="radio" value="OnlyFromMAIN" className="form-check-input" />
                        Only from MAIN  </div>
                </div>
            </div>
            }
            {["AzureDataLakeTE", "AwsDataLakeTE"].includes(kind) &&
            <div className="form-group row">
                <label htmlFor="environment" className="col-2 col-form-label">{environmentLabel()}</label>
                <div className="col-8">
                    <div className="form-check mt-2">
                        <Field name="environment" type="radio" value="PPROD" className="form-check-input" />
                        Pre-PROD </div>
                    <div className="form-check mt-2">
                        <Field name="environment" type="radio" value="PROD" className="form-check-input" />
                        PROD  </div>
                </div>
            </div>
            }
            <div className="form-group row mt-3">
                <label htmlFor="technicalInfo" className="col-2 col-form-label">{technicalInfoLabel()}</label>
                    <div className="col-8">
                    <Field type="text" className="form-control"
                                            name="technicalInfo"
                                            placeholder={technicalInfoPlaceHolder()}
                    value={formikContext.values.technicalInfo}
                    readOnly={kind === "AzureDataLakeTE" && (
                        formikContext.values.environment === "PROD" || 
                        formikContext.values.environment === "PPROD"
                    )}
                    />
                    <ErrorMessage name="technicalInfo"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                {!["AzureDataLakeTE"].includes(kind) &&
                <div className="col-2">
                    <Button variant="secondary"
                            onClick={() =>  setHelp("/" + kind + "StorageAccountId.png")}
                            aria-expanded={helpOpen}
                            aria-controls="helpOpen"
                            > How to find <Icon iconName='Help'/></Button>
                </div>
                }
            </div>
            <div className="form-group row">
                <label htmlFor="basePath" className="col-2 col-form-label">{basePathLabel()}</label>
                <div className="col-8">
                    <Field type="text" className="form-control"
                        name="basePath"
                        placeholder={basePathPlaceHolder()}
                    />
                    <ErrorMessage name="basePath"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                {!["AzureDataLakeTE"].includes(kind) &&
                <div className="col-2">
                    <Button variant="secondary"
                        onClick={() =>  setHelp("/" + kind + "Scope.png")}
                        aria-expanded={helpOpen}
                        aria-controls="helpOpen"
                    > How to find <Icon iconName='Help'/></Button>
                </div>
                }
            </div>
            <div className="form-group row">
                <label htmlFor="landingZoneName" className="col-2 col-form-label">{landingZoneNameLabel()}</label>
                <div className="col-8">
                    <Field type="text" className="form-control"
                        name="landingZoneName"
                        placeholder={landingZoneNamePlaceHolder()}
                    />
                    <ErrorMessage name="landingZoneName"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                    <Button variant="secondary"
                        onClick={() =>  setHelp("/" + kind + "landingZoneName.png")}
                        aria-expanded={helpOpen}
                        aria-controls="helpOpen"
                    > How to find <Icon iconName='Help'/></Button>
                </div>
            </div>
            <div className="mt-2" id="adminFormHelp">
                <Collapse in={helpOpen} >
                    <div id="helpOpen">
                        <Image src={helpImg} fluid />
                        {helpImg2!=="" &&
                            <div className="mt-2">
                                <Image src={helpImg2} fluid />
                            </div>
                        }
                    </div>
                </Collapse>
            </div>
            {["AwsDataLakeTE"].includes(kind) &&
            <div className="form-group row">
                <label htmlFor="arnRole" className="col-2 col-form-label">{arnRoleLabel()}</label>
                <div className="col-8">
                    <Field type="text" className="form-control"
                        name="arnRole"
                        placeholder={arnRolePlaceHolder()}
                    />
                    <ErrorMessage name="arnRole"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
            </div>
            }
            {!["AzureDataLakeTE"].includes(kind) &&
            <div className="form-group row">
                <label className="col-2 col-form-label"></label>
                <div className="col-8">
                    <Button variant="secondary"
                    onClick={() =>  setHelpInstr(kind)}
                    aria-expanded={helpOpenInstructions}
                    aria-controls="helpOpenInstructions">{helpOpenInstructions ? <FontAwesomeIcon icon={faCircleMinus} /> : <FontAwesomeIcon icon={faCirclePlus} />} Instructions</Button>
                </div>
            </div>
            }
            <div className="form-group row">
                <label className="col-2 col-form-label"></label>
                <div className="col-8">
                    <Collapse in={helpOpenInstructions} >
                        <div id="helpOpenInstructions">
                            <div id="helpOpenInstructions">
                                { (kind === "AzureBlob" || kind === "AzureDataLake") &&
                                <>
                                    If use of the building block <a href="https://github.com/TotalEnergiesCode/azr-iac-bb-st-o-pub">azr-iac-bb-st-o-pub</a>, check the instructions in the README file.
                                    <br/>
                                    Otherwise, on the storage account,
                                    <ul>
                                        <li>tab Access Control (IAM) / RBAC: add azrpwbflash-api with role Storage Blob Data Contributor </li>
                                        <li>tab Resource sharing (CORS): add a new rule, allowed origins=https://flash.totalenergies.com, Max age:1500, others parameters: * </li>
                                    </ul>
                                </>
                                }
                                { (kind === "AzureFile" ) &&
                                    <>
                                        On the storage account,
                                        <ul>
                                            <li>tab Access Control (IAM) / RBAC: add azrpwbflash-api with role Storage Account Key Operator Service Role </li>
                                            <li>tab Resource sharing (CORS): subtab "File Service", add a new rule, allowed origins=https://flash.totalenergies.com, Max age:1500, others parameters: * </li>
                                        </ul>
                                    </>
                                }  
                                { (kind === "AwsS3" ) &&
                                <>
                                If use of the building block <a href="https://github.com/TotalEnergiesCode/aws-iac-bb-st-o-pub">aws-iac-bb-st-o-pub</a>, check the instructions in the README file.
                                <br />
                                Or, on the S3 bucket on the tab Permissions, follow the steps below:
                                <ul>
                                    <li>Add this bucket policy :</li>
                                    <pre>{bucketPolicy}</pre>
                                    <li>Add this cors configuration: </li>
                                    <pre>{corsConfig}</pre>
                                </ul>
                                </>
                                }
                                {
                                    (kind === "AwsDataLakeTE" ) &&
                                    <>
                                    Create a new role to give the FLASH application the right to access your Data Lake for you.
                                    <br/>
                                    <ul>
                                        <li> The role policy :</li>
                                        <pre>{rolePolicy}</pre>
                                        <li> the Trust relationships :</li>
                                        <pre>{roleTrust}</pre>
                                    </ul>
                                    </>
                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
            {["AzureDataLakeTE", "AwsDataLakeTE"].includes(kind) &&
            <>
            <div>Provide technical information about the Source and Domain Data Lake layer</div>
            <Accordion defaultActiveKey={['0']}className="mt-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{dataLakeSourceLabel()}</Accordion.Header>
                    <Accordion.Body>
                        <div className="form-group row mt-3">
                            <label htmlFor="technicalInfoSource" className="col-2 col-form-label">{technicalInfoLabel()}</label>
                            <div className="col-8">
                                <Field type="text" className="form-control"
                                    name="technicalInfoSource"
                                    placeholder={technicalInfoPlaceHolder()}
                                    value={formikContext.values.technicalInfoSource}
                                    readOnly={kind === "AzureDataLakeTE" && (
                                        formikContext.values.environment === "PROD" ||
                                        formikContext.values.environment === "PPROD"
                                    )}
                                />
                                <ErrorMessage name="technicalInfoSource"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                            </div>
                        </div>   
                        <div className="form-group row mt-3">
                            <label htmlFor="xlsxFile" className="col-2 col-form-label">Upload your file</label>
                            <div className="col-8">
                                <input
                                    ref={source.inputRef}
                                    title="Upload your file"
                                    onChange={(event) => source.handleDisplayFileDetails(event, "source")}
                                    className="d-none"
                                    type="file"
                                />
                                <Button
                                    onClick={() => source.handleClickUpload()}
                                    variant={source.fileName === null ? "secondary" : (source.fileName && source.errorMessage !== '' ? "primary" : "success")}
                                >
                                    {source.fileName === null ? <><FontAwesomeIcon icon={faFileUpload} /> Upload </>: (source.fileName && source.errorMessage !== '' ?
                                            <><FontAwesomeIcon icon={faXmarkCircle} /> {source.fileName}</> : <><FontAwesomeIcon icon={faCheckCircle} /> {source.fileName}</>)}
                                </Button>
                                {source.errorMessage && (
                                <div className="invalid-feedback d-block">
                                {source.errorMessage}
                                </div>
                            )}
                            </div>
                        </div>
                        <div className="form-group row mt-3">
                            <label htmlFor="technicalInfoSource" className="col-3 col-form-label">{dataLakeSourceLabel()}</label>
                            <label htmlFor="technicalInfoSource2" className="col-3 col-form-label">{dataLakeBaseLabel()}</label>
                            <label htmlFor="technicalInfoSource3" className="col-3 col-form-label">{dataLakeObjectLabel()}</label>
                        </div>
                        <div>
                            <FieldArray
                                name="sourceDL"
                                render={(arrayHelpersSource: ArrayHelpers) => (
                                    <div>
                                        {formikContext.values.sourceDL?.map((source: cloudStorageSource, index: number) => (
                                            <div className="form-group row mt-3" key={index}>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`sourceDL.${index}.source`}
                                                        placeholder={dataLakeSourceLabel()}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`sourceDL.${index}.base`}
                                                        placeholder={dataLakeBaseLabel()}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`sourceDL.${index}.object`}
                                                        placeholder={dataLakeObjectLabel()}
                                                    />
                                                    
                                                </div>
                                                <div className="col-2"><Button
                                                        variant="secondary"
                                                        onClick={() => arrayHelpersSource.remove(index)}
                                                    >
                                                        <Icon iconName='Delete'/>
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <ErrorMessage name="sourceDL"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                                        <br/>
                                        <Button
                                            variant="secondary"
                                            onClick={() => arrayHelpersSource.push({ source: '', base: '', object: '' })}
                                        >
                                            <Icon iconName='Add'/> Add Row
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{dataLakeDomainLabel()}</Accordion.Header>
                    <Accordion.Body>
                        <div className="form-group row mt-3">
                            <label htmlFor="technicalInfoDomain" className="col-2 col-form-label">{technicalInfoLabel()}</label>
                            <div className="col-8">
                                <Field type="text" className="form-control"
                                    name="technicalInfoDomain"
                                    placeholder={technicalInfoPlaceHolder()}
                                    value={formikContext.values.technicalInfoDomain}
                                    readOnly={kind === "AzureDataLakeTE" && (
                                        formikContext.values.environment === "PROD" ||
                                        formikContext.values.environment === "PPROD"
                                    )}
                                />
                                <ErrorMessage name="technicalInfoDomain"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="form-group row mt-3">
                            <label htmlFor="xlsxFile" className="col-2 col-form-label">Upload your file</label>
                            <div className="col-8">
                                <input
                                    ref={domain.inputRef}
                                    onChange={(event) => domain.handleDisplayFileDetails(event, "domain")}
                                    className="d-none"
                                    type="file"
                                />
                                <Button
                                    onClick={() => domain.handleClickUpload()}
                                    variant={domain.fileName === null ? "secondary": (domain.fileName && domain.errorMessage !== '' ?
                                    "primary" : "success")}
                                >
                                    {domain.fileName === null ? <><FontAwesomeIcon icon={faFileUpload} /> Upload </>: (domain.fileName && domain.errorMessage !== '' ?
                                        <><FontAwesomeIcon icon={faXmarkCircle} /> {domain.fileName}</> : <><FontAwesomeIcon icon={faCheckCircle} /> {domain.fileName}</>)}
                                </Button>
                                {domain.errorMessage && (
                                <div className="invalid-feedback d-block">
                                {domain.errorMessage}
                                </div>
                            )}
                            </div>
                        </div>
                        <div className="form-group row mt-3">
                            <label htmlFor="technicalInfoDomain" className="col-3 col-form-label">{dataLakeDomainLabel()}</label>
                            <label htmlFor="technicalInfoDomain2" className="col-3 col-form-label">{dataLakeSubDomaiLabel()}</label>
                            <label htmlFor="technicalInfoDomain3" className="col-3 col-form-label">{dataLakeObjectDomainLabel()}</label>
                        </div>
                        <div>
                            <FieldArray
                                name="domainDL"
                                render={(arrayHelpersDomain: ArrayHelpers) => (
                                    <div>
                                        {formikContext.values.domainDL && formikContext.values.domainDL.map((domain: cloudStorageDomain, index: number) => (
                                            <div className="form-group row mt-3" key={index}>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`domainDL.${index}.domain`}
                                                        placeholder={dataLakeDomainLabel()}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`domainDL.${index}.subDomain`}
                                                        placeholder={dataLakeSubDomaiLabel()}
                                                    />
                                                </div>
                                                <div className="col-3">
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        name={`domainDL.${index}.object`}
                                                        placeholder={dataLakeObjectDomainLabel()}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => arrayHelpersDomain.remove(index)}
                                                    >
                                                        <Icon iconName='Delete'/>
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <ErrorMessage name="domainDL"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                                        <br/>
                                        <Button
                                            variant="secondary"
                                            onClick={() => arrayHelpersDomain.push({ domain: '', subDomain: '', object: '' })}
                                        >
                                            <Icon iconName='Add'/> Add Row
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </>
            }
            <br/>
        </>
    );
}



export function TechnicalInformationStepConfig(initialItem:CloudStorage  ) : StepConfig {
    const kind = initialItem.kind;
    let validationSchema: Yup.ObjectSchema<any>; 
    if (kind === "AwsDataLakeTE") {
        validationSchema = Yup.object({
            securityModel: Yup.string().required('required').oneOf(["NoToken" , "CloudStorageLevel" , "ContainerFileShareLevel" , "FolderLevel" , "FileLevel"]),
            technicalInfo: Yup.string().required('required'),
            technicalInfoDomain: Yup.string(),
            technicalInfoSource: Yup.string(),
            basePath:  Yup.string(),
            landingZoneName: Yup.string().required('required'),
            visibility: Yup.string(),
            confidentiality: Yup.string().required('required').oneOf(["C1", "C2", "C3"]),
            environment: Yup.string().required('required').oneOf(["PPROD", "PROD"]),
            arnRole: Yup.string().required('required'),
            sourceDL: Yup.array().of(Yup.object().shape({
                source: Yup.string().notOneOf([""]),
                base: Yup.string().notOneOf([""]),
                object: Yup.string().notOneOf([""])
            })),
            domainDL: Yup.array().of(Yup.object().shape({
                domain: Yup.string().notOneOf([""]),
                subDomain: Yup.string().notOneOf([""]),
                object: Yup.string().notOneOf([""])
            }))
        });
    } else if (kind === "AzureDataLakeTE") {
        validationSchema = Yup.object({
            securityModel: Yup.string().required('required').oneOf(["NoToken" , "CloudStorageLevel" , "ContainerFileShareLevel" , "FolderLevel" , "FileLevel"]),
            technicalInfo: Yup.string().required('required'),
            technicalInfoDomain: Yup.string(),
            technicalInfoSource: Yup.string(),
            basePath: Yup.string(),
            landingZoneName: Yup.string().required('required'),
            visibility: Yup.string().required('required'),
            confidentiality: Yup.string().required('required').oneOf(["C1", "C2", "C3"]),
            environment: Yup.string().required('required').oneOf(["PPROD", "PROD"]),
            sourceDL: Yup.array().of(Yup.object().shape({
                source: Yup.string().notOneOf([""]),
                base: Yup.string().notOneOf([""]),
                object: Yup.string().notOneOf([""])
            })),
            domainDL: Yup.array().of(Yup.object().shape({
                domain: Yup.string().notOneOf([""]),
                subDomain: Yup.string().notOneOf([""]),
                object: Yup.string().notOneOf([""])
            }))
        });
    } else {
        validationSchema = Yup.object({
            securityModel: Yup.string().required('required').oneOf(["NoToken" , "CloudStorageLevel" , "ContainerFileShareLevel" , "FolderLevel" , "FileLevel"]),
            technicalInfo: Yup.string().required('required'),
            basePath:  Yup.string(),
            landingZoneName: Yup.string().required('required'),
            visibility: Yup.string().required('required'),
            confidentiality: Yup.string().required('required').oneOf(["C1", "C2", "C3"])
        });
    }
    return {
        id: 'TechnicalInformation',
        component: <TechnicalInformationStep />,
        initialValues: {
            securityModel: initialItem.securityModel,
            technicalInfo: initialItem.technicalInfo,
            basePath: initialItem.basePath,
            landingZoneName: initialItem.landingZoneName,
            visibility: initialItem.visibility,
            confidentiality: initialItem.confidentiality,
            environment: initialItem.environment,
            arnRole : initialItem.arnRole,
            technicalInfoDomain: initialItem.technicalInfoDomain,
            technicalInfoSource: initialItem.technicalInfoSource,
            sourceDL: initialItem.sourceDL,
            domainDL: initialItem.domainDL
        },
        validationSchema,
        validate: (stepValues, allValues) => {
            const errors :any = {}
            const kind = allValues.Platform.kind;
            const securityModel = stepValues.securityModel;
            validateTechnicalInformation(kind, stepValues, errors);
            validateBasePathAzure(kind, securityModel, stepValues, errors);
            validateAws(kind, securityModel, stepValues, errors);
            return errors;
        },
        disableNextOnErrors: true
    }


    function validateAws(kind: string, securityModel: string, stepValues:Values, errors: any) {
        if (kind === "AwsS3") {
            if (securityModel !== "FileLevel") {
                errors.securityModel = 'not allowed';
            }
            if (!stepValues.basePath.match(/^\d{12}$/)) {
                errors.basePath = 'a valid Aws Account Id';
            }
        } else if (kind === "AwsDataLakeTE") {
            if (!stepValues.basePath.match(/^\d{12}$/)) {
                errors.basePath = 'a valid Aws Account Id';
            }
            if (securityModel !== "NoToken") {
                errors.securityModel = 'not allowed';
            }
            if (!stepValues.arnRole?.match(/^arn:aws:iam::\d{12}:role(?:\/[\w-]+)+$/)) {
                errors.arnRole = 'a valid arnRole';
            }
        }
    }

    function validateBasePathAzure(kind: string, securityModel: string, stepValues:Values, errors: any) {
        if (securityModel !== "NoToken" && securityModel !== "FileLevel") {
            if (!stepValues.basePath || stepValues.basePath === "") {
                errors.basePath = 'required';
            } else {
                switch (kind) {
                    case "AzureBlob":
                        // eslint-disable-next-line
                        if (!stepValues.basePath.match(/^[a-z0-9-]+$/)) {
                            errors.basePath = 'only the name of a container';
                        }
                        break;
                    case "AzureFile":
                        // eslint-disable-next-line
                        if (!stepValues.basePath.match(/^[a-z0-9-]+$/)) {
                            errors.basePath = 'only the name of file share';
                        }
                        break;
                    case "AzureDataLake":
                        if (securityModel === "ContainerFileShareLevel") {
                            if (!stepValues.basePath.match(/^[a-z0-9-]+$/)) {
                                errors.basePath = 'only the name of file share';
                            }
                        }

                }
            }
        }
    }

    function validateTechnicalInformation(kind: string, stepValues:Values, errors: any) {
        switch (kind) {
            case "AzureBlob":
            case "AzureFile":
            case "AzureDataLake":
                // eslint-disable-next-line
                if (!stepValues.technicalInfo.match(/^\/subscriptions\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/resourceGroups\/[\w().-]{1,90}\/providers\/Microsoft\.Storage\/storageAccounts\/[a-z0-9]{3,24}$/)) {
                    errors.technicalInfo = 'valid Azure Storage Id';
                }
                break;
            case "AzureDataLakeTE":
                // eslint-disable-next-line
                if (!stepValues.technicalInfo.match(/^\/subscriptions\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/resourceGroups\/[\w().-]{1,90}\/providers\/Microsoft\.Storage\/storageAccounts\/[a-z0-9]{3,24}$/)) {
                    errors.technicalInfo = 'valid Azure Data Lake Id';
                }
                if (stepValues.sourceDL){
                    stepValues.sourceDL.forEach((source: cloudStorageSource, index: number) => {
                        if (source.source === "" || source.base === "" || source.object === "") {
                            errors.sourceDL = 'None of the source, base or object fields should be empty !';
                        }
                    });
                }
                if (stepValues.domainDL){
                    stepValues.domainDL.forEach((domain: cloudStorageDomain, index: number) => {
                        if (domain.domain === "" || domain.subDomain === "" || domain.object === "") {
                            errors.domainDL = 'None of the domain, subdomain or object fields should be empty !';
                        }
                    });
                }
                break;
            case "AwsS3":
                // eslint-disable-next-line
                if (!stepValues.technicalInfo.match(/^[a-z0-9-.]{3,63}$/)) {
                    errors.technicalInfo = 'only the name of a bucket';
                }
                break;
            case "AwsDataLakeTE":
                // eslint-disable-next-line
                if (!stepValues.technicalInfo.match(/^[a-z0-9-.]{3,63}\/[a-z0-9-.]{3,63}$/)) {
                    errors.technicalInfo = 'only the name of a bucket with app code';
                }
                break;
        }
    }
}