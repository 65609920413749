import { filesize } from "filesize";
import { BaseFolder, SortDirection } from "./types";
import { IMsalContext } from "@azure/msal-react";
import { graphAPIScopes, protectedResources } from "./authConfig";
import { AccessToken, GetTokenOptions, TokenCredential } from "@azure/core-auth";

export const getFileExtension = (name: string): string => {
  const idx = name.lastIndexOf('.');
  if (idx > 0) return name.substring(idx + 1);
  return '';
};

export const getFileName = (path: string): string => {
  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  if (path.endsWith('/')) {
    path = path.substring(0, path.length - 1);
  }

  const idx = path.lastIndexOf('/');
  if (idx > 0) {
    path = path.substring(idx + 1);
  }

  return path;
}
export function safelyDecodeURIComponent(value: string) {
  try {
    return decodeURIComponent(value);
  } catch (error) {
    console.warn(
      `The value for the URL param will not be decoded because` +
        ` the string "${value}" is a malformed URL segment. This is probably` +
        ` due to a bad percent encoding (${error}).`
    );

    return value;
  }
}



export function encodePath(path:string){
  return path.split("/").map(encodeURIComponent).join("/");
}

function SortRecent(a: BaseFolder, b: BaseFolder, recents: string|null): number {
  if ( recents ){
    const idxa = recents.indexOf("," + a.displayName + ",");
    const idxb = recents.indexOf("," + b.displayName + ",");
    if( idxa>=0 && idxb>=0 )
        return idxa < idxb ? -1 : 1;
    if ( idxa>=0)
        return -1;
    if (idxb>=0)
        return 1;
    return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
  }
  return 0;
}

export const SortBaseFolder = (eles :BaseFolder[], direction: SortDirection, recents: string|null ) => {

  const newEle =  eles.sort((a,b)=> {
      if ( direction === SortDirection.Recent  )
          return SortRecent(a,b,recents);
      if ( direction === SortDirection.Ascending)
          return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
      if ( direction === SortDirection.Descending)
          return a.displayName.toLowerCase() > b.displayName.toLowerCase() ? -1 : 1;
      return 0;
  });
  return newEle;
}
export function myfileSize( arg:any):string
{
  return filesize(arg, {base: 2, exponent:1, pad:true, round:0, standard: "jedec", locale:"fr-fr"}).toString();
}
export function myfileSize2( arg:any):string
{
  return filesize(arg, {base: 2, pad:true, round:0, standard: "jedec", locale:"fr-fr"}).toString();
}

export const handleLogin = (msalContext : IMsalContext) => {
  const request = {
      // mettre si on veut forcer une connexion avec @totalenergies.com mais si on veut aussi se connecter avec compte AL/AJ laisser vide
      // loginHint: "name@totalenergies.com",
      scopes: graphAPIScopes,
      prompt: 'select_account'
    }      
  msalContext.instance.loginRedirect( request );
}


export class AADTokenCredential  implements TokenCredential {
  private msalContext: IMsalContext;
  constructor(msalContext: IMsalContext) {
    this.msalContext = msalContext;
  }
  async getToken(scopes: string | string[], options?: GetTokenOptions | undefined): Promise<AccessToken> {
    const ar = await  this.msalContext.instance.acquireTokenSilent({
      //scopes: [...scopes],
      scopes:  protectedResources.azurestorage.scopes,
      account: this.msalContext.accounts[0]
    });
    return {
      token: ar.accessToken,
      expiresOnTimestamp: ar.expiresOn?.getTime() ??0 
    };
  }
}
