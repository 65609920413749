import { PublicClientApplication } from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import React from "react";

export type DirectoryFLASH =
{
  name: string;
  path: string;
  canRename?: boolean;
  canMove?: boolean;
}
export type BaseFolderFLASH =
{
  name: string;
  path: string;
  kind: string;
}
export type FileFLASH =
{
  name: string;
  path: string;
  modified?: Date;
  size: number;
  canRename?: boolean;
  canMove?: boolean;
}

export type ContentItem =
  ({ isFile: true, isBaseFolder:false } & FileFLASH) |
  ({ isFile: false, isBaseFolder:true } & BaseFolderFLASH) |
  ({ isFile: false, isBaseFolder:false  } & DirectoryFLASH)  
  ;

export type TokenInfo = {
    urlWithSignature: string[];
    uploadId : string |undefined;
}

export interface ProgressCallback{
  loadedBytes: number;
}

export interface IDirectoryProvider
{
  get(path: string, options?: {continuationToken?: string, continuationTokenSearch?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent>; 
  upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<boolean>;
  createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean>;
  delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean>;
  download( path:string, item:ContentItem, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<Blob|undefined>;
  rename(path:string, item:ContentItem, newName:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean>;
  move(path:string, item:ContentItem, newPath:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean>;
}
export interface ITokenProvider extends IDirectoryProvider
{
  // filesize and partSize in Mo
  getToken( operation:"Read" | "Write" | "List" | "Delete" | "ReadList" | "All", path:string, durationInMinutes: number, options?: { abortSignal?: AbortSignal, fileSize?: number, partSize?: number, continuationToken?: string, continuationTokenSearch?: string, pageSize?: number, prefixSearch?: string } ): Promise<TokenInfo|undefined>;

}

export interface INavigator
{
  navigate : (path: string)=> void;
  refresh: () => void;
}

export class APIError extends Error {
  baseError: any;
  constructor(message:string, baseError: any) {
    super(message); 
    this.name = this.constructor.name;
    this.baseError = baseError;
  }
}

export type IDirectoryContent = {
  elements :  ContentItem[]
  baseFolder: BaseFolder 
  tokenProvider: ITokenProvider
  continuationToken?: string
  prefixSearch?: string
  pagesize?: number
}

export enum SortDirection {
  Recent,
  Ascending,
  Descending
}
export interface ISearch {
  search: (search:string) => void;
}

export type  ILoginContext = {
  isAuthenticated: boolean
  msalContext: IMsalContext
  msalInstance?: PublicClientApplication
}

export type cloudStorageSource = {
    "source":string,
    "base":string,
    "object":string
}
export type cloudStorageDomain = {
  "domain":string,
  "subDomain":string,
  "object":string
}
export type CloudStorage = {
  cloudStorageId: string;
  technicalInfo: string;
  technicalInfoDomain: string;
  technicalInfoSource: string;
  displayName: string;
  kind: "AzureBlob" | "AzureFile" |"AzureDataLake" |"GlobalAdmin" | "AwsS3" | "AwsDataLakeTE" | "AzureDataLakeTE" | undefined;
  basePath: string| undefined;
  maxDurationMinutes: number;
  logInfo: string | undefined;
  generateTokenPerFile: boolean | undefined;
  landingZoneName: string | undefined;
  cmdbBusinessService: string | undefined;
  cmdbApplicationService: string | undefined;
  visibility: string | undefined;
  confidentiality: string | undefined;
  environment: string | undefined;
  arnRole: string | undefined;
  sourceDL: cloudStorageSource[] | undefined;
  domainDL: cloudStorageDomain[] | undefined;
  branch: string | undefined;
  securityModel: "NoToken" | "CloudStorageLevel" | "ContainerFileShareLevel" | "FolderLevel" | "FileLevel"| undefined;
}

export type Permission = {
  cloudStorageId:string;
  operation:string;
  kind:string,
  objectId: string;
  displayName :string;
  email: string;
}

export type Review = {
  reviewedBy?:string;
  reviewedDate?:Date;
}
export type GraphResult = {
  displayName: string |null |undefined;
  objectId: string|null |undefined;
  kind: string;
  mail?: string;
}

export interface IGraphProvider
{
  canSearch: boolean;
  objectName: string;
  objectIdFieldName:string;
  search(instance: IMsalContext, search:string, additionalFilter?: string): Promise<GraphResult[]>; 
}

// indicates if current user is admin or not
// set in layout.tsx
export const AdminContext = React.createContext(
  false
);

export type BaseFolder = {
  cloudStorageId: string;
  displayName: string;
  kind:"AzureBlob" | "AzureFile" |"AzureDataLake" |"GlobalAdmin" | "AwsS3" | "AwsDataLakeTE" | "AzureDataLakeTE";
  operationAllowed: string;
  securityModel: "NoToken" | "CloudStorageLevel" | "ContainerFileShareLevel" | "FolderLevel" | "FileLevel";
  maxDurationMinutes: number;
}
export type BaseFolderWithVoucher =  BaseFolder & {
  voucher: string;
  urlGetToken: string;
  urlCompleteUpload: string | undefined;
  isAwsAPI: boolean;
  roleArn: string | undefined;
  roleSessionName: string | undefined;
}
export type DataLakeSource = {
  source: string;
  base: string;
  object: string;
}
export type DataLakeDomain = {
  domain: string;
  subdomain: string;
  object: string;
}

export type BaseFolderDataLakeTE = BaseFolder & {
  sourceDL:  DataLakeSource[];
  domainDL: DataLakeDomain[];
}

export type BaseFolderAzureDataLakeTE = BaseFolderDataLakeTE & {
  appStorageAccount: string;
  appContainer: string;
  sourceStorageAccount?: string;
  domainStorageAccount?: string;
}
export type BaseFolderAwsDataLakeTE = BaseFolderDataLakeTE & {
  flashRoleArn: string;
  flashRoleSessionName: string;
  appRoleArn: string;
  appBucket: string;
  appCode: string;
  sourceBucket?: string;
  domainBucket?: string;
  urlAuthenticate: string;
  voucher: string;  
}

export type BaseFolderDetails = 
  ({kind:"AzureDataLakeTE"} & BaseFolderAzureDataLakeTE) |
  ({kind:"AwsDataLakeTE"} & BaseFolderAwsDataLakeTE) |
  ({kind:"AzureBlob" | "AzureFile" |"AzureDataLake" |"GlobalAdmin" | "AwsS3" } & BaseFolderWithVoucher);
  
export const BaseFolderQueryKey = (cloudStorageId: string) => ["BaseFolder", cloudStorageId];
export const BaseFolderQueryURL = (cloudStorageId: string) => "Me/CloudStorage/" + cloudStorageId;
export const getAADToAwsTokenQueryKey = () => ["AADToAwsToken"];
