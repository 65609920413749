import { FunctionComponent } from "react"
import { useWizard } from "react-formik-step-wizard"

export interface WizardHeaderProps {
    stepsLabel: string[]
}
 

export const WizardHeader: FunctionComponent<WizardHeaderProps> = ({stepsLabel}) => {
     /** stepNumber = Current index, numbering starts from 1 */
    const { stepNumber, totalSteps } = useWizard() 
    return (
        <div className="d-flex">
            <div className="w-100">
                { totalSteps !== stepsLabel.length && <div>warning number of steps and number of step labels do not match</div> }
                <div className="stepper-horizontal">
                    {
                        stepsLabel.map( (item, index) => (
                            <div key={index} className={"stepper-step" + (index+1===stepNumber ? " active-step" : "" ) }>
                                <div className="stepper-circle" ><span>{index+1}</span></div> 
                                <div className="stepper-title">{item}</div>  
                                { index>0 && <div className="stepper-bar-left"></div> }
                                { index<stepsLabel.length-1 && <div className="stepper-bar-right"></div> }
                                
                            </div>
                        ) )
                    }
                </div>
            </div>
        </div>

    )
}
