import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { initializeIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// import 'bootstrap/dist/css/bootstrap.min.css';
initializeIcons();
initializeFileTypeIcons();

console.log(process.env);
let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHT_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});
appInsights.loadAppInsights();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
root.render(
  // cause double calls to API and sometime erros (double delete and so on)... Only in dev
  // <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
            <App />
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider >
      </MsalProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
