import { LoaderFunctionArgs, useParams } from 'react-router-dom';
import { DirectoryBrowser } from '../components/DirectoryBrowser'; 
import { BaseFolderDetails, ILoginContext, ITokenProvider } from '../types';
import { TokenProvider } from '../providers/TokenProvider';
import { QueryClient } from 'react-query';
import { callApiGetBaseFolder } from '../fetch';
import { AzureDataLakeTEProvider } from '../providers/AzureDataLakeTEProvider';
import { AwsDataLakeTE } from '../providers/AwsDataLakeTE';

const pageSize = 1000;

export const ListFolderContent = (context: ILoginContext, queryClient: QueryClient) => 
    async ({ params, request }: LoaderFunctionArgs) =>  {
        const cloudStorageId = params["cloudStorageId"] ;
        const realpath = params["*"] ?? "";
        const perimeter = params["perimeter"] ; // only for datalake routes . Not for classic routes
        // router-dom double decode the url. But we need to keep the original url to be able to call the api        
        const url = new URL(request.url);

        if (cloudStorageId && context.isAuthenticated){
            const cloudStorageInfo = await callApiGetBaseFolder<BaseFolderDetails>(queryClient,cloudStorageId, context.msalContext);
            if ( cloudStorageInfo){
                let provider: ITokenProvider;            
                switch (cloudStorageInfo.kind){
                    case "AwsDataLakeTE":
                        provider = new AwsDataLakeTE(context.msalContext,cloudStorageInfo,perimeter, queryClient );
                        break;
                    case "AzureDataLakeTE":
                        provider = new AzureDataLakeTEProvider(context.msalContext,cloudStorageInfo, perimeter );
                        break;
                    default:
                        provider = new TokenProvider(context.msalContext,cloudStorageInfo,queryClient );
                        break;
                }
    
                // pagination and search... null when we arrive in a folder and not null when used by the fetcher of DirectoryBrowser
                const prefixSearch = url.searchParams.get("prefixSearch") ?? undefined;
                const continuationToken = url.searchParams.get("continuationToken") ?? undefined;
                
                const contents = await provider.get(realpath, {pageSize: pageSize, prefixSearch: prefixSearch, continuationToken: continuationToken});
                return contents;
            }
            throw new Error("Cannot find cloud storage");
        }    
        return null;
};

const Navigation = () => {
    const params = useParams();
    return (
        <DirectoryBrowser cloudStorageId={params["cloudStorageId"] ?? 'fake' } realpath={params["*"] ?? 'fake'} />
    );
}
export default Navigation;


