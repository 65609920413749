import { BaseFolderWithVoucher, ContentItem, IDirectoryContent, IDirectoryProvider, ITokenProvider, ProgressCallback, TokenInfo } from "../types";
import {XMLParser} from 'fast-xml-parser';
import {
    TransferProgressEvent,
    createDefaultHttpClient,
    createPipelineRequest
  } from "../modules/fetchHttpClient";
import { getFileName } from "../utils";
import parallelBatch from 'it-parallel-batch'
import all from 'it-all'
import { callAwsApiBackend } from "../fetch";
import { IMsalContext } from "@azure/msal-react";
import { QueryClient } from "react-query";

export class AwsS3Provider implements IDirectoryProvider {
    private tokenInfo: TokenInfo;
    private baseFolder :BaseFolderWithVoucher;
    private tokenProvider: ITokenProvider;
    private concurrency = 5;
    private msalContext: IMsalContext;
    private queryCLient: QueryClient;    

    constructor(token: TokenInfo, baseFolder: BaseFolderWithVoucher, tokenProvider: ITokenProvider, msalContext : IMsalContext, queryCLient: QueryClient) {
        this.tokenInfo = token;
        this.baseFolder = baseFolder;
        this.tokenProvider = tokenProvider; 
        this.msalContext = msalContext;
        this.queryCLient = queryCLient;
    }

    async get(path: string, options?: {continuationToken?: string, continuationTokenSearch?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
        const url = this.tokenInfo.urlWithSignature[0];
        const response = await fetch(url, { signal: options?.abortSignal });
        const resultXml = await response.text();
        // const content = xmljs.xml2json(resultXml);
        const responseJson = new XMLParser().parse(resultXml);
        let contents: ContentItem[] = [];
        if ( responseJson.ListBucketResult.CommonPrefixes) {
            if (Array.isArray(responseJson.ListBucketResult.CommonPrefixes))
            {
                for (const folderS3 of responseJson.ListBucketResult.CommonPrefixes) {
                    this.fromFolder(path, contents, folderS3);
                }
            } else {
                this.fromFolder(path, contents, responseJson.ListBucketResult.CommonPrefixes);
            }
        }
        if ( responseJson.ListBucketResult.Contents) {
            if (Array.isArray(responseJson.ListBucketResult.Contents))
            {
                for ( const fileS3 of responseJson.ListBucketResult.Contents) {
                    this.fromFile(path,contents, fileS3);
                }
            } else {
                this.fromFile(path,contents, responseJson.ListBucketResult.Contents);
            }
        }
        if (responseJson.ListBucketResult.IsTruncated) {
            return {
                elements: contents,
                baseFolder: this.baseFolder,
                tokenProvider: this.tokenProvider,
                continuationToken: responseJson.ListBucketResult.NextContinuationToken,
                prefixSearch: options?.prefixSearch,
                pagesize: options?.pageSize
            }
        }
        else {
            return {
                elements: contents,
                baseFolder: this.baseFolder,
                tokenProvider: this.tokenProvider,
                pagesize: options?.pageSize,
                prefixSearch: options?.prefixSearch
            }
        }
    }
    private decodeKey(key: string) {
        return decodeURIComponent(key.replace(/\+/g, '%20'));
    }
    private fromFile(path: string, contents: ContentItem[], fileS3: any) {
        // for some folders (not all), the item in the result is the folder itself, so we remove it
        if (fileS3.Key?.endsWith("/"))
            return;
        const prefix = this.decodeKey(fileS3.Key);        
        contents.push({
            isFile: true,
            name: getFileName(prefix),
            path: prefix,
            modified: new Date(fileS3.LastModified),
            size: fileS3.Size,
            isBaseFolder: false,
        });
    }

    private fromFolder(path: string, contents: ContentItem[], folderS3: any) {
        const prefix = this.decodeKey(folderS3.Prefix);
        contents.push({
            isFile: false,
            name: getFileName(prefix),
            path: prefix,
            isBaseFolder: false,
        });
    }


    async download(path: string, item: ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<Blob | undefined> {
        const client = createDefaultHttpClient();
        const url = this.tokenInfo.urlWithSignature[0];
        const request = createPipelineRequest({
          url: url,
          method: "GET",
          enableBrowserStreams: false,
          streamResponseStatusCodes: new Set([Number.POSITIVE_INFINITY]),
          onDownloadProgress:(progress: TransferProgressEvent) => {
            options?.onprogress({ loadedBytes: progress.loadedBytes });
          },
          abortSignal: options?.abortSignal
        });
        const response = await client.sendRequest(request);
        const blob = await response.blobBody;

        return blob;
    }
    async upload(path: string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<boolean> {
        // path not relevant, included in token url
        if ( this.tokenInfo.urlWithSignature.length === 1 ) {
            await this.uploadSinglePart(this.tokenInfo.urlWithSignature[0], file, options);
        } else {
            const result = await this.uploadParallel(file, options);
            await this.completeUpload(path, file, result);
        }
        return true;
    }
    
    private async completeUpload(path: string, file: File, result: string[]) {
        if (  this.baseFolder.roleArn && this.baseFolder.roleSessionName && this.baseFolder.urlCompleteUpload) {
            let fullname = path;
            if (path)
                fullname += (path.endsWith("/") ? "" : "/") + file.name;

            else
                fullname = file.name;
            const url = this.baseFolder.urlCompleteUpload;
            const te = (new URL("https://dummy.com/?" + this.baseFolder.voucher)).searchParams.get("te"); // just get the te parameter in the voucher
            const parts = result.map((etag, index) => {
                return {
                    'ETag': etag,
                    'PartNumber': index + 1
                };
            });
            const body = {
                'te': te,
                'path': fullname,
                'uploadId': this.tokenInfo.uploadId,
                'parts': parts
            };
            await callAwsApiBackend(this.msalContext, this.queryCLient, this.baseFolder.roleArn, this.baseFolder.roleSessionName, url, "PUT",{ body:body });
        }
    }

    private async uploadParallel(file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }) {
        let blockSize = Math.ceil(file.size / this.tokenInfo.urlWithSignature.length);
        if (blockSize < 5 * 1024 * 1024)
            blockSize = 5 * 1024 * 1024; // https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
        let arrayTask = [];
        // init an array with same size than this.tokenInfo.urlWithSignature and init each element with 0
        const loadedBytes = new Array(this.tokenInfo.urlWithSignature.length).fill(0);
        for (let i = 0; i < this.tokenInfo.urlWithSignature.length; i++) {
            const opts = {
                onprogress: (progress: ProgressCallback) => {
                    loadedBytes[i] = progress.loadedBytes;
                    options?.onprogress({ loadedBytes: loadedBytes.reduce((a, b) => a + b, 0) });
                },
                abortSignal: options?.abortSignal
            }
            arrayTask.push(async (): Promise<string> => {
                const offset = i * blockSize;
                const size = i === this.tokenInfo.urlWithSignature.length - 1 ? file.size - offset : blockSize;
                const blobPart = file.slice(offset, offset + size);
                if ( options?.abortSignal && options?.abortSignal.aborted)
                    throw new DOMException("AbortError");
                const etag = await this.uploadSinglePart(this.tokenInfo.urlWithSignature[i], blobPart, opts);
                return etag;
            });
        }
        const result = await all(parallelBatch(arrayTask, this.concurrency));
        return result;
    }

    private async uploadSinglePart(url: string, file: Blob, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<string> {
        const client = createDefaultHttpClient();
        const request = createPipelineRequest({
            url: url,
            method: "PUT",
            body: file,
            onUploadProgress: options?.onprogress,
            abortSignal: options?.abortSignal
        });

        const response = await client.sendRequest(request);
        const etag = response.headers.get("Etag");
        if (response.status === 200 && etag)
            return etag;
        if ( response.status === 200 && !etag)
            throw new Error("On the cors policy of the bucket, please add 'ExposeHeaders' with the value 'ETag' for flash");
        throw new Error("cannot upload");
    }

    async createFolder(path: string, subfolder: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
        const client = createDefaultHttpClient();
        const request = createPipelineRequest({
            url: this.tokenInfo.urlWithSignature[0],
            method: "PUT",
            body: subfolder + "/",
            abortSignal: options?.abortSignal
        });

        const response = await client.sendRequest(request);
        const etag = response.headers.get("Etag");
        if (response.status === 200 && etag)
            return true;
        if ( response.status === 200 && !etag)
            throw new Error("On the cors policy of the bucket, please add 'ExposeHeaders' with the value 'ETag' for flash");
        throw new Error("cannot upload");
    }
    async delete(path: string, item: ContentItem, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
        const client = createDefaultHttpClient();
        const url = this.tokenInfo.urlWithSignature[0];
        const request = createPipelineRequest({
          url: url,
          method: "DELETE",
          enableBrowserStreams: false,
          streamResponseStatusCodes: new Set([Number.POSITIVE_INFINITY]),
          abortSignal: options?.abortSignal
        });
        const response = await client.sendRequest(request);
        await response.blobBody;
        return true;
    }
    rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
        throw new Error('Method not supported.');
    }
    move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
        throw new Error('Method not supported.');
    }
}


