import { Field } from "formik";
import { CloudStorage } from "../../types";
import { StepConfig } from "react-formik-step-wizard";
import * as Yup from 'yup';
import { Accordion } from "react-bootstrap";



const PlatformStep = () => {
    return (
        <>
            <div>Choose the platform that hold the storage</div>
            <Accordion defaultActiveKey={['0','1']} alwaysOpen className="mt-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>TotalEnergies DataLake</Accordion.Header>
                    <Accordion.Body>
                        <div className="form-check mt-2">
                            <label className="form-check-label">
                                <Field name="kind" type="radio" value="AwsDataLakeTE" className="form-check-input" />
                                TotalEnergies Aws Data Lake 
                                <small className="form-text text-muted">
                                    <br/>
                                    Product page <a href="https://digitalplatforms.totalenergies.com/products/data/data-lake-s3">Data Lake S3</a>
                                </small>
                            </label>
                        </div>
                        <div className="form-check mt-2">
                            <label className="form-check-label">
                                <Field name="kind" type="radio" value="AzureDataLakeTE" className="form-check-input" />
                                TotalEnergies Azure Data Lake  
                                <small className="form-text text-muted">
                                    <br/>
                                    Product page <a href="https://digitalplatforms.totalenergies.com/products/data/azure-data-lake-gen2-firewall">Azure Data Lake Gen2</a>
                                </small>
                            </label>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Other S3 / storage account</Accordion.Header>
                    <Accordion.Body>
                        <div >
                            <div className="form-check mt-1">
                                <label className="form-check-label" >
                                    <Field name="kind" type="radio" value="AwsS3" className="form-check-input" />
                                        Aws S3
                                </label>
                                <small className="form-text text-muted">
                                    <br/>
                                    <a href="https://confluence.totalenergies.com/x/C4hiHw">ST_O_PUB</a>: Durable Object Storage (S3), used for data exchange with a 3rd party (User or Machine) over internet with a pre-signed URL or with AWS console for admin users.<br/>
                                </small>                
                            </div>    
                        </div>

                        <div className="form-check mt-2">
                            <label className="form-check-label" >
                                <Field name="kind" type="radio" value="AzureBlob" className="form-check-input" />
                                    Azure Blob Storage
                            </label>
                            <small className="form-text text-muted">
                                <br/>
                                <a href="https://confluence.totalenergies.com/x/yopQHw">ST_O_PUB</a>: Durable Object Storage, can be used to store any kind of unstructured objects, and is accessible publicly using a temporary token<br/>
                            </small>                
                        </div>               
                        <div className="form-check mt-2">
                            <label className="form-check-label">
                                <Field name="kind" type="radio" value="AzureDataLake" className="form-check-input" />
                                Azure Blob Storage with hierarchical namespace (HNS) activated
                                <small className="form-text text-muted">
                                <br/>
                                    <a href="https://confluence.totalenergies.com/x/yopQHw">ST_O_PUB</a>: Durable Object Storage, can be used to store any kind of unstructured objects, and is accessible publicly using a temporary token<br/>
                                    With this option, the base folder in flash can be a subfolder in the datalake. Flash can deliver SAS token for folders.
                                </small>
                            </label>
                        </div>                                     
                        <div className="form-check mt-2">
                            <label className="form-check-label">
                                <Field name="kind" type="radio" value="AzureFile" className="form-check-input" />
                                Azure Files
                                <small className="form-text text-muted">
                                    <br/>
                                    <span className="text-danger">
                                        To use this option, you need to rotate the second Access Key every 6 months. In the Azure portal, tab "Access Keys", rotate the key2.
                                    </span>
                                    <br/>                    
                                    <a href="https://confluence.totalenergies.com/x/UodRHQ">ST_FS_1</a>: firewall rules of storage account are applicable. For landing zone Internet+Main, available from Vision desktop with zscaler
                                </small>
                            </label>
                        </div>
                    

                    </Accordion.Body>
                </Accordion.Item>
                
            </Accordion>



        </>
    );
}

export function PlatformStepConfig(initialItem:CloudStorage  ) : StepConfig {
    return {
        id: 'Platform',
        component: <PlatformStep />,
        initialValues: {
            kind: initialItem.kind
        },
        validationSchema: Yup.object({
            kind:  Yup.string().required('kind of storage is required').oneOf(["AzureBlob", "AzureFile", "AzureDataLake", "AzureDataLakeTE", "AwsS3", "AwsDataLakeTE"]),
        }),
        hidePrevious: true,
        disableNextOnErrors: true
    }   
}