import { useMsal} from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Button, ButtonGroup, Col,  Form, FormGroup, InputGroup, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { LoaderFunctionArgs, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { BaseFolder, ILoginContext, SortDirection } from "../types";
import { useCallback, useEffect, useState } from "react";
import { SortBaseFolder, handleLogin as handleLoginUtils } from "../utils";
import { callApiBackend } from "../fetch";

export const ListContextes = (context: ILoginContext) => 
    async ({ request, params }: LoaderFunctionArgs) =>  {
    if (context.isAuthenticated && context.msalContext.inProgress ==="none"){
        let bfs = await callApiBackend<BaseFolder[]>(context.msalContext, "Me/CloudStorage", "GET", {});
        return bfs;
    }
    return null;
};


const Home = () => {
    const msalContext = useMsal();
    const navigation = useNavigation();
    const localStorageRecent = "HomeRecent";
    const localStorageRecentDL = "HomeRecentDL";
    const localStorageLastSort = "HomeLastSort";
    const recents = localStorage.getItem(localStorageRecent);
    const recentsDL = localStorage.getItem(localStorageRecentDL);

    let navigate = useNavigate();    
    const folderContent=  useLoaderData() as BaseFolder[];
    const [search, setSearch] = useState("");
    const initialSort = Number( localStorage.getItem(localStorageLastSort) ) as SortDirection; // si pas présentr : donne SortDirection.Recent
    const [sort, setSort] = useState(initialSort) 

    const [elements, setElements] = useState<BaseFolder[]>([]);
    const [elementsDL, setElementsDL] = useState<BaseFolder[]>([]);
    useEffect(() => {
        if (folderContent)
        {
            const others = folderContent.filter((item,i) => item.kind !== "AwsDataLakeTE" &&  item.kind !== "AzureDataLakeTE");
            setElements( SortBaseFolder(others, initialSort, recents) );
            // filter dls. Sort by cloudsotrageid 
            // ppr dl and prod dl have same displayname and alsmost same cloudstorageid... Keep only the prod if both are present
            // pprod < prod
            let regex = /_[^_]*_/;
            const dls = folderContent.filter((item,i) => item.kind === "AwsDataLakeTE" || item.kind === "AzureDataLakeTE").sort((a,b)=> a.cloudStorageId < b.cloudStorageId ? -1 : 1)
                .reduce((acc:BaseFolder[], item:BaseFolder) => {
                    if (acc.length === 0)
                        return [item];
                    const last = acc[acc.length-1];
                    const lastMatch = regex.exec(last.cloudStorageId);
                    const itemMatch = regex.exec(item.cloudStorageId);
                    // same app on datalake 
                    if (lastMatch && itemMatch && lastMatch[0] === itemMatch[0])
                    {
                        // we can have the same app on Azure and Aws. Not very frequent as a project is on Azure or Aws but can happens. In that case, we create 2 card and we add " (AWS)" or " (Azure)" to the displayname
                        if (last.cloudStorageId.substring(0,3) !== item.cloudStorageId.substring(0,3))
                        {
                            last.displayName = last.displayName + " (AWS)";
                            item.displayName = item.displayName + " (Azure)";
                            return  [...acc, item];
                        } else {
                            // pprod and prod : keep only prod
                            acc.pop()
                            return [...acc, item];
                        }
                    }
                    return [...acc, item];
                }, [] as BaseFolder[]);
            setElementsDL( SortBaseFolder(dls, initialSort, recentsDL) );
        }
    }, [folderContent,initialSort,recents,recentsDL]);
    
    const  handleLogin = useCallback(() => {
        handleLoginUtils(msalContext);
      }, [msalContext]);

    const onSearch = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.currentTarget.value.toLowerCase());
    }
    const resetSearch= (event: any) => {
        setSearch("");
    }
    const onSort = (direction :SortDirection ) => {
        setSort(direction);
        setElements(SortBaseFolder([...elements], direction,recents));
        setElementsDL(SortBaseFolder([...elementsDL], direction,recents));
        localStorage.setItem(localStorageLastSort, direction.toString());
    }
    const onClickCard= (event:any, path :string, name:string) => {
        event.preventDefault();
        let existing=localStorage.getItem(localStorageRecent);
        if (!existing)
            existing = ",";
        existing = "," +  name + existing.replace( "," + name , "");
        localStorage.setItem(localStorageRecent, existing);
        navigate(path);
    }
    const onClickCardDL= (event:any, path :string, name:string) => {
        event.preventDefault();
        let existing=localStorage.getItem(localStorageRecentDL);
        if (!existing)
            existing = ",";
        existing = "," +  name + existing.replace( "," + name , "");
        localStorage.setItem(localStorageRecentDL, existing);
        navigate("dl/" + path);
    }

    return (
            <div className="container-fluid" id="home">

                <AuthenticatedTemplate>
                    <div className="row">
                        <div className="col-3" id="explanation">
                            <h1>What is Flash ?</h1>
                            <p>FLASH is a web interface that enables multiple end-users and application admins who have a LIFT account to upload and download files to and from cloud storage (Inox@Scale: Blob, fileshare and S3 Bucket for AWS) from both the internet and TotalEnergies' main network</p>
                            <p>Use FLASH to get SAS tokens (Azure) or presigned URLs (AWS) for use in scripts </p>
                            <div>Release notes:
                                <ul>
                                    <li>v5: TTE Data Lake</li>
                                    <li>v4: mass dowload/upload</li>
                                    <li>v3: Self Service module: any admin account can declare a new cloud storage</li>
                                    <li>v2: new design, search and sort content</li>
                                    <li>v1: initial release (Azure)</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col mt-2" id="listclouds">
                            <Row>
                                <Col xs></Col>
                                <Col xs={8}>
                                    <Form  >
                                        <Row>
                                            <Col>
                                                <FormGroup >
                                                    <ButtonGroup role="toolbar" aria-label="sortby">
                                                        <Button variant="secondary" active={sort === SortDirection.Recent}  onClick={() => onSort(SortDirection.Recent)}>Recently opened</Button>
                                                        <Button variant="secondary" active={sort === SortDirection.Ascending} onClick={() => onSort(SortDirection.Ascending)}>A-Z</Button>
                                                        <Button variant="secondary" active={sort === SortDirection.Descending} onClick={() => onSort(SortDirection.Descending)}>Z-A</Button>
                                                    </ButtonGroup>     
                                                                                    
                                                    {(navigation.state === "loading" || folderContent === null) &&     
                                                        <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>     
                                                    }                                              
                                                </FormGroup>                                            
                                            </Col>
                                            <Col>
                                                <InputGroup className="flashsearch">
                                                    <Form.Control
                                                        type="text"
                                                        value={search}
                                                        placeholder="search"
                                                        onChange={onSearch}
                                                        >
                                                    </Form.Control>
                                                    <Button variant="" onClick={resetSearch} >
                                                        <FontAwesomeIcon icon={faTimes} className="fa-fw" />
                                                    </Button> 
                                                </InputGroup>
                                            </Col>
                                        </Row>


                                    </Form>
                                  
                                </Col>
                                <Col xs></Col>
                            </Row>
                            {
                                elementsDL.length > 0 &&
                                <>
                                    { elements.length>0 && <h5>Data Lake</h5> }
                                    <Row id="contentBrowser" className="mt-3">
                                        <Col xs></Col>                                
                                        <Col xs={8} className={(navigation.state === "loading" || folderContent === null) ? "opaque" : ""}>
                                            <div className='row'>
                                            {
                                                elementsDL.map((item, i) => {
                                                    return (
                                                        <div key={item.cloudStorageId} className={'col-3 d-flex align-items-stretch' + (search !== "" && !item.displayName.toLowerCase().includes(search) ? " d-none" :"") }  >
                                                            <div className={'card w-100 card' + (i%7)} onClick={(event)=>onClickCardDL(event, item.cloudStorageId, item.displayName)} onKeyUp={(event)=> onClickCardDL(event, item.cloudStorageId, item.displayName)}>
                                                            <div className="card-body">
                                                                {item.displayName}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                )
                                            }
                                            </div>
                                        </Col>
                                        <Col xs></Col>
                                    </Row>                                
                                </>
                            }

                            {
                                elements.length>0 &&
                                <>
                                    { elementsDL.length>0 && <h5>Cloud storage</h5> }
                                    <Row id="contentBrowser" className="mt-3">
                                        <Col xs></Col>                                
                                        <Col xs={8} className={(navigation.state === "loading" || folderContent === null) ? "opaque" : ""}>
                                            <div className='row'>
                                            {
                                                elements.map((item, i) => {
                                                    return (
                                                        <div key={item.cloudStorageId} className={'col-3 d-flex align-items-stretch' + (search !== "" && !item.displayName.toLowerCase().includes(search) ? " d-none" :"") }  >
                                                            <div className={'card w-100 card' + (i%7)} onClick={(event)=>onClickCard(event, item.cloudStorageId, item.displayName)} onKeyUp={(event)=>onClickCard(event, item.cloudStorageId, item.displayName)}>
                                                            <div className="card-body">
                                                                {item.displayName}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                )
                                            }
                                            </div>
                                        </Col>
                                        <Col xs></Col>
                                    </Row>
                                </>
                            }

                        </div>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className="row">
                        <div className="px-4 col text-center">
                            Please connect with a TotalEnergies account <br/>
                            ex: firstname.lastname@totalenergies.com <br/>
                            or firstname.lastname@external.totalenergies.com <br/>
                            or AXXXXXXXX@admin.hubtotal.net <br/>
                            <br/>
                            Self service/admin functions are available only for admin accounts (AXXXXXXXX@admin.hubtotal.net)
                            <div className="mt-3">
                                <Button  variant="primary" onClick={handleLogin} >Login</Button>
                            </div>
                        </div>
                    </div>
                </UnauthenticatedTemplate>
            </div>
    );
}
export default Home;