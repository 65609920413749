import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import { useRouteError } from "react-router-dom";

export const ErrorBackend = () => {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <>
        <Alert variant='danger'><FontAwesomeIcon icon={faCircleExclamation} className="error-icon" /> Oops! There was an error. Cannot contact the backend</Alert>    
        <small>technicall error: {error?.toString()}</small>
    </>;
    
}