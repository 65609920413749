import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";
import { callApiBackend } from "../fetch";
import Home from "./Home";
import { DirectoryBreadcrumbs } from "../components/DirectoryBreadcrumbs";
import { handleLogin as handleLoginUtils } from "../utils";
import { AdminContext } from "../types";


export default function Layout() {
    const isAuthenticated = useIsAuthenticated();  
    const [canAdmin, setcanAdmin] = useState<boolean>(false);  
    const [canSelf, setcanSelf] = useState<boolean>(false);  

    const msalContext = useMsal();

    const handleLogout = () => {
      msalContext.instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
      }
    const  handleLogin = useCallback(() => {
      handleLoginUtils(msalContext);
    }, [msalContext]);
    
    const loadData = useCallback(() => {
      callApiBackend<boolean>(msalContext, "Me/CanAdmin")
          .then(async(response) => {
              if (response)
                setcanAdmin(response);         
          })
          .catch(error => {
              console.log(error);
          } )
          .finally(() =>{
          })
      callApiBackend<boolean>(msalContext, "Me/CanSelfService")
          .then(async(response) => {
              if (response)
                setcanSelf(response);         
          })
          .catch(error => {
              console.log(error);
          } )
          .finally(() =>{
          })          
    }, [msalContext]);

    useEffect(() => {
      if ( isAuthenticated )
        loadData();
    },[msalContext.instance, msalContext.accounts, isAuthenticated, loadData])

    return (
        <>
        <div className="main-bg position-absolute top-0 left-0 w-100 h-100">
          <div className="container-fluid m-0 p-0 h-100"></div>
        </div>
        <AdminContext.Provider value={canAdmin}>
          <header id="navheader">
            <div className="container-fluid m-0 p-0 d-flex flex-row">
              <div className="region region-header">
                <div id="block-dp-branding" className="block block-system block-system-branding-block">
                  <div className="navbar-brand d-flex_ align-items-center">
                    <NavLink to={`/`} title="Home" className="site-logo d-block"><img src="/logo.png" alt="Home" /></NavLink>
                    <div></div>
                  </div>
                </div>
              </div>
              <nav className="w-100 navbar navbar-expand-lg">
                <div className="container-fluid m-0 p-0 d-flex flex-column align-items-start">
                  {/* la partie haute du bandeau */}
                  <div className="region region-nav-branding d-flex flex-row justify-content-end align-items-center w-100">
                    <div id="block-dp-branding-sitename" className="block block-system block-system-branding-block">
                      <div className="navbar-brand d-flex_ align-items-center">
                        <NavLink to={`/`} title="Home" className="site-logo d-block"><img src="/logo.png" alt="Home" /></NavLink>
                        <div>
                          <NavLink to={`/`} title="Home"  className="site-title link-primary">FLASH portal</NavLink>
                        </div>
                      </div>
                    </div>
                    <Navbar.Collapse id="block-dpmyaccountmenulink"  className="justify-content-end">
                      <Nav>
                        <NavDropdown title={msalContext.accounts[0] == null ? 'not connected' : <> My Account<br/><span className="info">{msalContext.accounts[0]?.username}</span> </>} 
                          className="account nav-link text-end account">
                          { msalContext.accounts[0] != null &&
                          <>
                          <NavDropdown.Item href="#" onClick={() => handleLogout()}>Sign out</NavDropdown.Item>
                          <NavDropdown.Item href="#" onClick={() => handleLogin()}>Sign in new user</NavDropdown.Item>
                          </>
                          }
                          { msalContext.accounts[0] == null &&
                          <NavDropdown.Item href="#" onClick={() => handleLogin()}>Sign in</NavDropdown.Item>
                          }

                        </NavDropdown>
                      </Nav>
                    </Navbar.Collapse>                  
                    <nav aria-labelledby="block-dp-account-menu-menu" id="block-dp-account-menu" className="block block-menu navigation menu--account">
                      <ul className="nav navbar-nav">
                        <li className="nav-item">
                          { msalContext.accounts[0] != null && /* eslint-disable-next-line */
                          <a href="#" onClick={() => handleLogout()} className="nav-link" >Log out</a>
                          }
                          { msalContext.accounts[0] == null && /* eslint-disable-next-line */
                          <a href="#" onClick={() => handleLogin()} className="nav-link" >Log in</a>
                          }
                        </li>
                      </ul>
                    </nav>
                  </div>     
                  { /* la seconde ligne de bandeau*/}
                  <div className="collapse navbar-collapse justify-content-md-end flex-wrap show" id="navbarSupportedContent">
                    <div className="region region-nav-main">
                      <div id="block-mainnavigation" className="block block-superfish block-superfishmain">
                        <ul id="superfish-main" className="menu sf-menu sf-main sf-horizontal sf-style-none sf-js-enabled">
                          <li id="main-standardfront-page" className="sf-depth-1 sf-no-children">
                            <NavLink to={`/`} className="sf-depth-1 menuparent sf-with-ul">Home</NavLink>
                          </li>
                          {
                            !canSelf && 
                              <li  className="sf-depth-1 menuparent">
                              <NavLink to={`/notanadminaccount`}  aria-disabled="true" className="disabled disabledNavlink" title="Use the acount account (AJ/AL)" >Self Service</NavLink>
                              </li>                        
                          }
                          {
                            canSelf && 
                              <li  className="sf-depth-1 menuparent">
                              <NavLink to={`self`} className="sf-depth-1 menuparent sf-with-ul" >Self Service</NavLink>
                              </li>
                          }                           
                          {
                            canAdmin && 
                              <li  className="sf-depth-1 menuparent">
                              <NavLink to={`admin`} className="sf-depth-1 menuparent sf-with-ul">Admin</NavLink>
                              </li>
                            }                        
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </header>

          <main role="main" >
              <Container fluid className="main-flex">
                <AuthenticatedTemplate>
                  <DirectoryBreadcrumbs />
                  <Outlet />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <DirectoryBreadcrumbs />                
                  <Home/>
                </UnauthenticatedTemplate>          
              </Container>
          </main> 
        </AdminContext.Provider>

        </>
      )
    }