import React, { createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { ContentItem, IDirectoryContent} from '../types'
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap'
import { useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import { myfileSize } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useQueryClient } from 'react-query'
import { Mutex } from 'async-mutex'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

export interface FileFolderItemProps {
  file: ContentItem,
  basePath: string,
  cloudStorageId: string,  
  setFinished:  (success: boolean) => void
}

interface FileFolderItemRef {
  startDelete: (signal?: AbortSignal ) => void
}

const FileItem = forwardRef( ({file, setFinished, basePath, cloudStorageId} : FileFolderItemProps, ref: React.ForwardedRef<FileFolderItemRef> ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [progress, setProgress] = React.useState(0)  ;
  const [error, setError] = React.useState("") ;
  const tokenProvider = folderContent.tokenProvider;
  const appInsights = useAppInsightsContext();

  useImperativeHandle(ref, () => ({
    startDelete : (signal?: AbortSignal) => {
      const fetchData = async () => {
        const path = file.path.split('/').slice(0,-1).join('/');
        await tokenProvider.delete(path,file,{abortSignal: signal });
        appInsights.trackEvent({name: "DeleteFilePopup", properties: {cloudStorageId: cloudStorageId,  folder: path, file: file.name}});
        setProgress(100); 
        setFinished(true);
      }
      fetchData().catch((error:any) => {
        console.log(error); 
        setError(error?.toString());
        setFinished(false);
      });
    },
  }));
  

  return (
      <Row key={file.path}>
        <Col xs={6} className='text-truncate' title={file.name}>{file.path.replace(basePath, "")}</Col>
        <Col xs={2} className='text-right' >{file.isFile ? myfileSize(file.size) : "" }</Col>
        <Col><ProgressBar now={  progress } /><span>{error}</span></Col>
      </Row>
  );

});


const FolderItem = forwardRef( ({file, setFinished, basePath, cloudStorageId} : FileFolderItemProps, ref: React.ForwardedRef<FileFolderItemRef> ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [progress, setProgress] = React.useState(0)  ;
  const [error, setError] = React.useState("") ;
  const tokenProvider = folderContent.tokenProvider;
  const appInsights = useAppInsightsContext();

  useImperativeHandle(ref, () => ({
    startDelete : (signal?: AbortSignal) => {
      const fetchData = async () => {
        const path = file.path.split('/').slice(0,-2).join('/');        
        await tokenProvider.delete(path,file,{abortSignal: signal });
        appInsights.trackEvent({name: "DeleteFolderPopup", properties: {cloudStorageId: cloudStorageId,  folder: path, file: file.name}});

        setProgress(100); 
        setFinished(true);
      }
      fetchData().catch((error:any) => {
        console.log(error); 
        setError(error?.toString());
        setFinished(false);
      });
    },
  }));


  return (
      <Row key={file.path}>
        <Col xs={6} className='text-truncate' title={file.name}>{file.path.replace(basePath, "")}</Col>
        <Col xs={2} className='text-right' >{file.isFile ? myfileSize(file.size) : "" }</Col>
        <Col><ProgressBar now={  progress } /><span>{error}</span></Col>
      </Row>
  );

});

export interface ItemToExpandProps {
  folder: ContentItem,
  basePath: string,  
  cloudStorageId: string,
  setFinished:  (folder:ContentItem, elements: ContentItem[]) => void 
}

interface ItemToExpandRef {
}

const ItemToExpand = forwardRef( ({folder, setFinished, basePath, cloudStorageId} : ItemToExpandProps, ref: React.ForwardedRef<ItemToExpandRef> ) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const [error, setError] = React.useState("") ;
  const tokenProvider = folderContent.tokenProvider;
  const queryClient = useQueryClient();

  useEffect(() => {
    const getFromCacheOrFetch = async () => {
      const queryKey = ["FileListDownload", cloudStorageId, folder.path];
      const staleTime = 1 * 60 * 1000; // 1 minute
      const content = await (queryClient.getQueryData<IDirectoryContent>(queryKey) ?? 
        queryClient.fetchQuery(queryKey,  () => {
            return tokenProvider.get(folder.path)
          }
          ,{staleTime:staleTime}));
      return content;
    }

    if (folder ) {
      getFromCacheOrFetch()
        .then((content) => {
          setFinished(folder, content.elements);
        })
        .catch((error:any) => {
          console.log(error); 
          setError(error?.toString());
        });
    }
  }, [folder, setFinished, tokenProvider, queryClient, cloudStorageId]);

  return (
      <Row key={folder.name}>
        <Col xs={6} className='text-truncate' title={folder.name}>{folder.path.replace(basePath,"")}</Col>
        <Col xs={2} className='text-right' ></Col>
        <Col><ProgressBar now={  0 } /><span>{error}</span></Col>
      </Row>
  );

});


export interface FileListDeleteProps {
  files: ContentItem[],
  basePath: string;
  show: boolean,
  cloudStorageId: string,
  close:  () => void;
}

export const FileListDelete = 
  (props: FileListDeleteProps) => {
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [allDownloaded, setAllDownloaded] = useState<boolean>(false);
    const [downloadedStatus, setDownloadedStatus] = useState<boolean>(true);  
    const [files, setFiles] = React.useState<ContentItem[]>([])
    const [filesRefs, setFilesRefs] = React.useState<React.RefObject<FileFolderItemRef>[]>([])
    const [folders, setFolders] = React.useState<ContentItem[]>([])
    const [foldersRefs, setFoldersRefs] = React.useState<React.RefObject<FileFolderItemRef>[]>([])
    const [foldersToExpand, setFoldersToExpand] = React.useState<ContentItem[]>([])
    const [foldersToExpandRefs, setFoldersToExpandRefs] = React.useState<React.RefObject<ItemToExpandRef>[]>([])

    const [abortController, setAbortController] = useState< AbortController | undefined>(undefined);    
    const [listFileComplete, setListFileComplete] = useState<boolean>(false);

    useEffect(() => {
      if (props.show) {
        const files = props.files.filter((v)=>v.isFile);
        const folders = props.files.filter((v)=>!v.isFile);
        setFilesRefs(files.map(() => createRef<FileFolderItemRef>()));
        setFoldersRefs(folders.map(() => createRef<FileFolderItemRef>()));
        setFoldersToExpandRefs(folders.map(() => createRef<ItemToExpandRef>()));
        const hasFolder = folders.length > 0;
        setListFileComplete( !hasFolder);
        setFoldersToExpand(folders);
        setFiles(files);
        setFolders(folders);
      }
    }, [props.show, props.files]);    

    
    function isFileFolderItemRef(e: FileFolderItemRef | ItemToExpandRef): e is FileFolderItemRef {
      return (e as FileFolderItemRef).startDelete !== undefined;
    }

    const handleClose = () => {
      setInProgress(false);    
      setAllDownloaded(false);
      setDownloadedStatus(true);
      setAbortController(undefined);
      props.close();
    }

    const handleCancel = () => {
      if (abortController) {
        abortController.abort();
        setInProgress(false);    
        setAllDownloaded(true);
        setDownloadedStatus(false);
      } else {
        window.location.reload();
      }
    }; 

    const [, setNbFilessToDelete] = useState<number>(0);    
    const [, setNbFoldersToDelete] = useState<number>(0);   
    const deleteStart = async (event: any) => {
      event?.persist();
      event?.preventDefault();
      setInProgress(true);      
      const myAbortController = new AbortController();
      setAbortController(myAbortController);
      setDownloadedStatus(true);
      // first delete files
      if (filesRefs.length > 0) {
        setNbFilessToDelete(filesRefs.length);      
        filesRefs.forEach(ref => {
            if (ref.current && isFileFolderItemRef(ref.current))
              ref.current.startDelete(myAbortController.signal);
          });
      } else {
        deleteStartFolders();
      }
    }    

    const mutex = new Mutex();
    const setFinished = (success: boolean) => {
      mutex.runExclusive(() => {
        setNbFilessToDelete( (old) => {
          if ( old <= 1){
            if ( folders.length > 0){
              deleteStartFolders();
            } else {
              setInProgress(false);
              setAllDownloaded(true);
              setAbortController(undefined);
            }
          }
          return old - 1;
        });
        setDownloadedStatus( (old) => old && success);
      });
    }

    const deleteStartFolders = async () => {
      // folders cannot be done in parralel because of the path
      // trick : folders should be  sorted in reverse order of deletion (we can parallelize but to complex)
      setNbFoldersToDelete(foldersRefs.length);
      if (folders.length > 0) 
        foldersRefs[foldersRefs.length-1].current?.startDelete(abortController?.signal);
    }


    const setFinishedFolder = (success: boolean) => {
      mutex.runExclusive(() => {
        setNbFoldersToDelete( (old) => {
          const newNb = old - 1;
          if ( newNb <= 0){
            setInProgress(false);
            setAllDownloaded(true);
            setAbortController(undefined);
          }
          else {
            foldersRefs[newNb-1].current?.startDelete(abortController?.signal);
          } 
          return newNb;
        });
        setDownloadedStatus( (old) => old && success);
      });
    }

    const setListFolderFinished = (folder:ContentItem, elements: ContentItem[]) => {
      mutex.runExclusive(() => {
        const index = foldersToExpand.findIndex((v)=>v.path === folder.path);
        if (index>=0)
        {
          const newfoldersToExpand = foldersToExpand.slice();
          newfoldersToExpand.splice(index,1);
          newfoldersToExpand.push(...elements.filter((v)=>!v.isFile));
          const newfoldersToExpandRefs = foldersToExpandRefs.slice();
          newfoldersToExpandRefs.splice(index,1);
          newfoldersToExpandRefs.push(...elements.filter((v)=>!v.isFile).map(() => createRef<ItemToExpandRef>()));
          setFoldersToExpandRefs(newfoldersToExpandRefs);
          setFoldersToExpand(newfoldersToExpand); 

          const elementsFiles = elements.filter((v)=>v.isFile);
          const elementsFolders = elements.filter((v)=>!v.isFile);

          const newFiles = files.slice();
          newFiles.push(...elementsFiles);
          const newFilesRefs = filesRefs.slice();
          newFilesRefs.push(...elementsFiles.map(() => createRef<FileFolderItemRef>()));
          setFilesRefs(newFilesRefs);
          setFiles(newFiles);

          const newFolders = folders.slice();
          newFolders.push(...elementsFolders);
          const newFoldersRefs = foldersRefs.slice();
          newFoldersRefs.push(...elementsFolders.map(() => createRef<FileFolderItemRef>()));
          setFoldersRefs(newFoldersRefs);
          setFolders(newFolders);

          setListFileComplete( newfoldersToExpand.length === 0);
        }
      });
    }

 
    return (
      <Modal show={props.show} onHide={handleClose} size="lg" backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Delete files</Modal.Title>
          </Modal.Header>
          <Modal.Body >      
            <Container fluid className='mt-4'>
              {files.map((file: ContentItem, index:number ) => {
                  return (
                    <FileItem ref={filesRefs[index]} key={file.path} file={file} setFinished={setFinished} basePath={props.basePath} cloudStorageId={props.cloudStorageId} />
                  );
                })
              }
              {folders.map((file: ContentItem, index:number ) => {
                  return (
                    <FolderItem ref={foldersRefs[index]} key={file.path} file={file} setFinished={setFinishedFolder} basePath={props.basePath} cloudStorageId={props.cloudStorageId}  />
                  );
                })
              }              
              {
                foldersToExpand.map((file: ContentItem, index:number ) => {
                  return (
                    <ItemToExpand ref={foldersToExpandRefs[index]} key={file.path} folder={file} setFinished={setListFolderFinished} basePath={props.basePath} cloudStorageId={props.cloudStorageId}  />
                  );
                })
              }
              {
                files.length === 0 && folders.length === 0 &&
                <span>no file to delete</span>
              }
            </Container>
          </Modal.Body>
          <Modal.Footer>
            {
              allDownloaded===false &&
              <span className='me-5'>To delete: {files.length} file(s) {folders.length > 0 ? "and " + folders.length + " folder(s)": ""}
              {!listFileComplete && " (get the list of files in progress)"}
              </span>
            }
            {
              allDownloaded===true && downloadedStatus===true &&
              <span className='me-5 text-success'>All files{folders.length>0?" and folders":"" } deleted</span>
            }
            {
              allDownloaded===true && downloadedStatus===false &&
              <span className='me-5 text-danger'>At least one error</span>
            }
            <Button variant="secondary" onClick={handleClose}>
              <FontAwesomeIcon icon={faXmark} /> Close
            </Button>
            <Button variant="secondary" disabled={!inProgress} onClick={handleCancel}>
              <FontAwesomeIcon icon={faBan} /> Cancel
            </Button>
            <Button variant="primary" disabled={inProgress || files.length+folders.length===0} onClick={deleteStart}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
          </Modal.Footer>   
      </Modal>           
      );
  }
