import { QueryClient } from "react-query";
import { BaseFolderAzureDataLakeTE, BaseFolderAwsDataLakeTE, ILoginContext } from "../types";
import { Link, LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom";
import { callApiGetBaseFolder } from "../fetch";
import { Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { FileIconType, IFileTypeIconOptions, getFileTypeIconProps } from "@fluentui/react-file-type-icons";

interface DatalakeInfo {
    prod?: BaseFolderAzureDataLakeTE | BaseFolderAwsDataLakeTE
    pprod?: BaseFolderAzureDataLakeTE | BaseFolderAwsDataLakeTE
}

export const GetDatalakeInfos = (context: ILoginContext, queryClient: QueryClient) => 
    async ({ params, request }: LoaderFunctionArgs) =>  {
        // router-dom double decode the url. But we need to keep the original url to be able to call the api        
        if (params.cloudStorageId){
            let apiReturn: DatalakeInfo = {};
            const cloudStorageIdProd =  params.cloudStorageId.replace("_pprod","_prod");
            if ( cloudStorageIdProd.endsWith("_prod") ){
                try {
                    apiReturn.prod = await callApiGetBaseFolder(queryClient,cloudStorageIdProd, context.msalContext);
                } catch (error) {
                }
            }
            const cloudStorageIdPprod =  params.cloudStorageId.replace("_prod","_pprod");
            if ( cloudStorageIdPprod.endsWith("_pprod") ){
                try {
                    apiReturn.pprod = await callApiGetBaseFolder(queryClient,cloudStorageIdPprod, context.msalContext);
                } catch (error) {
                }
            }
            return apiReturn;
        }    
        return null;
    }


export const DataLakeHome = () => {
    const datalakeInfo = useLoaderData() as DatalakeInfo;
    const folderIcon : IFileTypeIconOptions = {
          size: 16,
          imageFileType: 'svg',
          type: FileIconType.folder
        };
    const navigate = useNavigate();
    const datalakes = [
        {
            displayName: "Prod", 
            dl: datalakeInfo?.prod,
            sources: datalakeInfo?.prod?.sourceDL.reduce((acc, item) => {
                if (acc.indexOf(item.source) === -1)
                    return [...acc, item.source];
                return acc;
            }, [] as string[]),
            domain: datalakeInfo?.prod?.domainDL.reduce((acc, item) => {
                if (acc.indexOf(item.domain) === -1)
                    return [...acc, item.domain];
                return acc;
            }, [] as string[]),
        }, 
        {
            displayName: "PreProd", 
            dl: datalakeInfo?.pprod,
            sources: datalakeInfo?.pprod?.sourceDL.reduce((acc, item) => {
                if (acc.indexOf(item.source) === -1)
                    return [...acc, item.source];
                return acc;
            }, [] as string[]),
            domain: datalakeInfo?.pprod?.domainDL.reduce((acc, item) => {
                if (acc.indexOf(item.domain) === -1)
                    return [...acc, item.domain];
                return acc;
            }, [] as string[]),

        }
    ];
    return (
        <>
            <h3>Data Lake for {datalakeInfo.prod ? datalakeInfo.prod.displayName : datalakeInfo.pprod?.displayName }</h3>
            <Tabs className="mt-3" >
                { datalakes.map( (item) => {
                    if (item.dl){
                        return (
                        <Tab eventKey={item.displayName} title={item.displayName} key={item.displayName}>
                            <Row className="mt-3 datalakepage">
                                <Col>
                                    <Card className="w-75">
                                        <Card.Body>
                                            <Card.Title>App</Card.Title>
                                            <Card.Text as="div">
                                                <Table hover  id="tableFolders" size="sm" className='mt-2 table-fit'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Icon {...getFileTypeIconProps(folderIcon)} className="fileicon" aria-label="folder type icon" />
                                                            </td>
                                                            <td>
                                                                <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate("/dl/" + item.dl?.cloudStorageId + "/apps/common/" )}}  to="apps/common">common</Link></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Icon {...getFileTypeIconProps(folderIcon)} className="fileicon" aria-label="folder type icon" />
                                                            </td>
                                                            <td>
                                                                <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate("/dl/" + item.dl?.cloudStorageId + "/apps/exec/" )}}  to="apps/exec">exec</Link></button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Icon {...getFileTypeIconProps(folderIcon)} className="fileicon" aria-label="folder type icon" />
                                                            </td>
                                                            <td>
                                                                <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate("/dl/" + item.dl?.cloudStorageId + "/apps/private/" )}}  to="apps/private">private</Link></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="w-75">

                                        <Card.Body>
                                            <Card.Title>Sources</Card.Title>
                                            <Card.Text as="div">
                                                { (item.sources && item.sources.length > 0) && 
                                                <Table hover  id="tableFolders" size="sm" className='mt-2 table-fit'>
                                                    <tbody>
                                                        { item.sources.map( (source) => 
                                                            <tr key={source}>
                                                                <td>
                                                                    <Icon {...getFileTypeIconProps(folderIcon)} className="fileicon" aria-label="folder type icon" />
                                                                </td>
                                                                <td>
                                                                    <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate("/dl/" + item.dl?.cloudStorageId + "/source/" + source + "/" )}}  to={"source/" + source}>{source}</Link></button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                }
                                                { (!item.sources || item.sources.length === 0) && 
                                                <>No source for this app</>
                                                }
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="w-75">
                                        <Card.Body>
                                            <Card.Title>Domains</Card.Title>
                                            <Card.Text as="div">
                                                { (item.domain && item.domain.length > 0) && 
                                                <Table hover  id="tableFolders" size="sm" className='mt-2 table-fit'>
                                                    <tbody>
                                                        { item.domain.map( (domain) => 
                                                            <tr key={domain}>
                                                                <td>
                                                                    <Icon {...getFileTypeIconProps(folderIcon)} className="fileicon" aria-label="folder type icon" />
                                                                </td>
                                                                <td>
                                                                    <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate("/dl/" + item.dl?.cloudStorageId + "/domain/" + domain + "/" )}}  to={"domain/" + domain}>{domain}</Link></button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                                }
                                                { (!item.domain || item.domain.length === 0) && 
                                                <>No domain for this app</>
                                                }
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>                            
                                </Col>
                            </Row>
                        </Tab> 
                        )                       
                    } else 
                        return <></>
                }
                )}

            </Tabs>
        </>
    );
}