import {
    DatatableWrapper,
    Filter,
    TableBody,
    TableColumnType,
    TableHeader,
  } from 'react-bs-datatable';
  import { Col, Row, Table, Button, Spinner, Modal} from 'react-bootstrap';

import { useMsal} from "@azure/msal-react";
import { FunctionComponent, useState } from "react";
import { callApiBackend } from "../fetch";
import { Icon } from '@fluentui/react';
import { LoaderFunctionArgs, useLoaderData, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { CloudStorage, ILoginContext } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRotate } from '@fortawesome/free-solid-svg-icons';

interface ContextAdminList extends CloudStorage {
    button: any;
}


export const GetCloudStorages = (context: ILoginContext, admin: boolean) => 
  async ({ request, params }: LoaderFunctionArgs) =>  {
    if (context.isAuthenticated){
        const result = await callApiBackend<CloudStorage[]>(context.msalContext, "CloudStorage");
        if ( admin){
            return result?.filter(v=> v.kind === "GlobalAdmin");
        } else {
            return result?.filter(v=> v.kind !== "GlobalAdmin");
        }
    }
    return null;
};
export interface AdminListProps {
    admin:boolean
}


const AdminList : FunctionComponent<AdminListProps> = ( {admin}) => {
    const defaultItem: CloudStorage = { 
        cloudStorageId: '',
        kind:'AzureFile',
        maxDurationMinutes: 10,
        basePath:'',
        technicalInfo:'',
        technicalInfoDomain: '',
        technicalInfoSource: '',
        securityModel: "ContainerFileShareLevel",
        displayName:'',
        logInfo: undefined,
        generateTokenPerFile: undefined,
        cmdbBusinessService : '',
        cmdbApplicationService : '',
        visibility: undefined,
        confidentiality: undefined,
        environment: undefined,
        arnRole:'',
        branch: undefined,
        landingZoneName: '',
        sourceDL: undefined,
        domainDL: undefined
    };    
    const msalContext = useMsal();
    const [item, setItem] = useState<CloudStorage>(defaultItem);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState(false);    
    const location = useLocation();
    const navigate = useNavigate(); 
    const items = useLoaderData() as ContextAdminList[];

    const navigation = useNavigation();

    const launchDelete = (context: ContextAdminList) => {
        setItem(context);
        setShowDelete(true);
    }    
    const deleteItem = () =>{
        setLoading(true);
        callApiBackend<void>(msalContext, "CloudStorage/" + item.cloudStorageId, 'DELETE', { doNotCheckResult : true } )
            .then(async(response) => {
            })
            .catch(error => {
                console.log(error);
                if (error.message)
                    alert(error.message);
                else
                    alert('unexpected error');
            } )
            .finally(() =>{
                setLoading(false);
                setShowDelete(false);
                navigate( location.pathname);
            })
    }
    const refresh = (event: any) => {
        event?.persist();
        event?.preventDefault();
        navigate( location.pathname);
      }
    const headers : TableColumnType<ContextAdminList>[] = [
        { title: 'Display Name', prop: 'displayName', isFilterable: true, isSortable:true },
        { title: 'StorageAccount', prop: 'technicalInfo', isFilterable: true, isSortable:true,
            cell: (row)=>(
                <>
                { (row.kind === "AzureBlob" || row.kind === "AzureDataLake" || row.kind === "AzureFile" || row.kind === "AzureDataLakeTE") &&
                <a target="_blank" rel="noreferrer" className='link-dark' title="click to open Azure portal on a new tab" href={'https://portal.azure.com/#@totalworkplace.onmicrosoft.com/resource' + row.technicalInfo + '/overview'}>{row.technicalInfo.split('/').splice(-1)[0]}</a>
                }
                { (row.kind === "AwsS3" ) &&
                    <a target="_blank" rel="noreferrer"  className='link-dark'  title="click to open AWS portal on a new tab" href={'https://s3.console.aws.amazon.com/s3/buckets/' + row.technicalInfo}>{row.technicalInfo.split('/').splice(-1)[0]}</a>
                }
                {
                    (row.kind === "AwsDataLakeTE") &&
                    <a target="_blank" rel="noreferrer"  className='link-dark'  title="click to open AWS portal on a new tab" href={'https://s3.console.aws.amazon.com/s3/buckets/' + row.technicalInfo + '/'}>{row.technicalInfo}</a>
                }
                </>
            )
        },
        { title: 'kind', prop: 'kind', isFilterable: true, isSortable:true, 
            cell: (row)=>(
                <>
                    {row.kind}{row.environment ? " / " + row.environment : ""}
                </>
            )
         },
        { title: '', prop: 'button', isFilterable: false, isSortable:false,
            cell: (row)=>(
                <>
                    {row.kind !== "GlobalAdmin" &&
                        <>
                        <Button variant="outline-primary" size="sm" onClick={()=>navigate(row.cloudStorageId)}><Icon iconName='Edit'/> Edit</Button>&nbsp;
                        </>
                    }
                    <Button variant="outline-primary" size="sm"  onClick={()=>navigate(row.cloudStorageId+ "/permissions")}><Icon iconName='Permissions'/> Permissions</Button>&nbsp;
                    {row.kind !== "GlobalAdmin" &&
                        <Button variant="outline-primary" size="sm"  onClick={()=>launchDelete(row)}><Icon iconName='Delete'/> Delete</Button>
                    }
                </>
            )
        },

    ];
    const headersAdmin : TableColumnType<ContextAdminList>[] = [
      { title: 'Role', prop: 'cloudStorageId', isFilterable: true, isSortable:true },
      { title: 'Display Name', prop: 'displayName', isFilterable: true, isSortable:true },
      { title: '', prop: 'button', isFilterable: false, isSortable:false,
          cell: (row)=>(
              <>
                  <Button variant="outline-primary" onClick={()=>navigate(row.cloudStorageId+ "/permissions")}><Icon iconName='Permissions'/> Permissions</Button>&nbsp;
              </>
          )
      },

    ];

    return (
        <>

        {
            !admin &&
            <h2>Cloud Storages</h2> 
        }
        {
            admin &&
            <h2>Global Permissions</h2> 
        }
        {items &&
        <DatatableWrapper body={items} headers={admin? headersAdmin : headers}  paginationOptionsProps={{
            initialState:{
                rowsPerPage:10000,
                options: [5, 10, 15, 20,10000]
            }
        }}>
            <Row className="mb-4">
                <Col
                    xs={12}
                    lg={9}
                    className="d-flex flex-col justify-content-begin align-items-end navigationtoolbar"
                    >
                    <Button variant='primary' onClick={(e)=>refresh(e)} disabled={navigation.state === "loading"} title="refresh the list" aria-label='refresh'><FontAwesomeIcon icon={faRotate} /> {navigation.state === "loading" ? "Refreshing" : "Refresh"}</Button>
                    {
                        !admin &&
                        <Button variant='primary' onClick={(e) => navigate("new")}  title="New Cloud Storage" className={navigation.state === "loading" ? "opaque" : ""}><FontAwesomeIcon icon={faPlus} /> New Cloud Storage</Button>
                    }
                    {navigation.state === "loading" &&     
                        <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>     
                    }                       
                </Col>
                <Col
                xs={12}
                lg={3}
                className="d-flex flex-col justify-content-end align-items-end"
                >
                    <Filter placeholder='search' classes={{inputGroup:"flashsearch", clearButton:""}}/>
                </Col>
            </Row>
            <Table id="adminTable" className={navigation.state === "loading" ? "opaque" : ""}>
                <TableHeader />
                { 
                    isLoading ?   
                    (  
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )
                    : (
                        <TableBody  />
                    )
                }
            </Table>
        </DatatableWrapper>
        }
        {!items &&     
            <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                <span className="visually-hidden">Loading...</span>
            </Spinner>     
        }
        <Modal show={showDelete} onHide={()=>setShowDelete(false)}>
            <Modal.Header >
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure to delete {item.displayName} ({item.cloudStorageId}) ? </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setShowDelete(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={deleteItem}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        </>        
    );

}
export default AdminList;
