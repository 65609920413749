import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
        // Total
        clientId: String(process.env.REACT_APP_CLIENTID),
        authority: String(process.env.REACT_APP_AUTHORITY), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // exakis
        //clientId: "c81b210b-51e9-44c3-9b50-d758bbfbb8eb",
        //authority: "https://login.microsoftonline.com/4110c80b-9ab4-4cad-ad3b-f94c226a10e8", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored 
      // local : share between tabs
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    // case LogLevel.Verbose:
                    //     console.debug(message);
                    //     return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }    
  };

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
 export const protectedResources = {
    apiFLASH: {
        endpoint: String(process.env.REACT_APP_API_URL),
        //Total
        scopes: [String(process.env.REACT_APP_API_SCOPE1),String(process.env.REACT_APP_API_SCOPE2)],
        //JPC
        //scopes: ["api://82e7fa27-ccb9-4823-bddb-f525b50c3e63/SASToken.Generate","api://82e7fa27-ccb9-4823-bddb-f525b50c3e63/Permission.ReadWrite"],
        Apimkey:   String(process.env.REACT_APP_API_KEY)
    },
    apiAwsFLASH: {
        //Total
        scopes: [String(process.env.REACT_APP_API_AWS_SCOPE1)],
    },    
    azurestorage: {
        scopes: ["https://storage.azure.com/user_impersonation"]
    }

}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.apiFLASH.scopes, protectedResources.apiAwsFLASH.scopes]
};  

export const graphAPIScopes = ["User.Read", "User.ReadBasic.All"]
  
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

