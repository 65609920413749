import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, IDirectoryContent} from '../types';
import { Button, Col, Form, InputGroup, Modal, Overlay, Popover, ProgressBar, Row, Spinner, Table } from 'react-bootstrap';
import { encodePath, getFileExtension, myfileSize } from '../utils';
import { TokenGenerator } from './TokenGenerator';
import { Link, createSearchParams, useFetcher, useLoaderData, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,  faKey, faPlus, faRotate, faTrash, faUpload, faXmark, faFilePen, faFolderTree } from '@fortawesome/free-solid-svg-icons';
import { faFile as faFileRegular, faSquareCaretDown as faDownloadRegular, faTrashCan as faTrashRegular, faFolderOpen as faFolderOpenRegular, faFolder as faFolderRegular } from '@fortawesome/free-regular-svg-icons';
import { FileListUpload } from './FileListUpload';
import { FileListDownload } from './FileListDownload';
import { Icon } from '@fluentui/react';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { useMap } from '../useMap';
import { FileListDelete } from './FileListDelete';
import TreeView, { INode, ITreeViewOnLoadDataProps } from 'react-accessible-treeview';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { FileIconType, getFileTypeIconProps, IFileTypeIconOptions } from '@fluentui/react-file-type-icons';


type DirectoryBrowserProps = {
  cloudStorageId: string;
  realpath: string;
}

const formatDate = (date: Date): string => {
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
}

type sortProps = {
  isDescending: boolean,
  column : string

}


export const DirectoryBrowser = ({ cloudStorageId, realpath }: DirectoryBrowserProps) => {
  const folderContent = useLoaderData() as IDirectoryContent;
  const location = useLocation();
  const fetcher = useFetcher();

  const navigate = useNavigate();
  const navigation = useNavigation();
  const appInsights = useAppInsightsContext();
  const [showTokenGenerator, setShowTokenGenerator] = useState<boolean>(false);
  //search
  const [search, setSearch] = useState<string>("");
  const [searchInMemory, setSearchInMemory] = useState<string>("");
  const [searchByAPI, setSearchByAPI] = useState<string>("");
    

  // navigation dans dossier
  const [contentItems, setContentItems] = useState<ContentItem[]|undefined>(undefined);
  const [contentItemsPageOne, setContentItemsPageOne] = useState<ContentItem[]|undefined>(undefined);
  const [contentItemsPageNext, setContentItemsPageNext] = useState<ContentItem[]|undefined>(undefined);
  const [contentItemsChecked, contentItemsCheckedActions] = useMap<string,ContentItem>();

  // new folder
  const ref = useRef(null);
  const [showFolder, setShowFolder] = useState<boolean>(false);
  const [targetFolder, setTargetFolder] = useState<HTMLElement|null>(null);
  const [folder, setFolder] = useState<string>('');

  const newFolder = (event: any) => {
    event?.persist();
    event?.preventDefault();
    Promise.resolve().then(() => {
      const target = event?.target as HTMLElement | undefined;
      setShowFolder(!showFolder);
      if (target) {
        setTargetFolder(target);
      }
    });
  };
  const onFolderCreate = async (event: any) => {
    event.preventDefault();  
    if (folderContent.tokenProvider){
      try {
        const result = await folderContent.tokenProvider.createFolder(realpath, folder  );  
        appInsights.trackEvent({name: "CreateFolderDirect", properties: {cloudStorageId: cloudStorageId,  folder: realpath, newFolder: folder}});
        if (!result) {
          alert("You must upload a file in the folder to finalize the creation");
        }
        setShowFolder(false);  
        let newLocation =location.pathname;
        if (!newLocation.endsWith('/'))
          newLocation += '/';
        newLocation += folder + "/";
        navigate(newLocation);
      } catch (e){
          alert(e);
      }
    }
  }  

  // rename
  const [showRename, setShowRename] = useState<boolean>(false);
  const [targetRename, setTargetRename] = useState<HTMLElement|null>(null);
  const [rename, setRename] = useState<string>('');

  const newRename = (event: any) => {
    event?.persist();
    event?.preventDefault();
    Promise.resolve().then(() => {
      const target = event?.target as HTMLElement | undefined;
      setRename( contentItemsChecked.values().next().value.name);
      setShowRename(!showFolder);
      if (target) {
        setTargetRename(target);
      }                
    });         
  };  
  const onRename = async (event: any) => {
    event.preventDefault();  
    if (folderContent.tokenProvider){
      try {
        await folderContent.tokenProvider.rename(realpath, contentItemsChecked.values().next().value, rename  );  
        appInsights.trackEvent({name: "RenameDirect", properties: { cloudStorageId: cloudStorageId, file: ( contentItemsChecked.values().next().value as ContentItem).path, newname: rename }});
        setShowRename(false);  
        refresh(null);
      } catch (e){
        console.log(e);
        alert(e);
      }
    }
  }  

  // move
  const [showMove, setShowMove] = useState<boolean>(false);
  const [showMoveLoading, setShowMoveLoading] = useState<boolean>(false);
  const [selectedMove, setSelectedMove] = useState<string>('');
  const [expandedMove, setExpandedMove] = useState<string[]>([]);
  const improbablePathforRoot = "root*1";
  const improbablePathforRoot2 = "root*2";  
  // INode : id is the path of the folder
  const [dataMove, setDataMove] = useState<INode[]>([{
    name: "",
    id: improbablePathforRoot,
    children: [improbablePathforRoot2],
    parent: null,
  },{
    name: "root",
    id: improbablePathforRoot2,
    children: [],
    parent: improbablePathforRoot,  
    isBranch: true,
  }]);
  const updateMoveTreeData = async (elementToExpand:INode ) => {
    let path = elementToExpand.id as string;
    if (path === improbablePathforRoot || path === improbablePathforRoot2){
      path = "";
    }
    let content = await folderContent.tokenProvider.get(path);
    let list:INode[] = [];
    content.elements.forEach( (element) => {
      if (!element.isFile){
        list.push({
          name: element.name,
          id: element.path,
          children: [],
          parent: elementToExpand.id,
          isBranch: true,
        });
        if (!elementToExpand.children.includes(element.path)) {
          elementToExpand.children.push(element.path);
        }
      }
    });
    return list;
  };

  const onMoveLoadData  = async (props: ITreeViewOnLoadDataProps) => {
    if ( ! props.element.metadata?.isLoaded){
      const newdata = await updateMoveTreeData(props.element);
      if ( ! props.element.metadata?.isLoaded){
          setDataMove((oldValues) => {
            props.element.metadata = {isLoaded: true};
            const newValues = oldValues.concat(newdata);
            return newValues;
          });
      }
    }
  };

  const initMove = async (event: any) => {
    event.preventDefault();
    setShowMoveLoading(true);
    setShowMove(true);
    let initial : INode[] = [      {
      name: "",
      id: improbablePathforRoot,
      children: [improbablePathforRoot2],
      parent: null,
      metadata : {isLoaded: true}
    },{
      name: "root",
      id: improbablePathforRoot2,
      children: [],
      parent: improbablePathforRoot,  
      isBranch: true,
    }];
    let initialExpanded = [improbablePathforRoot2];
    initial =  initial.concat(await updateMoveTreeData(initial[1]));
    initial[1].metadata = {isLoaded: true};
    let idxSlash = realpath.indexOf('/');
    while (idxSlash > 0){
      let path = realpath.substring(0,idxSlash+1);
      let element = initial.find((e) => e.id === path);
      if (element){
        initial =  initial.concat(await updateMoveTreeData(element));
        element.metadata = {isLoaded: true};
        initialExpanded.push(element.id as string);
      }
      idxSlash = realpath.indexOf('/',idxSlash+1);
    }
    setDataMove(value => {
      setExpandedMove(initialExpanded);
      setSelectedMove(realpath === "" ? improbablePathforRoot2 : realpath);
      return initial;
    });
    setShowMoveLoading(false);
  }
  const handleCloseMove = () => {
    setShowMove(false);
    setDataMove(value => {
      setSelectedMove("");
      setExpandedMove([improbablePathforRoot2]);
      return [      {
        name: "",
        id: improbablePathforRoot,
        children: [improbablePathforRoot2],
        parent: null,
        metadata : {isLoaded: true}
      },{
        name: "root",
        id: improbablePathforRoot2,
        children: [],
        parent: improbablePathforRoot,  
        isBranch: true,
      }];
    });
    refresh(null);
  }
  const onMove = async (event: any) => {
    event.preventDefault();  
    if (folderContent.tokenProvider){
      try {
        const newFolder = selectedMove  === improbablePathforRoot2 ? "" : selectedMove;
        await folderContent.tokenProvider.move(realpath, contentItemsChecked.values().next().value, newFolder  );  
        appInsights.trackEvent({name: "MoveDirect", properties: { cloudStorageId: cloudStorageId, file: ( contentItemsChecked.values().next().value as ContentItem).path, newname: rename }});
        setShowMove(false);  
        refresh(null);
      } catch (e){
        console.log(e);
        alert(e);
      }
    }
  }    
  


  //refresh 
  const refresh = (event: any) => {
    event?.persist();
    event?.preventDefault();
    contentItemsCheckedActions.reset();
    navigate( location.pathname);
  }

  // upload 
  const [showUpload, setShowUpload] = useState<boolean>(false);  
  const closeUpload =  () => {
    setShowUpload(false);
    refresh(null);    
  }    

  // mass download
  const [showDownload, setShowDownload] = useState<boolean>(false);  
  const closeDownload =  () => {
    setShowDownload(false);
    setFilesDownload([]);    
    refresh(null);    
  }    


  const [filesDownload, setFilesDownload] = useState<ContentItem[]>([])
  const prepareDownload = async (event: any) => {
    event?.persist();
    event?.preventDefault();
    Promise.resolve().then(() => {
      const filesContentItem =  Array.from(contentItemsChecked.values());
      setFilesDownload(filesContentItem);
      setShowDownload(true);
    });
  };  



  // mass and individual delete
  
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const closeDelete = () => {
    setShowDelete(false);
    refresh(null);     
  }  

  const [filesDelete, setFilesDelete] = useState<ContentItem[]>([]);
  const prepareDeleteUnit = async (event: any, item:ContentItem) => {
    event.preventDefault();  
    setFilesDelete([item]);
    setShowDelete(true);
  }  

  const prepareDeleteMass = async (event: any) => {
    event?.persist();
    event?.preventDefault();
    Promise.resolve().then(() => {
      const filesContentItem =  Array.from(contentItemsChecked.values());
      setFilesDelete(filesContentItem);
      setShowDelete(true);
    });
  };  


  const getToken = (event: any) => {
    event?.persist();
    event?.preventDefault();   
    Promise.resolve().then(() => {
      setShowTokenGenerator(!showTokenGenerator);
    });       
  };


  // download unitaire (fichier par fichier)
  const downloadBlobBrowser = (fileName: string, blob: Blob) => {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }
  const [percentComplete, setPercentComplete] = useState(0);  
  const [downloadFileName, setDownloadFileName] = useState("");  
  const [downloadError, setDownloadError] = useState("");  

  const downloadFile= async (event: any, item: ContentItem ) => {
    event?.persist();
    event?.preventDefault();   
    setPercentComplete(0);
    setDownloadFileName(item.name);
    setDownloadError("");
    if (item.isFile) {
      const blob = await folderContent.tokenProvider.download(realpath, item, {onprogress: (progress) => {
        setPercentComplete(progress.loadedBytes/item.size);
      }}).catch((error:any) => {
        console.log(error); 
        setDownloadError(error?.toString());        
      });
      if (blob) {
        downloadBlobBrowser(item.name, blob);
        appInsights.trackEvent({name: "DownloadDirect", properties: { cloudStorageId: cloudStorageId,  file: item.path, size: item.size.toString() }});
        setPercentComplete(0);
        setDownloadFileName("");    
      }
    } else {
      setPercentComplete(0);
      setDownloadFileName("");    
      alert("not supported");
    }
  }

  // sort and search
  const sortNatural = "Natural";
  const [sort, setSort] = useState<sortProps>({column:sortNatural, isDescending: false});

  const columnClick = (event: any, column: string): void => {
    event?.persist();
    event?.preventDefault();       

    let newSort : sortProps;
    if ( column === sort.column)
    {
      newSort =  {column:sort.column, isDescending: !sort.isDescending} ;
    } else {
      // comme l'explorateur de fichier, date et size sont triés par défaut du plus garnd au plus petit et le reste dans l'ordre logique...
      const newDescending=  column === sortNatural || column === "name" ? false : true;
      newSort =   {column:column, isDescending: newDescending};
    }
    setSort(newSort);
  };
  const columnTitle = (column: string): string => {
    const prefix = "sort by " + column + " ";
    if ( column === sort.column)
    {
      return prefix + (sort.isDescending ? "ascending" : "descending");
    } else {
      // comme l'explorateur de fichier, date et size sont triés par défaut du plus garnd au plus petit et le reste dans l'ordre logique...
      const newDescending=  column === sortNatural || column === "name" ? false : true;
      return prefix + (!newDescending ? "ascending" : "descending");
    }
  };

  const sortComment = (): string => {
    switch (sort.column) {
      case "Natural":
        return sort.isDescending ? "sorted files first, then folders" : "sorted folder first, then files by name case sensitive";
      case "name":
        return sort.isDescending ? "sorted by name, case sensitive, reverse order": "sorted by name, case sensitive";
      case "modified":
        return sort.isDescending ? "sorted by modification date, recent first" : "sorted by modification date";
      case "size":
        return sort.isDescending ? "sorted by size, bigger first" : "sorted by size";
    }
    return "";
  };


  // pagination variables
  // last page : always undefined . So if the array end with a undefined, we are at the last page
  const [continuationTokens, setContinuationTokens] = useState<(string | undefined)[]>([]);
  const [continuationTokensSearch, setContinuationTokensSearch] = useState<(string | undefined)[]>([]);
  const [hasPagination, setHasPagination] = useState<boolean>(false);


  // when loading the current page
  useEffect(() => {
    //reset search
    setContinuationTokensSearch([]);
    setSearch("");
    setSearchByAPI("");
    setSearchInMemory("");
    setContentItemsPageNext(undefined);
    // init of screen
    if (!folderContent){
      setContentItemsPageOne(undefined);
      return;
    }
    // API send data
    const newItems  = folderContent.elements;
    if (folderContent.continuationToken ){
      setContinuationTokens([folderContent.continuationToken]);
      setHasPagination(true);
      setSort({column:"name", isDescending: false});
    } else {
      setContinuationTokens([]);
      setHasPagination(false);
      setSort({column:sortNatural, isDescending: false});
    }
    // share new content
    setContentItemsPageOne(newItems);
  }, [folderContent]);

  // when loading data from fetcher (next page, pagination) or search combined with pagination
  useEffect(() => {
    if (fetcher.data) {
      const newItems  = fetcher.data.elements;
      if(!fetcher.data.prefixSearch){
        setContinuationTokens(old => {
          const newToken = fetcher.data.continuationToken;
          if ( !fetcher.data?.prefixSearch && old.includes(newToken)){
            return old;
          }
          return [...old, newToken];
        })
      } else{
        setContinuationTokensSearch(old => {
          const newToken = fetcher.data.continuationToken;
          if ( old.includes(newToken)) return old;
          return [...old, newToken];
        })
      }
      setContentItemsPageNext(newItems);
    }
  }, [fetcher.data]);

  // mixt data, pagination and sort
  useEffect( () => {
    let newItems = contentItemsPageNext ?? contentItemsPageOne;
    if (newItems) {

      if ( sort.column === sortNatural ){
        newItems = _copyAndSortNatural(newItems, sort.isDescending, hasPagination);
      } else if ( sort.column === "name" || (sort.column === sortNatural)) {
        newItems = _copyAndSortName(newItems, sort.isDescending, hasPagination);
      } else {
        newItems = _copyAndSort(newItems, sort.column, sort.isDescending);
      }
      newItems =  searchInMemory ? newItems.filter( i=>i.name.startsWith(searchInMemory) ) : newItems;
    }
    setContentItems(newItems);    
  }, [contentItemsPageNext, contentItemsPageOne, hasPagination, searchInMemory, sort]);


  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const mult = isSortedDescending ? -1 : 1;    
    return items.slice(0).sort((a: T, b: T) => {
        return a[key] > b[key] ? mult : -mult;
    });
  }  

  function _copyAndSortName(items: ContentItem[], isSortedDescending: boolean, hasPagination: boolean): ContentItem[] {
    const mult = isSortedDescending ? -1 : 1;    
    return items.slice(0).sort((a: ContentItem, b: ContentItem) => {
      return a.name > b.name ? mult : -mult
    });
  }

  function _copyAndSortNatural(items: ContentItem[], isSortedDescending: boolean, hasPagination: boolean): ContentItem[] {
    // first folders sorted by name, and then files sorted by name
    const mult = isSortedDescending ? -1 : 1;
    return items.slice(0).sort((a: ContentItem, b: ContentItem) => {
      if (!a.isFile && b.isFile) return -mult ;
      if (a.isFile && !b.isFile) return mult ;
      return a.name > b.name ? mult : -mult
    });
  }
  const showingComment = (): string => {
    if (searchInMemory)
      return "Filtered by " + searchInMemory;
    if (searchByAPI && continuationTokensSearch && continuationTokensSearch.length>0)
    {
      if (continuationTokensSearch.length === 1 && continuationTokensSearch[0] === "")
        return "Filtered by '" + searchByAPI + "', " + fetcher.data.elements.length + " items";
      else if (continuationTokensSearch.length === 1)
        return "Filtered by '" + searchByAPI + "', first " + fetcher.data.pagesize + " items";
      else
        return "Filtered by '" + searchByAPI + "', items from " + (fetcher.data.pagesize * (continuationTokensSearch.length-1) + 1) + " to " + ((fetcher.data.pagesize  * (continuationTokensSearch.length-1)) + ( continuationTokensSearch[continuationTokensSearch.length - 1] ? fetcher.data.pagesize : fetcher.data.elements.length  ));
    }
    if (hasPagination && folderContent.pagesize) {
      if (continuationTokens.length === 1)
        return "Showing the first " + folderContent.pagesize + " items";
      else
        return "Showing the items from " + (folderContent.pagesize * (continuationTokens.length-1) + 1) + " to " + ((folderContent.pagesize * (continuationTokens.length-1)) + ( continuationTokens[continuationTokens.length - 1]===null ? folderContent.pagesize : fetcher.data.elements.length  ));
    }
    return "Showing all " + folderContent?.elements?.length + " items";
  }

  // icons
  const iconForFluentUI = (item:ContentItem):IFileTypeIconOptions => {
    const icon: IFileTypeIconOptions = {
      size: 16,
      imageFileType: 'svg',
    };

    if (item.isFile) {
      icon.extension =  getFileExtension(item.name) ;
    } else {
      icon.type = FileIconType.folder;
    }
    return icon;
  }  

  // checkbox
	const toggleRow = (item:ContentItem ): void => {
    if ( contentItemsChecked.has(item.name)) {
      contentItemsCheckedActions.remove(item.name);
    } else {
      contentItemsCheckedActions.set(item.name,item );
    }
	}  
  const [allChecked, setAllChecked] = useState(false);
  const toggleCheckAll = (): void => {
    const newAllChecked = !allChecked;
    if (newAllChecked) {
      contentItems?.forEach ( function (item) {
        if (!contentItemsChecked.has(item.name)){
          contentItemsCheckedActions.set(item.name,item );
        }
      });
    } else {
      contentItems?.forEach ( function (item) {
        if (contentItemsChecked.has(item.name)){
          contentItemsCheckedActions.remove(item.name);
        }
      });

    }
    setAllChecked(newAllChecked);
  }
  const branchNode = (isExpanded: boolean, element: INode) => {
    return isExpanded && !element.metadata?.isLoaded ? (
      <>
        <span
          role="alert"
          aria-live="assertive"
          className="visually-hidden"
        >
          loading {element.name}
        </span>
        <Spinner animation="border" variant="primary" className="icon" >
        </Spinner>  
      </>
    ) : (
      <FontAwesomeIcon className="icon" color="e8a87c" icon={isExpanded ? faFolderOpenRegular : faFolderRegular} />
    );
  };

  // Pagination event

  const handlePreviousPage = () => {
    const params = createSearchParams({ prefixSearch: searchByAPI }); 
    if ( searchByAPI) {
      if (continuationTokensSearch.length > 2)      
        params.set("continuationToken",continuationTokensSearch[continuationTokensSearch.length - 3] as string);
      setContinuationTokens(continuationTokensSearch.slice(0, -2));
    } else {
      if (continuationTokens.length > 2)
        params.set("continuationToken",continuationTokens[continuationTokens.length - 3] as string);
      setContinuationTokens(continuationTokens.slice(0, -2));

    }
    fetcher.load(location.pathname + "?" + params );
  };

  const handleNextPage = () => {
    const params = createSearchParams({ continuationToken: continuationTokens[continuationTokens.length - 1] as string, prefixSearch: searchByAPI });
    if (searchByAPI)
      params.set("continuationToken", continuationTokensSearch[continuationTokensSearch.length - 1] as string);
    if ( params.get("continuationToken")) 
      fetcher.load(location.pathname + "?" + params );
  };

  const handleSearch2 = async (event: any, value: string) => {
    event?.persist();
    event?.preventDefault();
    setSearch(value);
    if (hasPagination){
      // reset search (search empty or not) memorise the choice and reset the continuation token (user will go to page 1 of the search if set)
      setSearchByAPI(value);
      setContinuationTokensSearch([]);
      if (value){
        // new search
        const params = createSearchParams({ prefixSearch: value });
        fetcher.load(location.pathname + "?" + params );
      } else {
        resetSearch(event);
      }
     } else {
      setSearchInMemory(value);
     }
  };

  const resetSearch = async (event: any) => {
    event?.persist();
    event?.preventDefault();
    setSearch("");
    setSearchInMemory("");
    setSearchByAPI("");
    setContinuationTokensSearch([]);
    if (hasPagination){
        // reset fetcher.data with the last page (not previous not next bu current page with no search)
        const params = createSearchParams({});    
        if (continuationTokens.length > 1)      
          params.set("continuationToken",continuationTokens[continuationTokens.length - 2] as string);
        setContinuationTokens(continuationTokens.slice(0, -1));
        fetcher.load(location.pathname + "?" + params );
    }
  }

  return (
    <>
        <Row className='mt-2 mx-2 navigationtoolbar'>
          <Col xs={8} className='px-0' >
            <Button variant='primary' onClick={(e)=>refresh(e)} disabled={navigation.state === "loading"} title="refresh the content of the folder" aria-label='refresh'><FontAwesomeIcon icon={faRotate} /> {navigation.state === "loading" ? "Refreshing" : "Refresh"}</Button>
            <span className="d-inline-block" data-toggle="tooltip" title="Read only: no upload">
                <Button variant='primary' onClick={(e)=>setShowUpload(true)} disabled={folderContent?.baseFolder?.operationAllowed  !== "Write" || showUpload} title="upload one or several files" aria-label='upload'><FontAwesomeIcon icon={faUpload} /> Upload</Button>
            </span>
            <span className="d-inline-block" data-toggle="tooltip" title="Read only: cannot create folder" aria-label='new folder'>
              <Button variant='primary' onClick={(e)=>newFolder(e)} disabled={showFolder  || folderContent?.baseFolder?.operationAllowed  !== "Write"} title="create a folder and go in it"><FontAwesomeIcon icon={faPlus} /> New Folder</Button>
            </span>
            {
              folderContent?.baseFolder?.securityModel !== "NoToken" &&
              <Button variant='primary' onClick={(e)=>getToken(e)} disabled={showTokenGenerator} title="Generate a SAS token or a presigned URL" aria-label='get token'><FontAwesomeIcon icon={faKey} /> Get Token</Button>
            }
            <Button variant='primary' onClick={(e)=>prepareDownload(e)} disabled={showDownload || contentItemsChecked.size===0} title="Download all the checked files" aria-label='download'><FontAwesomeIcon icon={faDownload} />  Download</Button>
            <Button variant='primary' onClick={(e)=>prepareDeleteMass(e)} disabled={showDelete || contentItemsChecked.size===0} title="Delete all the checked files" aria-label='delete' ><FontAwesomeIcon icon={faTrash} />  Delete</Button>
            {
              contentItems && contentItems.reduce((acc, item) => acc + ((item.isFile || !item.isBaseFolder) && item.canRename ? 1 : 0), 0) > 0 &&
              <span className="d-inline-block" data-toggle="tooltip" title="Rename">
                <Button variant='primary' onClick={(e)=>newRename(e)} disabled={folderContent?.baseFolder?.operationAllowed  !== "Write" || contentItemsChecked.size!==1} title="rename" aria-label='rename' ><FontAwesomeIcon icon={faFilePen} /> Rename</Button>
              </span>
            }
            {
              contentItems && contentItems.reduce((acc, item) => acc + ((item.isFile || !item.isBaseFolder) && item.canRename ? 1 : 0), 0) > 0 &&
              <span className="d-inline-block" data-toggle="tooltip" title="move">
                <Button variant='primary' onClick={initMove} disabled={folderContent?.baseFolder?.operationAllowed  !== "Write" || contentItemsChecked.size!==1} title="move" aria-label='move'><FontAwesomeIcon icon={faFolderTree} /> Move</Button>
              </span>
            }
          </Col>
          {hasPagination && 
          <Col xs={2}>
              <Button variant='primary' onClick={handlePreviousPage} disabled={!searchByAPI ? continuationTokens.length <= 1 : continuationTokensSearch.length <= 1} title="Prevous page" aria-label='previous'>Previous</Button>
              <Button variant='primary' onClick={handleNextPage} disabled={!searchByAPI ? !continuationTokens[continuationTokens.length - 1] : !continuationTokensSearch[continuationTokensSearch.length - 1]} title="Next page" aria-label='next'>Next</Button>
          </Col>
          }
          <Col>
            <InputGroup className="flashsearch">
                <Form.Control
                    type="text"
                    value={search}
                    placeholder="case sensitive, startwith"
                    onChange={(event) => {
                      handleSearch2(event, event.currentTarget.value)
                    }}
                    >
                </Form.Control>
                <Button variant="" onClick={resetSearch} >
                    <FontAwesomeIcon icon={faXmark} className="fa-fw" />
                </Button> 
            </InputGroup>
          </Col>

        </Row>

<div ref={ref}>
<Overlay
  show={showFolder}
  target={targetFolder}
  placement="bottom"
  container={ref}
  containerPadding={20}
>
  <Popover id="popover-contained">
    <Popover.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Control type='text' value={folder} onChange={(e)=> setFolder(e.target.value)}  /> 
        </Form.Group>
        <Button variant='primary'  className='folderConfirm' size="sm" onClick={onFolderCreate} type="submit">OK</Button>
        <Button variant='secondary' className='folderConfirm' size="sm" onClick={(e)=> setShowFolder(false)} >Close</Button>
      </Form>
    </Popover.Body>

  </Popover>
</Overlay>
<Overlay
  show={showRename}
  target={targetRename}
  placement="bottom"
  container={ref}
  containerPadding={20}
>
  <Popover id="popover-contained">
    <Popover.Body>
      <Form>
        <Form.Group className="mb-3">
          <Form.Control type='text' value={rename} onChange={(e)=> setRename(e.target.value)}  /> 
        </Form.Group>
        <Button variant='primary'  className='folderConfirm' size="sm" onClick={onRename} type="submit">Rename</Button>
        <Button variant='secondary' className='folderConfirm' size="sm" onClick={(e)=> setShowRename(false)} >Close</Button>
      </Form>
    </Popover.Body>

  </Popover>
</Overlay>        
<TokenGenerator show={showTokenGenerator} files={Array.from(contentItemsChecked.values()).filter((item) => item.isFile)} handleShowChange={setShowTokenGenerator} baseFolder={folderContent?.baseFolder} basePath={realpath} tokenProvider={folderContent?.tokenProvider} />
</div>    
<FileListUpload show={showUpload} close={closeUpload}  basePath={realpath} cloudStorageId={cloudStorageId}/>
<FileListDownload files={filesDownload} show={showDownload} close={closeDownload} basePath={realpath} cloudStorageId={cloudStorageId}/>
<FileListDelete files={filesDelete} show={showDelete} close={closeDelete}  basePath={realpath} cloudStorageId={cloudStorageId}/>
<Modal show={showMove} onHide={handleCloseMove} size="lg" backdrop="static">
<Modal.Header closeButton>
    <Modal.Title>Move</Modal.Title>
  </Modal.Header>
  <Modal.Body >
    Select the destination Folder
    <br/>
    {
      showMoveLoading && 
      <Spinner animation="border" variant="primary" >
      </Spinner>  
    }

    <div className='movepopup'>
      <TreeView
        data={dataMove}
        aria-label="folder tree"
        onLoadData={onMoveLoadData}
        togglableSelect
        onNodeSelect={({element}) => setSelectedMove(element.id as string)}
        expandedIds={expandedMove}
        selectedIds= {[selectedMove]}
        nodeRenderer={({
          element,
          isBranch,
          isExpanded,
          getNodeProps,
          level
        }) => {

          return (
            <div
              {...getNodeProps()}
              style={{ marginLeft: 40 * (level - 1) }}
            >
              {isBranch && branchNode(isExpanded, element)}
              <span className="name">{element.name}</span>
            </div>
          );
        }}
      />
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseMove}>
      <FontAwesomeIcon icon={faXmark} /> Close
    </Button>
    <Button variant="primary" disabled={showMoveLoading || selectedMove === (realpath === "" ? improbablePathforRoot2 : realpath)} onClick={onMove}>
      <FontAwesomeIcon icon={faTrash} /> Move
    </Button>
  </Modal.Footer>   
</Modal>  

{
downloadFileName!=='' &&
  <div className='sticky-top mx-2' id="progressDownload" >
    <ProgressBar now={  percentComplete *100 } />
    <span>{downloadError}</span>
  </div>
}
{(navigation.state === "loading" || contentItems===undefined || fetcher.state === "loading") && 
  <Row className='mx-2'><Col>     
  <Spinner animation="border" variant="primary" className="align-middle mx-3" >
      <span className="visually-hidden">Loading...</span>
  </Spinner>     
  </Col></Row>
}

<div className="mt-2 mx-2">
  <small className="text-muted">
  {showingComment()}&nbsp;({sortComment()}{hasPagination && sort.column!=="name"?", current page":""})
  </small>
</div>
<Table hover  id="tableFiles" size="sm" className='mt-2 mx-2 table-fit '>
<thead>
  <tr>
    
    <th>
      <input
              type="checkbox"
              checked={allChecked}
              title="select all items"
              onChange={() => toggleCheckAll()}
            />      
    </th>
    <th onClick={(e)=>columnClick(e,sortNatural)} 
      title={ sort.column === sortNatural && !sort.isDescending? 'sort by files descending then by folders descending' : 'sort by folders then by files'} aria-label='sort by folder then by files'
      >
      <FontAwesomeIcon icon={faFileRegular} />
    </th>
    <th onClick={(e)=>columnClick(e,"name")} 
        title={columnTitle("name")} aria-label={columnTitle("name")}
        >
        Name 
      { sort.column === "name" &&
        <FontAwesomeIcon className='ms-2' icon={sort.isDescending ? faSortDown : faSortUp} />
      }
    </th>
    <th onClick={(e)=>columnClick(e,"modified")}
        title={columnTitle("modified")} aria-label={columnTitle("modified")}
        >
      Modified 
      { sort.column === "modified" &&
        <FontAwesomeIcon className='ms-2'icon={sort.isDescending ? faSortDown : faSortUp} />
      }
    </th>
    <th onClick={(e)=>columnClick(e,"size")}
        title={columnTitle("size")} aria-label={columnTitle("size")}
        >
      Size 
      { sort.column === "size" &&
        <FontAwesomeIcon className='ms-2' icon={sort.isDescending ? faSortDown : faSortUp} />
      }
    </th>
    <th> </th>
  </tr>
</thead>
<tbody>
  {
    contentItems?.map((item,index)=> (
      <tr key={item.name}>
        <td>
          <input
            type="checkbox"
            checked={contentItemsChecked.has(item.name)}
            title="select item"
            onChange={() => toggleRow(item)}
          />                  
        </td>
        <td>
          <Icon {...getFileTypeIconProps(iconForFluentUI(item))} className="fileicon" aria-label={ getFileExtension( item.name ) + ' file type icon'} />
        </td>
        <td>
          {item.isFile &&
            <button className='browserlink'><Link onClick={(e) => downloadFile(e, item)}  to={item.name}>{item.name}</Link></button>   
          }
          {!item.isFile &&
            <button className='browserlink'><Link onClick={(e) => {e.preventDefault(); navigate(encodePath(item.path) )}}  to={item.path}>{item.name}</Link></button>
          }
        </td>
        <td>
          {
            item.isFile && item.modified &&
              formatDate(item.modified)
          }
        </td>
        <td>
          {
            item.isFile && 
              <span title={item.size.toString()}>{myfileSize(item.size)}</span>
              
          }
        </td>
        <td>
          {
            item.isFile &&
            <button className='browserlink ' onClick={(e)=> item.isFile ? downloadFile(e, item)  : null } title="download"><FontAwesomeIcon icon={faDownloadRegular} /></button>
          }
          {
            (!item.isFile) &&
            <button className='browserlink ' disabled title="noicon"><Icon iconName='' /></button>
          }
          {
            folderContent.baseFolder?.operationAllowed ==="Write" &&
              <button className='browserlink ' onClick={(e)=>prepareDeleteUnit(e,item)} title="delete"><FontAwesomeIcon icon={faTrashRegular} /></button>
          }
        </td>
      </tr>
    ))
  }
</tbody>
</Table>

</>
);
};