import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import { useRouteError } from "react-router-dom";

export const ErrorGeneric = () => {
  let error = useRouteError();
    // Uncaught ReferenceError: path is not defined
    return <>
        <Alert variant='danger'><FontAwesomeIcon icon={faCircleExclamation} className="error-icon" /> Oops! There was an error.</Alert>
        <small>technicall error: {error?.toString()}</small>
        <small>technicall error2: {JSON.stringify(error)}</small>
      </>;
    
  }