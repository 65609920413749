import { FunctionComponent, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, FormGroup, FormLabel, InputGroup, Modal,  Row, Spinner, Table } from "react-bootstrap";
import { DatatableWrapper, Filter,  TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { GraphResult, IGraphProvider, Permission } from "../types";
import { Icon } from "@fluentui/react";
import { useLoaderData, useNavigation } from "react-router";
import { useMsal} from "@azure/msal-react";
import { GraphGroup, GraphUser } from "../GraphService";
import { useFetcher, useLocation } from "react-router-dom";
import { SubmitTarget } from "react-router-dom/dist/dom";

interface PermissionsProps {
    cloudStorageId: string,
    operation: "Owner" | "Write"  | "Read"
}

interface PermissionVM extends Permission {
    button?:string;
}
interface GraphResultVM extends GraphResult {
    button?:string;
}
export const AdminPermissionOperation : FunctionComponent<PermissionsProps> = ({cloudStorageId, operation}) => {
    const loaderData = useLoaderData() as any;
    const allpermissions = loaderData.permissions as PermissionVM[];
    const permissions = allpermissions.filter((v)=> v.operation === operation);
    const navigation = useNavigation();
    const msalContext = useMsal();
    const fetcher = useFetcher();
    const location = useLocation();
    let headers : TableColumnType<PermissionVM>[] = [
        { title: 'Display Name', prop: 'displayName', isFilterable: true, isSortable:true },
        { title: 'kind', prop: 'kind', isFilterable: true, isSortable:true },
        { title: 'group id or user upn', prop: 'objectId', isFilterable: true, isSortable:true},
        { title: 'email', prop: 'email', isFilterable: true, isSortable:true, 
            cell: (row:PermissionVM)=>(
                <>
                    {
                        !row.email && "no email"
                    }
                    {row.email}
                    &nbsp;<Button variant="outline-danger" size="sm" onClick={()=>launchAddEmail(row)}><Icon iconName='Search'/></Button>
                </>
            )},
        { title: '', prop: 'button', isFilterable: false, isSortable:false,
            cell: (row:PermissionVM)=>(
                <Button variant="outline-danger" onClick={()=>launchDelete(row)}><Icon iconName='Delete'/></Button>
            )
        },

      ];
    if (operation !== "Owner") {
        headers.splice(3,1);
    } else {
        headers[2].title="user admin UPN"
    }

    const [popupDeleteShow, setPopupDeleteShow] = useState(false);    
    const popupDeleteHandleClose = () => setPopupDeleteShow(false);
    const [popupDeleteItem, setPopupDeleteItem] = useState<PermissionVM|null>(null);    
    const launchDelete = async (item: PermissionVM) => {
        setPopupDeleteItem(item);
        setPopupDeleteShow(true);
    };
    const popupDeleteDelete = async () => {
        if (popupDeleteItem) {
            const values2 : SubmitTarget = popupDeleteItem as unknown as  SubmitTarget;
            fetcher.submit( values2, { method: "post", encType: "application/json", action:location.pathname + "/delete"} );
        }
    };
    useEffect( ()=> {
        // si l'action est soumise, on attend la fin pour fermer la popup
        if (popupDeleteItem && fetcher.data && fetcher.state==="idle"  ){
            setPopupDeleteItem(null);
            fetcher.data = null;
            setPopupDeleteShow(false);
        }
    }, [fetcher, fetcher.state, fetcher.data, popupDeleteItem]);



    const [popupShow, setPopupShow] = useState(false);    
    const [popupProvider, setPopupProvider] = useState<IGraphProvider |null>(null);
    const [popupResult, setPopupResult] =  useState<GraphResult[]>([]);
    const [popupLoading, setPopupLoading] = useState(false);    
    const [popupCurrentItem, setPopupCurrentItem] = useState<PermissionVM|undefined>(undefined);    
    const popupHandleClose = () => setPopupShow(false);
    const popupHandleAddUser = () => {
        setPopupProvider(new GraphUser());
        setPopupCurrentItem(undefined);
        setPopupObjectId("");
        setPopupDisplayName("");
        setPopupResult([]);
        setPopupError("");
        setPopupShow(true);
    }
    const popupHandleAddGroup = () => {
        setPopupProvider(new GraphGroup());
        setPopupCurrentItem(undefined);
        setPopupObjectId("");
        setPopupDisplayName("");
        setPopupResult([]);
        setPopupError("");
        setPopupShow(true);
    }
    const launchAddEmail = (row:PermissionVM) => {
        setPopupProvider(new GraphUser());
        setPopupCurrentItem(row);
        setPopupObjectId("");
        setPopupDisplayName("");
        setPopupResult([]);
        setPopupError("");
        setPopupShow(true);
    }    
    const popupSearch = async (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const tosearch = event.currentTarget.value.toLowerCase();
        if (popupProvider && tosearch  )  { // && tosearch && tosearch.length>=3 
            setPopupLoading(true);
            let additionalFilter:string|undefined = undefined;
            if ( operation === "Owner" && popupProvider.objectName === "User") {
                if (popupCurrentItem === undefined)
                    additionalFilter = "endsWith(userPrincipalName, '@admin.hubtotal.net')";
                else 
                    additionalFilter = "endsWith(userPrincipalName, 'totalenergies.com')";
            }
            const userOrGroup = await popupProvider.search(msalContext, tosearch, additionalFilter);
            setPopupResult(userOrGroup);
            setPopupLoading(false);
        } else {
            setPopupResult([]);
            setPopupLoading(false);
        }
    }
    const popupAddCommon = async (newItem: Permission) => {
        if (permissions.filter((v) => v.kind === newItem.kind && v.objectId===newItem.objectId).length > 0 ){
            setPopupError("this " + popupProvider?.objectName.toLowerCase() + ' already exist' );
        } else {
            setPopupError("");
            const values2 : SubmitTarget = newItem as unknown as  SubmitTarget;
            fetcher.submit( values2, { method: "post", encType: "application/json", action:location.pathname + "/add"} );
        }
    };
    const popupAddFromSearch = async (item: GraphResult) => {
        if (item.objectId && item.displayName && popupProvider) {
            if (popupCurrentItem === undefined  ) {
                const newItem: Permission = {
                    cloudStorageId: cloudStorageId ,
                    operation: operation,
                    kind:popupProvider.objectName,
                    objectId: item.objectId,
                    displayName:item.displayName,
                    email: ""
                }
                await popupAddCommon(newItem);
            } else if( item.mail) {
                popupCurrentItem.email = item.mail
                setPopupError("");
                const values2 : SubmitTarget = popupCurrentItem as unknown as  SubmitTarget;
                fetcher.submit( values2, { method: "post", encType: "application/json", action:location.pathname + "/add"} );                
                setPopupShow(false);
            } else {
                setPopupError("this " + popupProvider?.objectName.toLowerCase() + ' do not have email' );
            }
        }
    };    
    const headersPopup : TableColumnType<GraphResultVM>[] = [
        { title: 'Display Name', prop: 'displayName', isFilterable: true, isSortable:true },
        { title: 'group id or user upn', prop: 'objectId', isFilterable: true, isSortable:true},
        { title: '', prop: 'button', isFilterable: false, isSortable:false,
            cell: (row)=>(
                <>
                    <Button variant="outline-danger" onClick={()=>popupAddFromSearch(row)} disabled={fetcher.state !== "idle"}><Icon iconName='Add'/> {popupCurrentItem === undefined ? "Add" : "Associate email"}</Button>
                </>
            )
        },

      ];
    const [popupObjectId, setPopupObjectId] = useState("");    
    const [popupDisplayName, setPopupDisplayName] = useState("");    
    const popupAddFromManualSteps = async () => {
        if (popupProvider && popupObjectId && popupDisplayName) {
            const newItem: Permission = {
                cloudStorageId: cloudStorageId ,
                operation: operation,
                kind:popupProvider.objectName,
                objectId: popupObjectId,
                displayName: popupDisplayName,
                email: ""
            }        
            await popupAddCommon(newItem);
        }
    };    
    const [popupError, setPopupError] = useState("");    


    return <>
    
            <DatatableWrapper body={permissions} headers={headers} paginationOptionsProps={{
                initialState:{
                    rowsPerPage:10000,
                    options: [5, 10, 15, 20,10000]
                }
            }}>
                <Row className="mb-4">
                    <Col
                        xs={12}
                        lg={9}
                        className="d-flex flex-col justify-content-begin align-items-end"
                        >
                        <Button variant='secondary'  title="Add user" onClick={popupHandleAddUser} className={navigation.state === "loading" ? "opaque" : ""}> Add User</Button> 
                        { operation !== "Owner" &&
                            <Button variant='secondary'  title="Add group" onClick={popupHandleAddGroup}  className={navigation.state === "loading" ? "opaque mx-3" : "mx-3"}> Add Group</Button>
                        }
                        {navigation.state === "loading" &&     
                            <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>     
                        }                       
                    </Col>
                    <Col
                    xs={12}
                    lg={3}
                    className="d-flex flex-col justify-content-end align-items-end"
                    >
                    <Filter placeholder='search' classes={{inputGroup:"flashsearch", clearButton:""}}/>
                    </Col>
                </Row>
                <Table>
                    <TableHeader />
                    <TableBody  />
                </Table>    
            </DatatableWrapper>
            <Modal show={popupShow} onHide={popupHandleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Add a new {popupProvider?.objectName} as {operation}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid className="main-flex">
                    {popupProvider?.objectName==="User" && popupCurrentItem === undefined && 
                        <Row><Col>
                        <span>Add a new user of Azure Active Directory
                            <br/>
                            {operation === "Owner" && 
                            <b>For owner role, only admin accounts are allowed</b>
                            }
                            { operation !== "Owner" &&
                            <>
                                Can be any account like firstname.lastname@totalenergies.com or firstname.lastname@external.totalenergies.com or AXXXXXXXX@admin.hubtotal.net
                                <br/>
                                External accounts (account not managed by TotalEnergies) are forbidden
                            </>
                            }
                        </span>
                        </Col></Row>
                    }
                    {popupProvider?.objectName==="User" && popupCurrentItem !== undefined && 
                        <Row><Col>
                        <span>Search for the email of the user {popupCurrentItem?.displayName}
                        </span>
                        </Col></Row>
                    }                    
                    {popupProvider?.objectName==="Group" && 
                        <Row><Col>
                        <span>Add a new group of Azure Active Directory
                            <br/>
                            You must get manually information about the group.
                            <br/>
                            <ul>
                                <li>
                                Open the <a href="https://portal.azure.com/?feature.msaljs=true#view/Microsoft_AAD_IAM/GroupsManagementMenuBlade/~/AllGroups" target="_blank" rel="noopener noreferrer" >Azure portal / Entra Id / groups</a>
                                </li>
                                <li>
                                Search an existing group, get the Object Id and the display name.
                                </li>
                                <li>
                                Fill the form and click on add (when you fill the form, the infos are NOT checked. When user log in into flash, only the objectid is checked)
                                </li>
                            </ul>
                             Group can be :
                             <ul>
                                <li>The members of an existing Microsoft Team. The users defined at the team level are sync in an AAD group with the same name of the team.</li>
                                <li>A group of your landing zone <a href="https://digitalplatforms.totalenergies.com/documentation/platforms/azure-platform/draft-azlz-wip/apis/mr-robot/groups/create-group-v2" target="_blank" rel="noopener noreferrer" >created by Mr Robot</a> </li>
                                <li>A group <a href="https://itsm.hubtotal.net/sp?id=sc_cat_item&sys_id=c01456abfdceff8818d42846fb462723" target="_blank" rel="noopener noreferrer">created in LIFT by a SR in Service Now</a></li>
                             </ul>
                        </span>
                        </Col></Row>
                    }
                    {(popupLoading || fetcher.state !== "idle") && 
                        <Row><Col>     
                        <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>     
                        </Col></Row>
                    }
                    {
                        popupError &&
                        <Row><Col><Alert variant="danger">{popupError}</Alert></Col></Row>
                    }
                    { popupProvider?.canSearch && 
                        <Form  >
                        <Row className="mt-3"><Col>
                            <InputGroup className="flashsearch">
                                <Form.Control
                                    type="text"
                                    placeholder="part of name or email"
                                    onChange={popupSearch}
                                    >
                                </Form.Control>
                            </InputGroup>

                        </Col><Col></Col></Row>
                        <Row><Col>
                        <DatatableWrapper body={popupResult} headers={headersPopup} paginationOptionsProps={{
                            initialState:{
                                rowsPerPage:10000,
                                options: [5, 10, 15, 20,10000]
                            }
                            }}>
                            <Table className="mt-2">
                                <TableHeader />
                                <TableBody  />
                            </Table>                    
                            </DatatableWrapper>                        
                        </Col></Row>
                        </Form>    
                    }
                    { !popupProvider?.canSearch && 
                        <Form >
                        <Row>
                            <Col className="col-9">
                                <FormGroup as={Row} className="" controlId="objectId" >
                                    <FormLabel column sm="2">Object Id</FormLabel>
                                    <Col sm="10" >
                                        <Form.Control
                                                    type="text"
                                                    name="objectId"
                                                    placeholder="Example: 898bc067-6c38-410b-b7d8-2172a5a00c51"
                                                    value={popupObjectId}
                                                    onChange={(e)=>setPopupObjectId(e.target.value)} 
                                                />                                      
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row} className=""  controlId="displayName" >
                                    <FormLabel column sm="2">Display Name</FormLabel>
                                    <Col sm="10" >
                                        <Form.Control
                                                    type="text"
                                                    name="displayName"
                                                    placeholder="the group name"
                                                    value={popupDisplayName}
                                                    onChange={(e)=>setPopupDisplayName(e.target.value)}
                                                />                                      
                                    </Col>
                                </FormGroup>                                
                            </Col>
                            <Col className="col-2">
                                <FormGroup as={Row} className=""  controlId="displayName" >
                                    <Button variant="outline-danger" onClick={popupAddFromManualSteps} disabled={fetcher.state !== "idle"}  ><Icon iconName='Add'/> Add</Button>
                                </FormGroup>                                
                            </Col>
                        </Row>
                        </Form>                        
                    }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={popupHandleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>    
            <Modal show={popupDeleteShow} onHide={popupDeleteHandleClose}  size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Really delete the {popupDeleteItem?.kind.toLowerCase()} {popupDeleteItem?.displayName} {popupDeleteItem?.kind === "User" ? "(" + popupDeleteItem?.objectId + ")": "" }?
                {fetcher.state !== "idle" && 
                            <Row><Col>     
                            <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>     
                            </Col></Row>
                }            
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={popupDeleteHandleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={popupDeleteDelete}>
                    Delete
                </Button>
            </Modal.Footer>
            </Modal>
    </>;
}
