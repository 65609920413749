import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { Client, PageCollection} from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Group, User } from 'microsoft-graph';
import { graphAPIScopes } from './authConfig';
import { IMsalContext } from '@azure/msal-react';
import { GraphResult, IGraphProvider } from './types';

let graphClient: Client | undefined = undefined;

function ensureClient(msalContext: IMsalContext) {
  if (!graphClient) {
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msalContext.instance as PublicClientApplication,
        {
          account: msalContext.accounts[0],
          scopes: graphAPIScopes,
          interactionType: InteractionType.Silent
        }
      );
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }
  return graphClient;
}


export async function getUser(instance: IMsalContext): Promise<User> {
    ensureClient(instance);
  
    // Return the /me API endpoint result as a User object
    const user: User = await graphClient!.api('/me')
      // Only retrieve the specific fields needed
      .select('displayName,mail,userPrincipalName')
      .get();
  
    return user;
  }

export class GraphUser implements IGraphProvider {
  canSearch: boolean = true; // possible grace au doit low risk : User.ReadBasic.All
  objectIdFieldName: string = "User Principal Name";
  objectName: string= "User";

  async search(instance: IMsalContext, search: string, additionalFilter?: string): Promise<GraphResult[]> {
    ensureClient(instance);

    const toSearch = '$search=("displayName:' + search + '" OR "mail:' + search + '" OR "userPrincipalName:' + search + '")'
    const limitGraphAPI = 10
    // il ny aura pas de pagination grace à la limite. Au besoin regarder https://github.com/microsoftgraph/msgraph-sample-reactspa/blob/main/graph-tutorial/src/GraphService.ts
    // on veut filtrer les utilisateurs "que" membre de l'AAD.
    // or avec le droit User.ReadBasic.All  on a pas le droit de récupérer le userType de l'utilisateur (qui est égal à member ou guest)
    // plan B : les guest on un UPN qui se termine par #EXT#@totalworkplace.onmicrosoft.com 
    // exemples à exclure (car guest ): jeanpierrec_exakis.com#EXT#@totalworkplace.onmicrosoft.com ou denise.camal_totalenergies.co.mz#EXT#@totalworkplace.onmicrosoft.com
    // exemple à conserver (car membres) : AL0407502@admin.hubtotal.net, denise.camal@totalenergies.com, jean-pierre.camalot@external.totalenergies.com 
    // ==> on exclu tous les utilisateurs dont le UPN se termine par #EXT#@totalworkplace.onmicrosoft.com 
    // 
    // note : récup mail, givenname, surname pas fiable. Sur comptes admins (AL/AJ) : vide..
    let filter = "not(endsWith(userPrincipalName, '%23EXT%23@totalworkplace.onmicrosoft.com'))"
    if (additionalFilter) {
      filter = "(" + filter + ") and (" + additionalFilter + ")"
    }

    const response: PageCollection = await graphClient!
      //.api("/users?" + toSearch + "&$top=" + limitGraphAPI + "&$orderby=displayName&")
      .api("/users?$select=displayName,mail,userPrincipalName&" + toSearch + "&$top=" + limitGraphAPI + "&$orderby=displayName&$filter=" + filter)
      .header("ConsistencyLevel", "eventual")
      .get()
    const users = response.value as User[];
    
    return users.map((v)=> ({
      kind: 'User',
      displayName: v.displayName,
      objectId: v.userPrincipalName,
      mail: v.mail ? v.mail: undefined
    }));
  }

}

// recherche de groupe : KO car pas le droit Group.Read.All
// mais au cas ou : le code est là... Mettre le boolean à true ou false

export class GraphGroup implements IGraphProvider {
  canSearch: boolean = false; // pas possible. pas le droit  :  Group.Read.All
  objectName: string= "Group";
  objectIdFieldName: string = "ObjectId";

  async search(instance: IMsalContext, search: string, additionalFilter?: string): Promise<GraphResult[]> {
    ensureClient(instance);
    const toSearch = '$search=("displayName:' + search + '")'
    const limitGraphAPI = 10
    const response: PageCollection = await graphClient!
      //.api("/users?" + toSearch + "&$top=" + limitGraphAPI + "&$orderby=displayName&")
      .api("/groups?$select=displayName,id&" + toSearch + "&$top=" + limitGraphAPI + "&$orderby=displayName" + (additionalFilter ? "&$filter=" + additionalFilter : ""))
      .header("ConsistencyLevel", "eventual")
      .get()
    const users = response.value as Group[];
    
    return users.map((v)=> ({
      kind: 'Group',
      displayName: v.displayName,
      objectId: v.id
    }));
  }

}



// recherche sur /me/memberOf : KO ne liste que les ids de groupes, pas les displayname
// donc je ne peut meme proposer une fonction de type "group I belong"
// par défaut (voir https://digitalplatforms.totalenergies.com/documentation/platforms/azure-platform/draft-azlz-wip/apis/mr-robot/applications/grant-low-risk-0)
//   on a pas le droit de requéter les groupes. Seulement les utilisateurs.... 
// extrait : If you need other Microsoft Graph permissions, you have to submit a request to your cyber-security coach.
