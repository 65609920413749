// so thank you https://github.com/ryanerdmann/azure-storage-browser/blob/main/src/AzureDirectoryProvider.ts#L21
import { BlobItem, BlobPrefix } from '@azure/storage-blob';
import { ContentItem } from '../types';
import { getFileName, safelyDecodeURIComponent } from '../utils'
import { AzureBlobProvider } from './AzureBlobProvider';
import { DataLakeFileSystemClient, DataLakeServiceClient } from '@azure/storage-file-datalake';

interface DatalakeClientFilePath {
  client: DataLakeFileSystemClient;
  filePath: string;
}

// Datalake is almost like a blob... technically a datalake is a storage account blob with HNS
// HNS allow to perform others operations like rename, move, etc...
export class AzureDataLakeProvider extends AzureBlobProvider {

  async rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    return await this.moveOrRename(path, item, path, newName);
  }
  async move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    return await this.moveOrRename(path, item, newPath, item.name, options);
  }

  // for datalake, move and rename are the same operation
  async moveOrRename(path: string, item: ContentItem, newPath: string, newName:string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    let fullName = item.name ;
    const containerClient  = this.getDataLakeContainerClientAndFilePath(path, fullName, false);  
    if (containerClient) {
      const client = containerClient.client.getFileClient(containerClient.filePath);
      const newFullName = this.getNewName(newPath, newName);
      let url = new URL(this.tokenInfo.urlWithSignature[0]);
      let sas = url.search;      
      await client.move( newFullName  +  sas, options );
    }
    return true;
  }

  async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    const containerClient  = this.getDataLakeContainerClientAndFilePath(path, subfolder, false);  
    if (containerClient) {
      await containerClient.client.getDirectoryClient(containerClient.filePath).createIfNotExists(options);
    }
    return true;
  }  

  async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    if (item.isFile) {
      let fullName = item.name ;
      const containerClient  = this.getContainerClientAndFilePath(path, false, fullName);  
      if (containerClient) {
        const client = containerClient.containerClient.getBlockBlobClient(containerClient.filePath);
        await client.delete(options);
      }
    } else {
      const containerClient  = this.getDataLakeContainerClientAndFilePath(path, item.name, false);  
      if (containerClient) {
        containerClient.client.getDirectoryClient(containerClient.filePath).deleteIfExists(true, options);
      }
    }
    return true;
  }



  // return a client with dfs endpoint
  getDataLakeServiceClient( ): DataLakeServiceClient  {
    let url = new URL(this.tokenInfo.urlWithSignature[0]);
    // Dfs URL not blob. Transform https://xxxx.blob.core.windows.net/ to https://xxx.dfs.core.windows.net/
    let baseUrl = new URL(url.protocol+ "//"+url.host.replace(".blob.core.windows.net",".dfs.core.windows.net")  + url.port);
    let sas = url.search;
    return new DataLakeServiceClient(baseUrl.toString() + sas);
  }

  
  getDataLakeContainerClientAndFilePath(path:string,  fileNameOrPrefixToSearch: string, isFolder: boolean ): DatalakeClientFilePath | null{
    let url = new URL(this.tokenInfo.urlWithSignature[0]);
    if (url.pathname === '/' && !path) // no container name neither in the token neither in the path
      return null;
    path = this.sanetizePath(path, isFolder, fileNameOrPrefixToSearch);
    let containerName = "";
    let restOfPath = "";
    if (url.pathname === '/') {
      containerName = path.split('/')[0];
      restOfPath = path.substring(containerName.length + 1);
    } else  {
      containerName = url.pathname.split('/')[1];
      restOfPath = safelyDecodeURIComponent(url.pathname.substring(containerName.length + 2));
      if (path && this.baseFolder.securityModel !== "FileLevel") {
        if (restOfPath && !restOfPath.endsWith('/'))
          restOfPath += '/';
        restOfPath += path;
      }
    }
    if (!fileNameOrPrefixToSearch  && restOfPath && !restOfPath.endsWith('/'))
      restOfPath += '/';
    return {
      client: this.getDataLakeServiceClient().getFileSystemClient(containerName),
      filePath: restOfPath
    }
  }

  getNewName(path:string,  filename:string) {
    path = this.sanetizePath(path, true, filename);
    let url = new URL(this.tokenInfo.urlWithSignature[0]);
    const containerName = url.pathname.split('/')[1];
    let restOfPath = safelyDecodeURIComponent(url.pathname.substring(containerName.length + 2));
    if (path && this.baseFolder.securityModel !== "FileLevel") {
      if (restOfPath && !restOfPath.endsWith('/'))
        restOfPath += '/';
      restOfPath += path;
    }
    return restOfPath;
  }

  override fromBlobPrefix(path: string, prefix: BlobPrefix): ContentItem {
    let newPath = path ;
    // remove last slash if any
    let idx = prefix.name.lastIndexOf('/', prefix.name.length - 2);
    return {
      isFile: false,
      name: getFileName(prefix.name),
      path: newPath + prefix.name.substring(idx+1), 
      isBaseFolder: false,
      canMove: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel",
      canRename: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel"
    };
  }

  override fromBlobItem(path: string, blob: BlobItem): ContentItem {
    let newPath = path ;
    // remove last slash if any
    let idx = blob.name.lastIndexOf('/', blob.name.length - 2);    
    return {
      isFile: true,
      name: getFileName(blob.name) ,
      path: newPath + blob.name.substring(idx+1), 
      modified: blob.properties.lastModified,
      size: blob.properties.contentLength ?? 0,
      isBaseFolder: false,
      canMove: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel",
      canRename: this.baseFolder.operationAllowed === "Write"&& this.baseFolder.securityModel !== "FileLevel"
    }
  }

  
}