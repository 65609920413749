import { ErrorMessage, Field} from "formik";
import { useState } from "react";
import { CloudStorage } from "../../types";
import { StepConfig } from "react-formik-step-wizard";
import * as Yup from 'yup';
import { Button,  Image, Collapse } from "react-bootstrap";
import { Icon } from "@fluentui/react";


const NamesStep = () => {

    const [helpOpen, setHelpOpen] = useState(false);
    const [helpImg, setHelpImg] = useState("");
    const [helpImg2, setHelpImg2] = useState("");
    const setHelp = (url:string, url2?:string) => {
        if( url !== helpImg){
            setHelpImg(url);
            if (url2)
                setHelpImg2(url2);
            else
                setHelpImg2('');
            setHelpOpen(true);
        } else {
            setHelpOpen(!helpOpen);
        }
    }

    return (
        <>
            <div>Name the cloud storage in flash</div>
            <div className="form-group row mt-3">
                <label htmlFor="displayName" className="col-2 col-form-label">Display Name</label>
                <div className="col-8">
                    <Field type="text" className="form-control" 
                        name="displayName" 
                        placeholder='a name to display'
                        />
                    <ErrorMessage name="displayName"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                    <Button variant="secondary" 
                                onClick={() =>  setHelp("/DisplayName.png","/ShortName.png")}
                                aria-expanded={helpOpen}
                                aria-controls="helpOpen"
                                > Impact on flash <Icon iconName='Help'/></Button>                            
                </div>
            </div>                          
            <div className="form-group row">
                <label htmlFor="cloudStorageId" className="col-2 col-form-label">Short name</label>
                <div className="col-8">
                    <Field type="text" className="form-control" 
                        name="cloudStorageId" 
                        placeholder='a short unique name'
                        />
                    <ErrorMessage name="cloudStorageId"  >{(msg : string) => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
                </div>
                <div className="col-2">
                    <Button variant="secondary" 
                                    onClick={() =>  setHelp("/ShortName.png", "/DisplayName.png")}
                                    aria-expanded={helpOpen}
                                    aria-controls="helpOpen"
                                    > Impact on flash <Icon iconName='Help'/></Button> 
                </div>
            </div>                
            <div className="mt-2" id="adminFormHelp">
                <Collapse in={helpOpen} >
                    <div id="helpOpen">
                        <Image src={helpImg} fluid />
                        {helpImg2!=="" &&
                            <div className="mt-2"> 
                                <Image src={helpImg2} fluid />
                            </div>
                        }
                    </div>
                </Collapse>           
            </div>              
        </>
    );
}

export function NamesStepConfig(initialItem:CloudStorage  ) : StepConfig {
    return {
        id: 'Names',
        component: <NamesStep />,
        initialValues: {
            displayName: initialItem.displayName,
            cloudStorageId: initialItem.cloudStorageId
        },
        validationSchema: Yup.object({
            displayName: Yup.string().required('required'),
            cloudStorageId: Yup.string().required('required').matches(/^[a-z0-9-_]+$/,'only lowercase, digits, - and _'),
        }),
        shouldSkip: (stepValues) => {
            const kind = stepValues.Platform.kind;
            const environment = stepValues.TechnicalInformation.environment;
            let initialValues = undefined; 
            const isSkipping = kind === "AwsDataLakeTE" || kind === "AzureDataLakeTE";

            if (isSkipping) {
                if (kind === "AwsDataLakeTE") {
                    // Save initial values to a temporary variable
                    initialValues = {
                        displayName: stepValues.TechnicalInformation.technicalInfo.split('/')[1]  ,
                        cloudStorageId: "dlw_" + stepValues.TechnicalInformation.technicalInfo.split('/')[1] + "_" + environment.toLowerCase()
                    };
                } else {
                    initialValues = {
                        displayName: stepValues.TechnicalInformation.basePath,
                        cloudStorageId:  "dlz_" + stepValues.TechnicalInformation.basePath + "_" +  environment.toLowerCase()
                    };
                }

                // Set initial values to the form
                stepValues.Names = initialValues;
            }
            return isSkipping;
        },
        disableNextOnErrors: true
    }
}