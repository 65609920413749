import { IMsalContext} from '@azure/msal-react';
import { callApiBackend, callAwsApiBackend} from '../fetch';
import { IDirectoryProvider,ContentItem, TokenInfo, ITokenProvider, ProgressCallback, IDirectoryContent, BaseFolderWithVoucher } from '../types';
import { AzureBlobProvider } from './AzureBlobProvider';
import { AzureFileProvider } from './AzureFileProvider';
import { QueryClient } from 'react-query';
import { AwsS3Provider } from './AwsS3Provider';
import { AzureDataLakeProvider } from './AzureDataLakeProvider';


export class TokenProvider  implements ITokenProvider{
    private msalContext: IMsalContext;
    private baseFolder :BaseFolderWithVoucher;
    private queryCLient: QueryClient;
    constructor(msalContext: IMsalContext, baseFolder :BaseFolderWithVoucher, queryCLient: QueryClient) {
      this.msalContext = msalContext;
      this.baseFolder = baseFolder;
      this.queryCLient = queryCLient;
    }

  
    async get(path: string, options?: { continuationToken?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
      let token: TokenInfo;
      token = await this.getTokenAndCache("List", path, undefined, { continuationToken: options?.continuationToken ? encodeURIComponent(options?.continuationToken) : undefined, pageSize: options?.pageSize, prefixSearch: options?.prefixSearch});
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
        throw new Error("unkown kind");
      let contents = await secondaryProvider.get(path , { continuationToken: options?.continuationToken, pageSize: options?.pageSize, prefixSearch: options?.prefixSearch, abortSignal: options?.abortSignal});
      return contents;
    }
    getTokenQueryKey = (cloudStorageId: string, path: string) => ["GetToken", cloudStorageId, path];


    async getTokenAndCache( operation: "Read" | "Write" | "List" | "Delete" | "ReadList" | "All", path:string, filename:string|undefined
      , options?: { abortSignal?: AbortSignal, fileSize?: number, partSize?: number, continuationToken?: string, pageSize?: number, prefixSearch?: string}
      ): Promise<TokenInfo>{
      let token: TokenInfo | undefined;
      const fullname = this.computeFullname(path, filename);
      const queryKey = this.getTokenQueryKey(this.baseFolder.cloudStorageId,"");
      switch(this.baseFolder.securityModel){
        case "CloudStorageLevel":
        case "ContainerFileShareLevel":
        case "FolderLevel":
        case "NoToken":
          // in this case we can cache the token: same used to list, read, write, delete
          let staleTime = 10 * 60 * 1000; // 10 minutes
          if ( this.baseFolder.maxDurationMinutes < 10)
            staleTime = 1 * 60* 1000; // 1 minute
          operation = this.baseFolder.operationAllowed === "Write" ? "All" : "ReadList";
          // get from cache (getQueryData), if not, call API and put in cache (fetchQuery)
          // we can ask for a token at the root level, it will be used for all operations
          token = await (this.queryCLient.getQueryData(queryKey) ?? 
            this.queryCLient.fetchQuery(queryKey,  () =>
              this.getToken(operation, "",  this.baseFolder.maxDurationMinutes, options)
              ,{staleTime:staleTime}));
          break;
        case "FileLevel":
          token =  await this.getToken(operation, fullname,  this.baseFolder.maxDurationMinutes, options);
          break;
      }
      if (!token)
          throw new Error("no token");
      return token;
    }


  private computeFullname(path: string, filename: string | undefined) {
    let fullname = path;
    if (filename)
      if (path)
        fullname += (path.endsWith("/") ? "" : "/") + filename;

      else
        fullname = filename;
    return fullname;
  }

    async getToken( operation:"Read" | "Write" | "List" | "Delete" | "ReadList" | "All", path:string, durationInMinutes: number
      , options?: { abortSignal?: AbortSignal, fileSize?: number, partSize?: number, continuationToken?: string, pageSize?: number, prefixSearch?: string}
      ): Promise<TokenInfo|undefined>{
      if (options?.prefixSearch) {
        path += options?.prefixSearch;
      }        
      let url = this.baseFolder.urlGetToken + "?durationInMinutes=" + durationInMinutes + "&operation=" + operation  + "&path=" + encodeURIComponent(path) +  "&" + this.baseFolder.voucher;
      if (options?.fileSize && options?.partSize && options?.fileSize > options?.partSize ) {
        url += "&fileSize=" + options?.fileSize + "&partSize=" + options?.partSize
      }
      if (options?.continuationToken ) {
        url += "&continuationToken=" + options?.continuationToken;
      }
      if (options?.pageSize) {
        url += "&pageSize=" + options?.pageSize;
      }
      // if (options?.prefixSearch) {
      //   url += "&prefixSearch=" + options?.prefixSearch;
      // }
      // no cache
      if ( this.baseFolder.kind.startsWith("Azure"))
      {
        return callApiBackend<TokenInfo>(this.msalContext,  url, "GET", {abortSignal: options?.abortSignal} );
      }
      else 
      {
        // then get an Aws credential
        if (!this.baseFolder.roleArn || !this.baseFolder.roleSessionName) 
          throw new Error("no aws info");
        return await callAwsApiBackend(this.msalContext, this.queryCLient, this.baseFolder.roleArn, this.baseFolder.roleSessionName, url, "GET", {abortSignal: options?.abortSignal});
      }
    }




    async upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<boolean>{
      let token: TokenInfo;
      const fileSizeMo = Math.ceil(file.size / (1024*1024) );
      let partSizeUpload = undefined;
      if (fileSizeMo > 50 && this.baseFolder.kind === "AwsS3"){
        // aws up to 10000 parts but each part must be at least 5Mo
        // azr make 4 Mo parts.... So try to make 5 Mo parts
        let numberOfParts = Math.ceil(fileSizeMo / 5);
        if (numberOfParts > 10000)
          numberOfParts = 10000;
        partSizeUpload = Math.ceil(fileSizeMo / numberOfParts); 
      }
      token = await this.getTokenAndCache("Write", path, file.name, {fileSize: fileSizeMo,partSize: partSizeUpload} );

      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.upload(path, file, options);
      return contents;
    }  
    async download( path:string, item:ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<Blob|undefined>{
      let token: TokenInfo;
      token = await this.getTokenAndCache("Read", path, item.name);
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.download(path,item, options);
      return contents;
    }  
    async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
      let token: TokenInfo;
      // AWS needs folder name to end with /
      if (this.baseFolder.kind === "AwsS3")
        token = await this.getTokenAndCache("Write", path, subfolder + "/");
      else
        token = await this.getTokenAndCache("Write", path,undefined); // need permission to write at the parent level
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.createFolder(path,subfolder, options);
      return contents;
    }
    async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
      let token: TokenInfo;
      // AWS needs folder name to end with /
      if (this.baseFolder.kind === "AwsS3" && !item.isFile)
        token = await this.getTokenAndCache("Delete", path, item.name + "/");
      else
        token = await this.getTokenAndCache("Delete", path, item.name);
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.delete(path,item, options);
      return contents;
    }
    async rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      let token: TokenInfo;
      token = await this.getTokenAndCache("Write", path, item.name);
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.rename(path,item, newName, options);
      return contents;
    }
    async move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      let token: TokenInfo;
      token = await this.getTokenAndCache("Write", path, item.name);
      let secondaryProvider: IDirectoryProvider | null = this.getSecondaryProvider(token);
      if (secondaryProvider==null)
          throw new Error("unkown kind");
      let contents = await secondaryProvider.move(path,item, newPath, options);
      return contents;
    }
    getSecondaryProvider( token: TokenInfo ) : IDirectoryProvider | null {
      switch (this.baseFolder.kind) {
        case "AzureBlob": 
          return new AzureBlobProvider(token, this.baseFolder, this);
        case "AzureDataLake": 
          return new AzureDataLakeProvider(token, this.baseFolder, this);
        case "AzureFile": 
          return  new AzureFileProvider(token, this.baseFolder, this);
        case "AwsS3":
          return  new AwsS3Provider(token, this.baseFolder, this, this.msalContext, this.queryCLient);
        default:
          console.warn('unknown kind ' +this.baseFolder.kind);
          return null;
      }
    }

  }