import { ShareDirectoryClient , DirectoryItem, FileItem } from '@azure/storage-file-share';
import { IDirectoryProvider, ContentItem, ProgressCallback, IDirectoryContent, TokenInfo, ITokenProvider, BaseFolderWithVoucher } from '../types';
import { getFileName } from '../utils'
import { TransferProgressEvent } from '@azure/core-rest-pipeline';

export class AzureFileProvider implements IDirectoryProvider {
  private tokenInfo: TokenInfo;
  private baseFolder :BaseFolderWithVoucher;
  private tokenProvider: ITokenProvider;

  constructor(token: TokenInfo, baseFolder: BaseFolderWithVoucher, tokenProvider: ITokenProvider) {
    this.tokenInfo = token;
    this.baseFolder = baseFolder;
    this.tokenProvider = tokenProvider;
  }

  getUrlForStorageAccount( path:string , isFolder: boolean) : URL {
    // a URL https://<account>.file.core.windows.net/<share>/<path/to/file>?<SAS token>
    const url = new URL(this.tokenInfo.urlWithSignature[0]);
    const sas = url.search;
    if (this.baseFolder.securityModel === "ContainerFileShareLevel" || isFolder ){
      const baseUrl = new URL(url.protocol+ "//"+url.host  + url.port + url.pathname + "/");
      if (!path.endsWith('/'))
        path += '/';    
      // left trim path, remove double slash
      path = path.replace(/^\/+/, '').replace("//","/");
        // the token is generated atr file share level. So the URL end with the file share name
      // path contain the folder/file in the file share
      return new URL(sas, new URL(path, baseUrl));
    }
    if (this.baseFolder.securityModel === "FileLevel"){
      // Url was generated for a file... Must return to the last folder 
      const lastFolder = url.pathname.substring(0, url.pathname.lastIndexOf('/'));
      const baseUrl = new URL(url.protocol+ "//"+url.host  + url.port + lastFolder);
      return new URL(sas,  baseUrl);
    }
    throw new Error("not implemented");
  }

  async get(path: string, options?: {continuationToken?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
    const container = new ShareDirectoryClient (this.getUrlForStorageAccount(path, true).toString());
    const files = container.listFilesAndDirectories( { prefix: options?.prefixSearch, includeTimestamps: true, abortSignal: options?.abortSignal }).byPage({ maxPageSize: options?.pageSize, continuationToken: options?.continuationToken });
    let response = (await files.next()).value;

    let contents: ContentItem[] = [];
    for await (const file of response.segment.directoryItems) {
      const item = this.fromDirectory(path, file);
      contents.push(item);
    }
    for await (const file of response.segment.fileItems) {
      const item = this.fromFile(path, file);
      contents.push(item);
    }
    contents.sort((a,b)=> {
      if ((a.isFile && b.isFile) || (!a.isFile && !b.isFile))
        return a.name.localeCompare( b.name );
      if (!a.isFile)
        return -1
      return 1;
    } );
    return {
      elements: contents,
      baseFolder: this.baseFolder,
      tokenProvider: this.tokenProvider,
      continuationToken: response.continuationToken,
      pagesize: options?.pageSize,
      prefixSearch: options?.prefixSearch
    }
  }

  async upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal }): Promise<boolean>{
    const filename = file.name;
    const container = new ShareDirectoryClient (this.getUrlForStorageAccount(path, false).toString());    
    const client = container.getFileClient(filename);
    const opts = { 
      blobHTTPHeaders: { 
        blobContentType: file.type 
      }, 
      onProgress: options?.onprogress,
      abortSignal: options?.abortSignal
    };
    await client.uploadData(file, opts);
    return true;
  }
  async download( path:string, item:ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<Blob|undefined>{
    const container = new ShareDirectoryClient (this.getUrlForStorageAccount(path, false).toString());      
    const client = container.getFileClient(item.name);
    const opts = {
      onProgress:(progress: TransferProgressEvent) => {
        options?.onprogress({ loadedBytes: progress.loadedBytes });
      },
      abortSignal: options?.abortSignal
    }    
    const downloadBlockBlobResponse = await client.download(undefined, undefined, opts);
    return await downloadBlockBlobResponse.blobBody;
  }
  async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    const container = new ShareDirectoryClient (this.getUrlForStorageAccount(path, false).toString());    
    await container.createSubdirectory(subfolder, options);
    return true;
  }
  async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
    const container = new ShareDirectoryClient (this.getUrlForStorageAccount(path, false).toString());    
    if (item.isFile)
      await container.deleteFile(item.name, options);
    else 
      await container.deleteSubdirectory(item.name, options);
    return true;
  }
  rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    throw new Error('Method not supported.');
  }
  move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
    throw new Error('Method not supported.');
  }


  fromDirectory(path: string, prefix: DirectoryItem): ContentItem {
    let newPath = path;
    return {
      isFile: false,
      name: getFileName(prefix.name),
      // path: this.cloudStorageId + '/' + prefix.name.substring(this.additionalPrefix.length -1 ),
      path: newPath  + prefix.name + "/",
      isBaseFolder: false,
    };
  }

  fromFile(path: string, item: FileItem): ContentItem {
    let newPath = path;    
    return {
      isFile: true,
      name: getFileName(item.name),
      path: newPath  + item.name,
      modified: item.properties.lastModified,
      size: item.properties.contentLength ?? 0,
      isBaseFolder: false,
    }
  }
}