import {  DirectoryFLASH} from '../types';
import { useMatches, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { useIsAuthenticated} from '@azure/msal-react';
import { useQueryClient } from 'react-query';
import { getBaseFolderFromCache } from '../fetch';


export const DirectoryBreadcrumbs =  () => {
  let navigate = useNavigate();   
  let matches = useMatches();
  let segments: DirectoryFLASH[] = [];
  const isAuthenticated = useIsAuthenticated();  
  const queryClient = useQueryClient();
  // matches[0] = 1ère route = Layout = / = Home
  // la route * est renvoyée sous la forme d'un seul match /path1/path2/path3 ==> à décomposer en plusieurs segments
  //    route * : le premier segment : remplacer l'url par le display name du context
  // chaque segment : ne doit PAS terminer par /. Sauf première route
  let windowTitle = "";
  if ( isAuthenticated) {
    if (matches.length===1 || (matches.length===2 && matches[1].pathname ==="/") )
      segments.push({ name: 'Flash portal', path: '/' });
    else
      segments.push({ name: 'Home', path: '/' });
    // parce qu'on a une route dans une route : il y a toujours 2 élements
    // revoir ceici si on rajoute un niveau de route
    if (matches.length===2){
      let path = matches[1].pathname;
      let routeId = matches[1].id;

      const parts = path.substring(1).split('/');
      let urlPrefix = "";
      let isFirst : boolean = true;
      for (const p in parts) {
          if (isFirst && parts[p] === "dl") {
            // route dl is special does not count in the navigation
            // but need to include it in the navigation path
            urlPrefix += "/" + parts[p];
          } else {
            const previousPrefix = urlPrefix;
            urlPrefix += "/" + parts[p];
            if (isFirst && (routeId==="navigation" || routeId==="navigationdl"|| routeId==="navigationdl2")) {
                const cloudStorageInfo =  getBaseFolderFromCache(queryClient,parts[p]);
                if ( cloudStorageInfo) {
                  segments.push({ name: decodeURIComponent(cloudStorageInfo.displayName), path: urlPrefix });
                  windowTitle+= " " + cloudStorageInfo.displayName;
                }
                else {
                  segments.push({ name: decodeURIComponent(parts[p]), path: urlPrefix });
                  windowTitle+= " " + parts[p];
                }
                isFirst = false;
            } else if (  parts[p] !== '' ) {

              if (routeId==="navigationdl2" && p==="2" && (parts[p] === "source" || parts[p] === "domain")) {
                segments.push({ name: decodeURIComponent(parts[p]), path: previousPrefix });
                windowTitle+= " " + parts[p];
              } else {
                segments.push({ name: decodeURIComponent(parts[p]), path: urlPrefix });
                windowTitle+= " " + parts[p];
              }
            }
          }

      }

    }  
  } else {
    segments.push({ name: 'Log in', path: '/' });
    windowTitle="Log in";
  }
  const isAdmin = segments.length >1 && segments[1].name === "admin";

  window.document.title = 'FLASH' + windowTitle;
  const onClick = (event: any, item?: DirectoryFLASH): void => {
    event.preventDefault(); 
    let path = item?.path;
    if (path) {
      if ( !path.endsWith("/"))
        path += "/";
      navigate(path);
    }
  }  
  return (
    <>
      <div className="region region-masthead">
          <div id="block-dp-breadcrumbs" className="block block-system block-system-breadcrumb-block"> 
            { 
              segments.length===1 
              ? <span>{segments[0].name}</span>
              :
                <Breadcrumb>
                  {segments.map((segment, index) => {
                      if ( segment.name === "/"){ 
                        return <Breadcrumb.Item key={index} onClick={(e) => onClick(e, segment) } >Home</Breadcrumb.Item>
                      }
                      return <Breadcrumb.Item key={index} onClick={(e) => onClick(e, segment) } active={index === segments.length-1 || (isAdmin && index>1 ) }>{segment.name}</Breadcrumb.Item>
                  })
                  }
                </Breadcrumb>          
            }
          </div>
          <img id="logo" src="/Flash-logo-04.png" alt="Logo FLASH" height="64px" />
      </div>
    </>
  );
}