
import { IMsalContext } from "@azure/msal-react";
import { BaseFolderAzureDataLakeTE,ContentItem, IDirectoryContent, ITokenProvider, ProgressCallback, TokenInfo } from "../types";
import { DataLakeFileSystemClient, DataLakeServiceClient } from "@azure/storage-file-datalake";
import { BlobItem, BlobPrefix, BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { AADTokenCredential, getFileName, safelyDecodeURIComponent } from "../utils";
import { TransferProgressEvent } from '@azure/core-http';


export class AzureDataLakeTEProvider  implements ITokenProvider{
    private msalContext: IMsalContext;
    private baseFolder :BaseFolderAzureDataLakeTE;
    private perimeter: string | undefined;
    constructor(msalContext: IMsalContext, baseFolder :BaseFolderAzureDataLakeTE, perimeter: string | undefined) {
      this.msalContext = msalContext;
      this.baseFolder = baseFolder;
      this.perimeter = perimeter;
    }


    async get(path: string, options?: {continuationToken?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
        const newPath = this.getPath(path, options?.prefixSearch ?? '', false);
        const blobs = this.getContainerClient(path).listBlobsByHierarchy('/', { prefix: newPath, abortSignal: options?.abortSignal  }).byPage({ maxPageSize: options?.pageSize, continuationToken: options?.continuationToken });
        let response = (await blobs.next()).value;

        let contents: ContentItem[] = [];
        for await (const blob of response.segment.blobItems) {
          const item = this.fromBlobItem(path, blob);
          contents.push(item);
        }
        for await (const prefix of response.segment.blobPrefixes) {
          const item = this.fromBlobPrefix(path, prefix);
          contents.push(item);
        }

        let newBaseFolder;
        ({ newBaseFolder, contents } = this.manageVisibilityAndPermissions(newPath, path, contents));

        return {
          elements: contents,
          baseFolder: newBaseFolder,
          tokenProvider: this,
          continuationToken: response.continuationToken,
          pagesize: options?.pageSize,
          prefixSearch: options?.prefixSearch
        }        

    }
    private manageVisibilityAndPermissions(newPath: string, path: string, contents: ContentItem[]) {
      // on Datalake, some folders are "protected" a little hack to avoid to diplay upload/delete buttons
      // first clone and them set operationAllowed to "Read" on some folders
      // second purpose : on apps and domain, filter the list of folders (base/subdomain and object)      
      const newBaseFolder = JSON.parse(JSON.stringify(this.baseFolder)) as BaseFolderAzureDataLakeTE;
      // je suis partagé sur la gestion des droits Azure
      // ofificiellement, un être humain ne peut se mettre que dans le groupe "teams". A ce titre les droits sont les suivants
      //   Apps : écriture seulement dans le folder private. Le reste en lecture seule
      //   source et domain: lecture seule
      // Mais si la personne s'ajoute dans le groupe "application"; en théorie il ne doit pas le faire les droits sont les suivants
      //  Apps : écriture dans tous les dossiers "terminaux" (/common, /private, /exec/internal et /exec/exposed)
      //  source et domain: écriture dans les répertoires "terminaux"
      // 
      // décision JPC : les utilisateurs ne font "que" ce qui est autorisé ==> groupe "teams" uniquement
      // au besoin régfléchir à un moyen de détecter l'appartenance au groupe application et adapter
      newBaseFolder.operationAllowed = "Read";
      const pathPart = newPath.split('/');
      const sourceOrDomain = this.getContainer(path);
      switch (this.perimeter?.toLowerCase()) {
        case "apps":
          if (path.startsWith("private/"))
            newBaseFolder.operationAllowed = "Write";
          break;
        case "source":
          if (pathPart.length === 2) {
            contents = contents.filter(c => this.baseFolder.sourceDL.filter(s => s.source === sourceOrDomain && s.base === c.name).length > 0);
          }
          if (pathPart.length === 3 && pathPart[0] !== "LANDING") {
            contents = contents.filter(c => this.baseFolder.sourceDL.filter(s => s.source === sourceOrDomain && s.base === pathPart[1] && s.object === c.name).length > 0);
          }
          break;
        case "domain":
          if (pathPart.length === 2) {
            contents = contents.filter(c => this.baseFolder.domainDL.filter(s => s.domain === sourceOrDomain && s.subdomain === c.name).length > 0);
          }
          if (pathPart.length === 3) {
            contents = contents.filter(c => this.baseFolder.domainDL.filter(s => s.domain === sourceOrDomain && s.subdomain === pathPart[1] && s.object === c.name).length > 0);
          }
          break;
      }
      return { newBaseFolder, contents };
    }

    async download( path:string, item:ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<Blob|undefined>{
        const newPath = this.getPath(path, item.name, false);
        const containerClient = this.getContainerClient(path);
        if ( containerClient) {    
          const client = containerClient.getBlockBlobClient(newPath);
          const opts = {
            onProgress:(progress: TransferProgressEvent) => {
              options?.onprogress({ loadedBytes: progress.loadedBytes });
            },
            abortSignal: options?.abortSignal
          }
          const downloadBlockBlobResponse = await client.download(undefined, undefined,opts);
          return await downloadBlockBlobResponse.blobBody;
        }
    }
    async upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<boolean>{
        const newPath = this.getPath(path, file.name, false);
        const containerClient = this.getContainerClient(path);
        if ( containerClient) {
            const client = containerClient.getBlockBlobClient(newPath);
            const opts = { 
              blobHTTPHeaders: { 
                blobContentType: file.type 
              }, 
              onProgress: options?.onprogress,
              abortSignal: options?.abortSignal
            };
            await client.uploadData(file, opts);
          }
          return true;
    }
    async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
        const newPath = this.getPath(path, item.path, false);
        if (item.isFile) {
            const containerClient  = this.getContainerClient(path);  
            if (containerClient) {
              const client = containerClient.getBlockBlobClient(newPath);
              await client.delete(options);
            }
          } else {
            const containerClient  = this.getDataLakeFileSystemClient(path);  
            if (containerClient) {
              containerClient.getDirectoryClient(newPath).deleteIfExists(true, options);
            }
          }
          return true;
    }
    async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
        const newPath = this.getPath(path, subfolder, true);
        const containerClient = this.getDataLakeFileSystemClient(path);
        if (containerClient) {
          await containerClient.getDirectoryClient(newPath).createIfNotExists(options);
        }
        return true;
    }

    async rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      return await this.moveOrRename(path, item, path, newName);
    }
    async move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      return await this.moveOrRename(path, item, newPath, item.name, options);
    }    

    // for datalake, move and rename are the same operation
    async moveOrRename(path: string, item: ContentItem, newPath: string, newName:string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      const oldPath = this.getPath(path, item.name, false);
      const newPath2 = this.getPath(newPath, newName, false);
      const containerClient  = this.getDataLakeFileSystemClient(path);  
      if (containerClient) {
        const client = containerClient.getFileClient(oldPath);
        await client.move( newPath2, options );
      }
      return true;
    }


    //region backend
    getStorageAccount() : string {
        switch (this.perimeter?.toLowerCase()) {
            case "apps":
                return this.baseFolder.appStorageAccount;
            case "source":
                if (!this.baseFolder.sourceStorageAccount)
                  throw new Error("No source storage account. Use self service to define a source storage account.");
                return this.baseFolder.sourceStorageAccount;
            case "domain":
              if (!this.baseFolder.domainStorageAccount)
                throw new Error("No domain storage account. Use self service to define a domain storage account.");
              return this.baseFolder.domainStorageAccount;
        }
        throw new Error("Invalid Perimeter " + this.perimeter);
    }

    getContainer(path:string) : string {
        switch (this.perimeter?.toLowerCase()) {
            case "apps":
                return this.baseFolder.appContainer;
            case "source":
            case "domain":
                return path.split('/')[0];
        }
        throw new Error("Invalid Perimeter " + this.perimeter);
    }

    getPath(path:string, fileNameOrPrefixToSearch: string, isFolder: boolean) : string {
        let newPath = this.sanetizePath(path, isFolder, fileNameOrPrefixToSearch);
        if (this.perimeter?.toLowerCase()!=="apps"){
            const idx = newPath.indexOf('/');
            if (idx > 0)
                newPath = newPath.substring(idx+1);
            else
                newPath = '';
        }
        return safelyDecodeURIComponent(newPath);
    }




    getContainerClient(path:string): ContainerClient {
        let url = `https://${this.getStorageAccount()}.blob.core.windows.net`;
        let container = this.getContainer(path);
        const tokenCredential = new AADTokenCredential(this.msalContext);
        return new BlobServiceClient(url, tokenCredential).getContainerClient(container);        
    }


    getDataLakeFileSystemClient(path:string): DataLakeFileSystemClient {
        let url = `https://${this.getStorageAccount()}.dfs.core.windows.net`;
        let container = this.getContainer(path);
        const tokenCredential = new AADTokenCredential(this.msalContext);
        return new DataLakeServiceClient(  url, tokenCredential  ).getFileSystemClient(container);
    }

    //endregion backend

    protected sanetizePath(path: string, isFolder: boolean, fileNameOrPrefixToSearch?: string, ) {
        if (!path.endsWith('/'))
          path += '/';
        // left trim path, remove double slash
        // whith token delivered at FileLevel, url contain already the filename
        if (fileNameOrPrefixToSearch && (this.baseFolder.securityModel !== "FileLevel" || isFolder)) {
          path += '/' + fileNameOrPrefixToSearch;
        }
        path = path.replace(/^\/+/, '').replace("//", "/");
        return path;
    }

    async getToken( operation:"Read" | "Write" | "List" | "Delete" | "ReadList" | "All", path:string, durationInMinutes: number
      , options?: { abortSignal?: AbortSignal, fileSize?: number, partSize?: number, continuationToken?: string, pageSize?: number, prefixSearch?: string}
      ): Promise<TokenInfo|undefined>{
        throw new Error("Method not implemented.");
    }
    fromBlobPrefix(path: string, prefix: BlobPrefix): ContentItem {
        let newPath = path ;
        // remove last slash if any
        let idx = prefix.name.lastIndexOf('/', prefix.name.length - 2);
        return {
          isFile: false,
          name: getFileName(prefix.name),
          path: newPath + prefix.name.substring(idx+1), 
          isBaseFolder: false,
          canMove: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel",
          canRename: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel"
        };
      }
    
    fromBlobItem(path: string, blob: BlobItem): ContentItem {
        let newPath = path ;
        // remove last slash if any
        let idx = blob.name.lastIndexOf('/', blob.name.length - 2);    
        return {
          isFile: true,
          name: getFileName(blob.name) ,
          path: newPath + blob.name.substring(idx+1), 
          modified: blob.properties.lastModified,
          size: blob.properties.contentLength ?? 0,
          isBaseFolder: false,
          canMove: this.baseFolder.operationAllowed === "Write" && this.baseFolder.securityModel !== "FileLevel",
          canRename: this.baseFolder.operationAllowed === "Write"&& this.baseFolder.securityModel !== "FileLevel"
        }
      }
    
}