// from https://github.com/riyadelberkawy/formik-stepper/blob/2.1.3
// then modified by me

import React, { useMemo } from "react";
import { memo, useEffect, useState } from "react";


export interface OneStepProps {
    label?: string;
    description?: string;    
    labelColor?: `#${string}`;
    circleColor?: `#${string}`;
    Icon?: ({ active, done, }: {
        active: boolean;
        done: boolean;
    }) => JSX.Element | null;
}

export interface StepperProps {
    withNumbers?: boolean;
    Icon?: boolean;
    circleColor?: `#${string}`;
    activeStep: number;
    steps: Array<OneStepProps>;
  }
  
  export interface StepProps {
    label?: string;
    Icon?: () => JSX.Element | number | null;
    active?: boolean;
    done?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    labelColor?: `#${string}`;
    circleColor?: `#${string}`;
    children?: React.ReactNode;
  }

  export const Step = memo(
    ({
      label,
      active,
      isFirst,
      isLast,
      labelColor,
      circleColor,
      Icon
    }: StepProps) => {
      const [loading, setLoading] = useState(false);
      const [tColor, setTcolor] = useState("");
      const [crColor, setCrColor] = useState("");
  
      const [classNames, setClassNames] = useState("stepper-step");
  
      useEffect(() => {
        if (active) {
          setClassNames((prev) => prev + "  active-step");
        } else {
          setClassNames("stepper-step");
        }
      }, [active]);
      //// SET COLORS WHICH USER NEEDES
      useEffect(() => {
        if (!crColor && circleColor) {
          if (circleColor.startsWith("#")) {
            setCrColor(`${circleColor}`);
          } else {
            setCrColor(
              getComputedStyle(document.documentElement).getPropertyValue(
                `--${circleColor}`
              )
            );
          }
        }
        if (!tColor && labelColor) {
          if (labelColor.startsWith("#")) {
            setTcolor(`${labelColor}`);
          } else {
            setTcolor(
              getComputedStyle(document.documentElement).getPropertyValue(
                `--${labelColor}`
              )
            );
          }
        }
        if (crColor || tColor || !loading) {
          setLoading(true);
        }
      }, [circleColor, crColor, loading, tColor, labelColor]);
  
      return loading ? (
        <div className={classNames}>
          <div
            className="stepper-circle"
            style={{
              background: circleColor ? `${crColor}` : "",
              filter: !active ? `opacity(0.43)` : "none",
            }}
          >
            <span>{typeof Icon === "function" ? Icon() : null}</span>
          </div>
          <div
            className="stepper-title"
            style={{
              color: `${tColor}`,
              filter: !active ? `opacity(0.43)` : "none",
            }}
          >
            {label}
          </div>
          {!isFirst ? <div className="stepper-bar-left" /> : null}
          {!isLast ? <div className="stepper-bar-right" /> : null}
        </div>
      ) : null;
    },
    (prevProps, nextProps) => {
      return prevProps.active === nextProps.active;
    }
  );

  export const Stepper = React.memo(
    ({ activeStep: step, steps }: StepperProps) => {
      const [activeStep, setActiveStep] = useState(step);
  
      useEffect(() => {
        setActiveStep(step);
      }, [step]);
  
      return (
        <>
        <div className="w-100">
          <div className="stepper-horizontal">
            {steps.map( (step, index) => {
              return (
                <Step
                  key={"step-" + index}
                  label={step.label}
                  active={activeStep === index}
                  done={activeStep > index}
                  isFirst={index === 0}
                  isLast={index === steps.length - 1}
                  Icon={() => {
                    if (typeof step.Icon === "function")
                      return step.Icon({
                        active: activeStep === index,
                        done: activeStep > index,
                      });
                    return index + 1;
                  }}
                  circleColor={step.circleColor}
                  labelColor={step.labelColor}
                />
              );
            })}
          </div>
        </div>
        {
          steps.length>step && 
          <div className="mb-3"><b>{steps[step].label}</b>: {steps[step].description}</div>
        }
        </>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.activeStep === nextProps.activeStep;
    }
  );

  export type ButtonProps = {
    label?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
  };

  export interface StepButtonsProps {
    step: number;
    childrenLength: number;
    nextButton?: ButtonProps;
    prevButton?: ButtonProps;
    submitButton?: ButtonProps;
    setStep: (step: number) => void;
    extraInfo?: string;
  }


  // initially FormikButtons but removing all submission/validation....
  export const StepButtons = memo(
    ({
      step,
      setStep,
      childrenLength,
      nextButton,
      prevButton,
      submitButton,
      extraInfo
    }: StepButtonsProps) => {


      return useMemo(
        () => (
          <div className='navigation'>
          <div className='navigation-wrapper justify-between'>
            {step > 0 && (
              <button type='button' onClick={() => setStep(step - 1)} disabled={prevButton?.disabled} >
              {prevButton?.label || "Prev"}
            </button>              
            )}
            {extraInfo && <div>{extraInfo}</div>}
            {step < childrenLength - 1 && (
              <div onClick={() => setStep(step + 1)}>
              <button type='submit' disabled={nextButton?.disabled} >
                {nextButton?.label || "Next"}
              </button>
              </div>
            )}
            {step === childrenLength - 1 || childrenLength === 1 ? (
              <div onClick={() => setStep(step + 1)}>
              <button type='submit' disabled={submitButton?.disabled}  >
              {submitButton?.label || "Submit"}
              </button>
              </div>              
            ) : null}
          </div>
          </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          childrenLength,
          nextButton?.label,
          nextButton?.style,
          prevButton?.label,
          prevButton?.style,
          step,
          submitButton?.label,
          submitButton?.style,
        ]
      );
    }
  );