import { LoaderFunctionArgs, redirect,useActionData,useLoaderData, useNavigation, useSubmit } from "react-router-dom";
import { CloudStorage, ILoginContext} from "../types";
import {  FetchValidationErrors, callApiBackend } from "../fetch";
import {  Container, Spinner, Alert } from "react-bootstrap";
import { SubmitTarget } from "react-router-dom/dist/dom";
import React from "react";
import {  StepConfig, Wizard } from "react-formik-step-wizard";
import { WizardHeader } from "../components/WizardHeader";
import { Values } from "react-formik-step-wizard/dist/types";
import WizardFooter from "../components/WizardFooter";
import { PlatformStepConfig } from "../components/AdminFormWizard/Platform";
import { TechnicalInformationStepConfig } from "../components/AdminFormWizard/TechnicalInformation";
import { NamesStepConfig } from "../components/AdminFormWizard/Names";
import { OtherParametersStepConfig } from "../components/AdminFormWizard/OtherParameters";

export const GetCloudStorage = (context: ILoginContext) => 
  async ({ request, params }: LoaderFunctionArgs) =>  {
    //let retour: IAdminWizardBaseProps = {} as IAdminWizardBaseProps;
    //Dont put undefined as default value but use '' instead
    let retour: CloudStorage = {
        cloudStorageId: '',
        kind:undefined,
        maxDurationMinutes: 1440,
        basePath:'',
        technicalInfo:'',
        displayName:'',
        securityModel: undefined,
        logInfo: undefined,
        landingZoneName: '',
        cmdbBusinessService: '',
        cmdbApplicationService: '',
        visibility: undefined,
        confidentiality: undefined,
        arnRole: '',
        environment: undefined,
        sourceDL: undefined,
        domainDL: undefined
    } as CloudStorage;
    if (params.cloudStorageId !=="new") {
        if (context.isAuthenticated){
            const retourAPI = await callApiBackend<CloudStorage>(context.msalContext, "CloudStorage/" +  params.cloudStorageId );
            
            if (retourAPI)
                retour = retourAPI;
            // const retourPermAPI = await callApiBackend<Permission[]>(context.msalContext, "Permission/" +  params.cloudStorageId );
            // if (retourPermAPI)
            //     retour.permissions = retourPermAPI;
        }
    }
    return retour;
};

interface CloudStorageSave extends CloudStorage {
    updated?: boolean;
}

export const SetCloudStorage = (context: ILoginContext) => 
  async ({ request, params }: LoaderFunctionArgs) =>  {
    const jsonData = await request.json() as CloudStorageSave;    
    console.log("jsonData",jsonData);
    if (jsonData.updated) {
        delete jsonData['updated'];
        let url = "CloudStorage";
        let method = "POST";
        let doNotCheckResult = false;
        if (params.cloudStorageId!=="new"){
            url = "CloudStorage/" +  params.cloudStorageId;
            method = "PUT";
            doNotCheckResult = true;
        }
    
        var errors = await callApiBackend<any>(context.msalContext, url, method, {body: jsonData, doNotCheckResult: doNotCheckResult})
            .then(async(response) => {
                return null;
            })
            .catch(async(error) => {
                if (error satisfies FetchValidationErrors){
                    const fetchValidationErrors = error as FetchValidationErrors;
                    return fetchValidationErrors;
                }
                throw error;                
            });
        if (errors)
            return errors; // on reste sur la page en cours mais ces erreurs sont dispo sur la var initialisée par useActionData()
    }
    return redirect(`/self/${jsonData.cloudStorageId}/permissions` );
}









const AdminFormBase = () => {
    const initialItem = useLoaderData() as CloudStorage;

    const submit = useSubmit();
    const fetchValidationErrors = useActionData() as FetchValidationErrors;

    const save = (values: Values) => {
        // appelé seulement si formulaire valide
        let v = values as unknown as CloudStorageSave;
        v.updated = JSON.stringify(values) !== JSON.stringify( initialItem ); 
        const values2 : SubmitTarget = v as unknown as  SubmitTarget;
        submit( values2, { method: "post", encType: "application/json" } );
        // fetcher.submit(values2, { method: "post", encType: "application/json" });
    }
    
    const navigation = useNavigation();

    const steps: StepConfig[] = [
        PlatformStepConfig(initialItem),
        TechnicalInformationStepConfig(initialItem),
        NamesStepConfig(initialItem),
        OtherParametersStepConfig(initialItem)      
    ]
    const stepsLabel = ["Platform", "Technical Information", "Names", "Other parameters"];

    return (
        <>
            <Container fluid >
            {(navigation.state === "loading" || navigation.state === "submitting")  &&     
                        <Spinner animation="border" role="status" variant="primary" className="align-middle mx-3" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>     
                    }       

            <div className={(navigation.state === "loading" || navigation.state === "submitting") ? "opaque" : ""}>
                <Wizard
                    steps={steps}
                    footer={<WizardFooter textSubmit="Save and set permissions" />}
                    header={<WizardHeader stepsLabel={stepsLabel} />}
                    onCompleted={save}
                />
            </div>
            <div className="row">
            {
                // système loin d'être parfait. Idéallement il aurait fallut revenir sur l'étape avec error
                // le composant actuel Wizard ne permet pas d'injecter des initialErrors https://formik.org/docs/api/formik#initialerrors-formikerrorsvalues
                // au besoin il faudra récupérer et dériver le code source du composant https://github.com/kennyhei/react-formik-step-wizard/blob/main/src/wizard/Wizard.tsx 
                // autre approche : faire un service web qui valide chaque étape et on s'intgère dans le step.onSubmit ou step.validate ce qui serait mieux en terme ergo..
                // a statuer au moment de valider les paramètres CORs, droits, etc....
                fetchValidationErrors && 
                <>
                    <Alert variant='danger'>{fetchValidationErrors.title}
                        <ul>
                        {
                            fetchValidationErrors.errors && fetchValidationErrors.getErrorsToDisplay().map((m)=> <li key={m.key}>{m.key}: {m.value}</li>)
                        }
                        </ul>
                    </Alert>                  
                </>
            }
            </div>            
            </Container>

        </>
    );
}
export const  AdminForm = React.memo(AdminFormBase);
export default  AdminForm;