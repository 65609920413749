import { DeleteObjectCommand, GetObjectCommand, ListObjectsV2Command, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { BaseFolderAwsDataLakeTE, ContentItem, IDirectoryContent, ITokenProvider, ProgressCallback, TokenInfo } from "../types";
import { Upload } from "@aws-sdk/lib-storage";
import { IMsalContext } from "@azure/msal-react";
import { getFileName } from "../utils";
import { QueryClient } from "react-query";
import { TransferProgressEvent} from "../modules/fetchHttpClient";
import { callAwsApiBackend } from "../fetch";
import { AwsCredentialIdentity} from "@smithy/types";

export class AwsDataLakeTE  implements ITokenProvider{
    private msalContext: IMsalContext;
    private baseFolder :BaseFolderAwsDataLakeTE;
    private perimeter: string | undefined;
    private queryClient: QueryClient;
    constructor(msalContext: IMsalContext, baseFolder :BaseFolderAwsDataLakeTE, perimeter: string | undefined, queryClient: QueryClient) {
      this.msalContext = msalContext;
      this.baseFolder = baseFolder;
      this.perimeter = perimeter;
      this.queryClient = queryClient;
    }


    async get(path: string, options?: {continuationToken?: string, pageSize?: number, prefixSearch?: string, abortSignal?: AbortSignal }): Promise<IDirectoryContent> {
        const infos = await this.getClientAndBucketInfo(path, options?.prefixSearch, options);
        const command = new ListObjectsV2Command({
            Bucket: infos.bucket,
            Prefix: infos.path,
            Delimiter: "/",
            ContinuationToken: options?.continuationToken,
            MaxKeys: options?.pageSize
        });
        const result = await infos.client.send(command, { abortSignal: options?.abortSignal});
        let contents: ContentItem[] = [];
        if ( result.CommonPrefixes) {
            for (const folderS3 of result.CommonPrefixes) {
                this.fromFolder(path, contents, folderS3);
            }
        }
        if ( result.Contents) {
            for ( const fileS3 of result.Contents) {
                this.fromFile(path,contents, fileS3);
            }
        }

        let newBaseFolder;
        ({ newBaseFolder, contents } = this.manageVisibilityAndPermissions(path, contents));        



        return {
            elements: contents,
            baseFolder: newBaseFolder,
            tokenProvider: this,
            continuationToken: result.NextContinuationToken,
            prefixSearch: options?.prefixSearch,
            pagesize: options?.pageSize
        }
    }
    private manageVisibilityAndPermissions(path: string, contents: ContentItem[]) {
      // on Datalake, some folders are "protected" a little hack to avoid to diplay upload/delete buttons
      // first clone and them set operationAllowed to "Read" on some folders
      // second purpose : on apps and domain, filter the list of folders (base/subdomain and object)      
      const newBaseFolder = JSON.parse(JSON.stringify(this.baseFolder)) as BaseFolderAwsDataLakeTE;

      // on a tous les droits 
      //  - apps : under private anbd co
      newBaseFolder.operationAllowed = "Read";
      const pathPart = path.split('/').filter(c => c);
      const sourceOrDomain = pathPart.length > 0 ? pathPart[0] : "";
      switch (this.perimeter?.toLowerCase()) {
        case "apps":
          if (path.startsWith("exec/")) {
            if (pathPart.length >= 2)
              newBaseFolder.operationAllowed = "Write";
          }
          else if (pathPart.length >= 1)
            newBaseFolder.operationAllowed = "Write";
          break;
        case "source":
          if (pathPart.length === 2) {
            contents = contents.filter(c => this.baseFolder.sourceDL.filter(s => s.source === sourceOrDomain && s.base === c.name).length > 0);
          }
          if (pathPart.length === 3 && pathPart[1] !== "LANDING") {
            contents = contents.filter(c => this.baseFolder.sourceDL.filter(s => s.source === sourceOrDomain && s.base === pathPart[2] && s.object === c.name).length > 0);
          }
          if (pathPart.length >= 5)
            newBaseFolder.operationAllowed = "Write";
          break;
        case "domain":
          if (pathPart.length === 2) {
            contents = contents.filter(c => this.baseFolder.domainDL.filter(s => s.domain === sourceOrDomain && s.subdomain === c.name).length > 0);
          }
          if (pathPart.length === 3) {
            contents = contents.filter(c => this.baseFolder.domainDL.filter(s => s.domain === sourceOrDomain && s.subdomain === pathPart[2] && s.object === c.name).length > 0);
          }
          if (pathPart.length >= 5)
            newBaseFolder.operationAllowed = "Write";
          break;
      }
      return { newBaseFolder, contents };
    }

    async download( path:string, item:ContentItem,  options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<Blob|undefined>{
        const infos = await this.getClientAndBucketInfo(path, item.name, options);
        const command = new GetObjectCommand({
            Bucket: infos.bucket,
            Key: infos.path,

          });
        const response = await infos.client.send(command, { abortSignal: options?.abortSignal});
        // in case of large file, that part of code is activated BEFORE the entire download of file. So we can have a progress bar (I take some code in the Azure SQK )
        const stream = response.Body?.transformToWebStream();
        if (!stream)
            throw new Error("No stream on download");
        const newStream = this.buildBodyStream(stream, { onProgress: options?.onprogress });
        const responseStream = new Response(newStream);
        return responseStream.blob();
    }
    async createFolder(path:string, subfolder:string, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
        const infos = await this.getClientAndBucketInfo(path, subfolder + "/", options);
        const command = new PutObjectCommand({
            Bucket: infos.bucket,
            Key: infos.path,
        });
        const result = await infos.client.send(command, { abortSignal: options?.abortSignal});
        if (result)
            return true;
        return false;
    }
    async upload(path:string, file: File, options?: { onprogress: (progress: ProgressCallback) => void, abortSignal?: AbortSignal } ): Promise<boolean>{
        const infos = await this.getClientAndBucketInfo(path, file.name, options);
        const parallelUploads3 = new Upload({
            client: infos.client,
            params: {  Bucket: infos.bucket, Key: infos.path, Body: file },
        });
        options?.abortSignal?.addEventListener("abort", async () => {
          return await parallelUploads3.abort();
        } );
        parallelUploads3.on("httpUploadProgress", (progress) => {
            options?.onprogress({ loadedBytes: progress.loaded ?? 0 });
          });        
        await parallelUploads3.done();
        return true;
    }
    async delete(path:string, item:ContentItem, options?: { abortSignal?: AbortSignal }) : Promise<boolean> {
        let filename = item.name;
        if (!item.isFile)
            filename += "/";
        const infos = await this.getClientAndBucketInfo(path, filename, options);
        const command = new DeleteObjectCommand({
            Bucket: infos.bucket,
            Key: infos.path,

          });
        const response = await infos.client.send(command, { abortSignal: options?.abortSignal});        
        if (response)
            return true;
        return false;
    }
    async rename(path: string, item: ContentItem, newName: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      throw new Error('Method not supported.');
    }
    async move(path: string, item: ContentItem, newPath: string, options?: { abortSignal?: AbortSignal }): Promise<boolean> {
      throw new Error('Method not supported.');
    }

    async getClientAndBucketInfo(path:string, prefix?: string, options?: { abortSignal?: AbortSignal }): Promise<{client: S3Client, bucket: string, path:string}>{
        if (path && !path.endsWith("/"))
            path += "/";
        let bucket: string;

        switch (this.perimeter?.toLowerCase()) {
            case "apps":
                bucket = this.baseFolder.appBucket;
                path = this.baseFolder.appCode + "/" + path;
                break;
            case "source":
                if (!this.baseFolder.sourceBucket)
                    throw new Error("No source S3 bucket. Use self service to define a source S3 bucket.");
                bucket = this.baseFolder.sourceBucket  ;
                break;
            case "domain":
                if (!this.baseFolder.domainBucket)
                    throw new Error("No domain S3 bucket. Use self service to define a domain S3 bucket.");
                bucket = this.baseFolder.domainBucket  ;
                break;
            default:
                throw new Error("Invalid Perimeter " + this.perimeter);                
        }
        if (prefix)
            path += prefix;
        const client = await this.getClient(options);
        return {client, bucket, path};
    }

    private cacheTimeSeconds  =  3600 ; // one hour 
    async getClient(options?: { abortSignal?: AbortSignal }) : Promise<S3Client>{
        // cache credentials : by appRoleArn
        const cacheKey = ["AwsDatalakeTe.getClient", this.baseFolder.appRoleArn];
        const s3Client = this.queryClient.getQueryData(cacheKey) as S3Client ??  
          (await this.queryClient.fetchQuery(cacheKey, ()=>  this.getClientInternal(options), {staleTime: this.cacheTimeSeconds * 1000})
        );
        return s3Client;
    }

    async getClientInternal(options?: { abortSignal?: AbortSignal }) : Promise<S3Client>{
        // Credentials:
        //   first: get an AAD token scoped to the app registration "aws"
        //   second: perform an assume_role_with_web_identity to get temporary credentials
        //   third: call Aws API for flash to get temporary credentials
        let url = this.baseFolder.urlAuthenticate + "?" + this.baseFolder.voucher;
        const credentials = await callAwsApiBackend<AwsCredentialIdentity>(this.msalContext, this.queryClient, this.baseFolder.flashRoleArn, this.baseFolder.flashRoleSessionName, url , "GET", {abortSignal: options?.abortSignal});

        // the code is "reverted" (assumerole with a requirement of assumerolewithwebidentity with a requirement of an AAD token)
        const region  = "eu-central-1";
        return new S3Client({
            region: region,
            // read https://www.npmjs.com/package/@aws-sdk/credential-providers, fromTemporaryCredentials and fromWebToken
            // finally we perform an assume_role (to target the role in the app Aws account )
            credentials: credentials
        });
    }

    async getToken( operation:"Read" | "Write" | "List" | "Delete" | "ReadList" | "All", path:string, durationInMinutes: number
      , options?: { abortSignal?: AbortSignal, fileSize?: number, partSize?: number, continuationToken?: string, pageSize?: number, prefixSearch?: string}
      ): Promise<TokenInfo|undefined>{
        throw new Error("Method not implemented.");
    }

    private decodeKey(key: string) {
        return key;
    }
    private fromFile(path: string, contents: ContentItem[], fileS3: any) {
        // for some folders (not all), the item in the result is the folder itself, so we remove it
        if (fileS3.Key?.endsWith("/"))
            return;
        // flash : path ends alays by /
        const prefix = this.decodeKey(fileS3.Key);   
        const filename = getFileName(prefix);
        contents.push({
            isFile: true,
            name: filename,
            path: path + filename,
            modified: new Date(fileS3.LastModified),
            size: fileS3.Size,
            isBaseFolder: false,
        });
    }

    private fromFolder(path: string, contents: ContentItem[], folderS3: any) {
        const prefix = this.decodeKey(folderS3.Prefix);
        const filename = getFileName(prefix);        
        contents.push({
            isFile: false,
            name: filename,
            path: path + filename + "/",
            isBaseFolder: false,
        });
    }    

    //region from fetchHttpClient.ts of the Azure SDK
    // only change Uint8Array by any
    private buildBodyStream(
        readableStream: ReadableStream<any>,
        options: { onProgress?: (progress: TransferProgressEvent) => void; onEnd?: () => void } = {},
      ): ReadableStream<any> {
        let loadedBytes = 0;
        const { onProgress, onEnd } = options;
      
        // If the current browser supports pipeThrough we use a TransformStream
        // to report progress
        if (this.isTransformStreamSupported(readableStream)) {
          return readableStream.pipeThrough(
            new TransformStream({
              transform(chunk, controller) {
                if (chunk === null) {
                  controller.terminate();
                  return;
                }
      
                controller.enqueue(chunk);
                loadedBytes += chunk.length;
                if (onProgress) {
                  onProgress({ loadedBytes });
                }
              },
              flush() {
                onEnd?.();
              },
            }),
          );
        } else {
          // If we can't use transform streams, wrap the original stream in a new readable stream
          // and use pull to enqueue each chunk and report progress.
          const reader = readableStream.getReader();
          return new ReadableStream({
            async pull(controller) {
              const { done, value } = await reader.read();
              // When no more data needs to be consumed, break the reading
              if (done || !value) {
                onEnd?.();
                // Close the stream
                controller.close();
                reader.releaseLock();
                return;
              }
      
              loadedBytes += value?.length ?? 0;
      
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
      
              if (onProgress) {
                onProgress({ loadedBytes });
              }
            },
            cancel(reason?: string) {
              onEnd?.();
              return reader.cancel(reason);
            },
          });
        }
      }

      private isTransformStreamSupported(readableStream: ReadableStream): boolean {
        return readableStream.pipeThrough !== undefined ;
      }      
    //endregion from fetchHttpClient.ts of the Azure SDK
}
