import React, { FunctionComponent, useEffect, useState } from "react";
import { LoaderFunctionArgs, redirect, useLoaderData, useLocation, useNavigate, useNavigation, useParams, useSubmit } from 'react-router-dom';
import { CloudStorage, ILoginContext, Permission, Review } from '../types';
import { callApiBackend } from "../fetch";
import { OneStepProps, StepButtons,Stepper, ButtonProps } from "../components/Step";
import { AdminPermissionOperation } from "../components/AdminPermissionOperation";
import { AdminListProps } from "./AdminList";
import { SubmitTarget } from "react-router-dom/dist/dom";

export const GetPermissions = (context: ILoginContext, admin: boolean) => 
  async ({ request, params }: LoaderFunctionArgs) =>  {
    if (params.cloudStorageId !=="new") {
        if (context.isAuthenticated){
            const perms = await callApiBackend<Permission[]>(context.msalContext, "Permission/" + params.cloudStorageId);   
            const lastreview =  await callApiBackend<Review>(context.msalContext, "Permission/Review/" + params.cloudStorageId);
            let cloudstorage : CloudStorage | undefined = undefined;
            if (!admin) {
                cloudstorage = await callApiBackend<CloudStorage>(context.msalContext, "CloudStorage/" +  params.cloudStorageId);
            }
            return { permissions: perms,
                 lastreview: lastreview,
                 cloudstorage: cloudstorage
            };
        }
    }
    const defaultperms : Permission[] = [];
    return { permissions: defaultperms,
        lastreview: undefined,
        cloudstorage: undefined
    };
};


export const AddPermission = (context: ILoginContext) => 
  async ({ request, params }: LoaderFunctionArgs) =>  {
        const jsonData = await request.json() as Permission;    
        await callApiBackend<void>(context.msalContext, "Permission", "POST", { body: jsonData})
            .then(async(response) => {
                return response;
            })
            .catch(error => {
                throw error;
            });
        return jsonData;
  }
export const DeletePermission = (context: ILoginContext) => 
    async ({ request, params }: LoaderFunctionArgs) =>  {
        const jsonData = await request.json() as Permission;    
        await callApiBackend<void>(context.msalContext, "Permission", "DELETE", { body: jsonData })
            .then(async(response) => {
                return response;
            })
            .catch(error => {
                throw error;
            });
        return jsonData;
    }
export const ReviewPermission = (context: ILoginContext, admin: boolean) => 
    async ({ params }: LoaderFunctionArgs) =>  {
        await callApiBackend<void>(context.msalContext, "Permission/Review/" + params.cloudStorageId, "POST", { });
        if (admin)
            return redirect("/admin");
        return redirect("/self");
    }


const AdminPermissionBase : FunctionComponent<AdminListProps> = ( {admin}) =>  {
    const loaderData = useLoaderData() as any;
    const review = loaderData.lastreview as Review | undefined;
    const cloudStorage = loaderData.cloudstorage;
    const kind = cloudStorage?.kind;

    
    const params = useParams();
    const cloudStorageId = params["cloudStorageId"] as string;

    // Define the default steps
    const defaultSteps: OneStepProps[] = [ 
        {
            label: "Owners",
            description: "can set these parameters (general parameters and permissions). Cannot navigate/read/write cloud storage content. Can add only user admin accounts. You must provide its corresponding email"
        },
        {
            label: "Writers",
            description: "can navigate, can read/upload/delete files, can read/create/delete folders in the cloud storage, can generate tokens"
        },
        {
            label: "Readers",
            description: "can navigate, can read files, can read folders in the cloud storage, can generate read tokens"
        }
    ]
    // Modify the steps array based on the value of the 'kind' variable
    let stepsSelf = [...defaultSteps];
    if (kind === "AwsDataLakeTE" || kind === "AzureDataLakeTE") {
        // Remove the third step ("Readers")
        stepsSelf.splice(2, 1);
        // Rename the second step ("Writers") to "Access"
        if (kind === "AwsDataLakeTE") {
            stepsSelf.splice(1, 1, { label: "Access", description: "can navigate, can read/upload files, can read/create folders in the cloud storage" });
        }else {
            const basePath = cloudStorage?.basePath;
            stepsSelf.splice(1, 1, { label: "Access", description: "give access to your datalake by adding the object id of your team group. Your group name is : ODP-IAS-WE-GLOBAL-ADLS-" + basePath + "-TEAM" });
        }
    }
    const stepsAdmin: OneStepProps[] = cloudStorageId==="cloudstorage" ?  [ 
        {
            label: "Global admins",
            description: "who can access to this screen and edit all the cloud storage"
        }
    ] : [ 
        {
            label: "Self Service",
            description: "Who can access to self service functionalities"
        }
    ];
    const steps = admin ? stepsAdmin : stepsSelf;


    const [step, setStep] = useState(0);
    const operation = step===0 ? "Owner" : step===1 ? "Write" : "Read";
    const navigate = useNavigate();
    const navigation = useNavigation();
    const submit = useSubmit();
    const location = useLocation();
    useEffect( ()=> {
        if (step>=steps.length && navigation.state === "idle") {
            const values2 : SubmitTarget = {} as unknown as  SubmitTarget;
            submit(values2, { method: "post", encType: "application/json", action:location.pathname + "/review" })
        }
    }, [step, steps, navigate, navigation, location.pathname, submit]);
    const [next, setNext] = useState<ButtonProps>({ label: "Next", style:{ "backgroundColor": "#ed0000" }, disabled: false });   
    useEffect( ()=> {
        const allpermissions = loaderData.permissions as Permission[];
        const permissions = allpermissions.filter((v)=> v.operation === operation);        
        if( operation === "Owner" && permissions.length === 0) {
            setNext({ label: "At least one owner", style:{ "backgroundColor": "#ed0000" }, disabled: true });
        }
        else if( operation === "Owner" && permissions.filter(v=>! v.email ).length !== 0) {
            setNext({ label: "assign email to each owner", style:{ "backgroundColor": "#ed0000" }, disabled: true });
        } else {
            setNext({ label: "Next", style:{ "backgroundColor": "#ed0000" }, disabled: false });
        }

    }, [loaderData.permissions, operation]);

    return <>
        <Stepper activeStep={step} steps={steps} />
        <AdminPermissionOperation cloudStorageId={cloudStorageId} operation={operation} />
        <StepButtons step={step} setStep={setStep} childrenLength={steps.length} 
                nextButton={next}
                submitButton={{ label: "End review", style:{ "backgroundColor": "#ed0000" } }}
                prevButton={{ label: "Back", style:{ 
                    "backgroundColor": "#fff", 
                    "color":"#ed0000",
                    "borderColor": "#ed0000" } }}
                extraInfo={ review?.reviewedBy && review?.reviewedDate ? "Last review by " + review?.reviewedBy + " on " + new Date(review?.reviewedDate + "Z").toLocaleString() + "": ""}                   
        />
    </>;
}
export const  AdminPermission = React.memo(AdminPermissionBase);
export default  AdminPermission;